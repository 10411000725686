import React, { useContext } from "react"
import {
  Card,
  Layout,
  TextContainer,
  SkeletonPage,
  SkeletonBodyText,
  SkeletonDisplayText,
  Loading,
  Stack,
  Icon,
  Tooltip,
} from "@shopify/polaris"
import { InfoMinor } from "@shopify/polaris-icons"

// animation
import { CSSTransition } from "react-transition-group"

// refresh image
import refresh from "../../images/refresh.png"
import refreshDark from "../../images/refresh-dark.png"

// style
import "./styles.css"

// localizer
import { useLocalizer } from "reactjs-localizer"

// context
import { AuthContext } from "../../app/AuthContext"

const TeamsSkeleton = ({ isRefreshing }) => {
  const { localize } = useLocalizer()

  const { theme } = useContext(AuthContext)

  return (
    <div
      className="skeleton-container"
      style={window.innerWidth < 426 ? { marginTop: -4, marginLeft: -4 } : null}
    >
      <Loading />
      <SkeletonPage
        title={
          <Stack alignment="center">
            <TextContainer>{localize("MyTeams")}</TextContainer>
            <div style={{ cursor: "pointer", marginTop: 2 }}>
              <Tooltip content={localize("HereYouCanSeeTeams")}>
                <Icon source={InfoMinor} color="white" />
              </Tooltip>
            </div>
            {isRefreshing && (
              <img
                src={theme.colorScheme === "dark" ? refreshDark : refresh}
                style={
                  window.location.innerWidth < 450
                    ? isRefreshing
                      ? {
                          marginBottom: -4,
                          padding: 0,
                          width: 22,
                          height: 22,
                          animation: "rotation 1s infinite linear",
                        }
                      : {
                          marginBottom: -4,
                          padding: 0,
                          width: 22,
                          height: 22,
                        }
                    : isRefreshing
                    ? {
                        marginBottom: -5.3,
                        padding: 0,
                        width: 22,
                        height: 22,
                        animation: "rotation 1s infinite linear",
                      }
                    : {
                        marginBottom: -5.3,
                        padding: 0,
                        width: 22,
                        height: 22,
                      }
                }
                alt=""
              />
            )}
          </Stack>
        }
        fullWidth
        // primaryAction={window.innerWidth < 426 ? false : true}
      >
        <Layout>
          <Layout.Section oneThird>
            <CSSTransition
              in={true}
              appear={true}
              timeout={1000}
              classNames="transform"
            >
              <Card>
                <Card.Section flush>
                  <div className="skeleton-card-image"></div>
                </Card.Section>
                <Card.Section subdued>
                  <TextContainer>
                    <div className="skeleton-display-text">
                      <SkeletonDisplayText size="small" />
                    </div>
                    <div
                      style={
                        window.innerWidth > 1327
                          ? {
                              height: 66,
                            }
                          : null
                      }
                    >
                      {window.innerWidth < 540 ? (
                        <SkeletonBodyText lines={3} />
                      ) : (
                        <SkeletonBodyText lines={4} />
                      )}
                    </div>
                  </TextContainer>
                </Card.Section>
              </Card>
            </CSSTransition>
          </Layout.Section>
          {window.innerWidth < 1084 && window.innerWidth > 823 ? (
            <>
              <Layout.Section oneThird></Layout.Section>
              <Layout.Section oneThird></Layout.Section>
            </>
          ) : window.innerWidth > 539 ? (
            <>
              <Layout.Section oneThird></Layout.Section>
              <Layout.Section oneThird></Layout.Section>
              <Layout.Section oneThird></Layout.Section>
            </>
          ) : null}
        </Layout>
      </SkeletonPage>
    </div>
  )
}

export default TeamsSkeleton
