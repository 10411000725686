export default function debounce(event) {
  var timer;

  if (timer) clearTimeout(timer);
  timer = setTimeout(
    () => {
      localStorage.removeItem("maxHeight");
      window.location.reload();
    },
    800,
    event
  );
}
