import React, { useEffect, useState, useCallback, useContext } from "react"
import {
  Page,
  Card,
  Stack,
  Layout,
  Heading,
  Toast,
  TextContainer,
  Badge,
  Icon,
  Tooltip,
} from "@shopify/polaris"
import { InfoMinor } from "@shopify/polaris-icons"

// refresh image
import refresh from "../../images/refresh.png"
import refreshDark from "../../images/refresh-dark.png"

// style
import "./styles.css"

// animation
import { CSSTransition } from "react-transition-group"

// reset global object
import { resetGlobalObjTeams } from "../Team/Utilities/globalObjTeams"
import { useHistory } from "react-router-dom"

// api
import Api, { GraphApi } from "../../api"
import initiative from "../../api/modules/initiatives"

// placeholder for image
import placeholder from "./../../images/placeholder.jpg"

// skeletons
import TeamsSkeleton from "./teamsSkeleton"
import InitiativesSkeleton from "./initiativesSkeleton"

// localizer
import { useLocalizer } from "reactjs-localizer"

// function to add event listener
import debounce from "../../app/config/debounceFunction"

// context
import { AuthContext } from "../../app/AuthContext"

const Overview = () => {
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [loadingInitiatives, setLoadingInitiatives] = useState(true)
  resetGlobalObjTeams()
  const { localize } = useLocalizer()
  var userLang = navigator.language || navigator.userLanguage
  userLang = userLang.slice(userLang.indexOf("-") + 1).toLowerCase()

  localStorage.removeItem("teamOwnerInfos")

  const { theme } = useContext(AuthContext)

  // REMOVE TEAMS IMPACT FROM LOCAL STORAGE
  useEffect(() => {
    let keys = Object.keys(localStorage)

    for (let i = 0; i < keys.length; i++) {
      if (keys[i].includes("Impact")) {
        localStorage.removeItem(keys[i])
      }
    }
  }, [])

  // RESIZE END EVENT
  if (window.innerWidth > 450) {
    window.addEventListener("resize", debounce)
  }

  // FETCH MY TEAMS LIST
  const [teamsList, setTeamsList] = useState([])

  const [teamsErrorToastActive, setTeamsErrorToastActive] = useState(false)
  const [initiativesErrorToastActive, setInitiativesErrorToastActive] =
    useState(false)

  const toggleTeamsErrorToastActive = useCallback(
    () =>
      setTeamsErrorToastActive(
        (teamsErrorToastActive) => !teamsErrorToastActive
      ),
    []
  )

  const toggleInitiativesErrorToastActive = useCallback(
    () =>
      setInitiativesErrorToastActive(
        (initiativesErrorToastActive) => !initiativesErrorToastActive
      ),
    []
  )

  const teamsErrorToastMarkup = teamsErrorToastActive ? (
    <Toast
      content={localize("ErrorLoadingTeams")}
      onDismiss={toggleTeamsErrorToastActive}
      error
    />
  ) : null

  const initiativesErrorToastMarkup = initiativesErrorToastActive ? (
    <Toast
      content={localize("ErrorLoadingInitiatives")}
      onDismiss={toggleInitiativesErrorToastActive}
      error
    />
  ) : null

  const fetchTeams = async () => {
    setLoading(true)
    setTeamsErrorToastActive(false)

    var apiCall
    try {
      apiCall = await Api.teams.getMyTeams()
    } catch (e) {
      console.error(e)
      setTeamsErrorToastActive(true)
      localStorage.clear()
      localStorage.setItem("theme", theme.colorScheme)
      window.location.reload()
      return
    }

    if (apiCall.length > 1) {
      var ordered = false

      while (ordered === false) {
        for (let i = 0; i < apiCall.length - 1; i++) {
          ordered = true

          if (apiCall[i + 1].id < apiCall[i].id) {
            let temp = apiCall[i + 1]
            apiCall[i + 1] = apiCall[i]
            apiCall[i] = temp

            ordered = false
          }
        }
      }
    }

    localStorage.setItem("apiCall", JSON.stringify(apiCall))
    localStorage.setItem("shouldRefresh", false)

    let teamsArray = []
    for (let i = 0; i < apiCall.length; i++) {
      teamsArray.push(apiCall[i].name + "%" + apiCall[i].id)
    }
    localStorage.setItem("teamsArray", JSON.stringify(teamsArray))

    setTeamsList(apiCall)
    setLoading(false)
  }

  useEffect(() => {
    if (localStorage.getItem("shouldRefresh") === null) {
      localStorage.setItem("shouldRefresh", true)
    }

    fetchTeams()

    // if no changes are made, the page will not refresh
    // if (localStorage.getItem("shouldRefresh") === "true") {
    //   fetchTeams()
    // } else {
    //   let apiCall = JSON.parse(localStorage.getItem("apiCall"))
    //   setTeamsList(apiCall)
    //   localStorage.setItem("shouldRefresh", false)

    //   let teamsArray = []
    //   for (let i = 0; i < apiCall.length; i++) {
    //     teamsArray.push(apiCall[i].name + "%" + apiCall[i].id)
    //   }
    //   localStorage.setItem("teamsArray", JSON.stringify(teamsArray))

    //   setLoading(false)
    // }
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!loading) {
      let divs = document.getElementsByClassName("text-div")
      let max
      let heights = []
      for (let i = 0; i < divs.length; i++) {
        heights.push(divs[i].clientHeight)
      }

      max = Math.max(...heights)

      if (localStorage.getItem("maxHeight")) {
        max = localStorage.getItem("maxHeight")
      }

      for (let i = 0; i < divs.length; i++) {
        divs[i].style.height = max + "px"
      }
    }
  }, [loading])

  // RETURNS ALL TEAMS ORGANIZED IN LAYOUT SECTIONS
  const layoutSections = () => {
    var sections = []

    for (let i = 0; i < teamsList.length; i++) {
      let teamName = teamsList[i].documents[userLang]
        ? teamsList[i].documents[userLang].heading
        : teamsList[i].name

      let description

      if (teamName.length < 30) {
        if (teamsList[i].documents[userLang]) {
          description = teamsList[i].documents[userLang].body[0].content.slice(
            0,
            155
          )
        } else {
          description = teamsList[i].documents.en.body[0].content.slice(0, 155)
        }
        if (description.length === 155) {
          description += "...</p>"
        }
      } else {
        if (teamsList[i].documents[userLang]) {
          description = teamsList[i].documents[userLang].body[0].content.slice(
            0,
            105
          )
        } else {
          description = teamsList[i].documents.en.body[0].content.slice(0, 105)
        }
        if (description.length === 105) {
          description += "...</p>"
        }
      }

      let sectionToAdd = (
        <Layout.Section oneThird key={teamsList[i].id}>
          <div
            className="team-div"
            onClick={() => {
              history.push(`/dashboard/myteams/${teamsList[i].id}`)
            }}
          >
            <Card>
              <Card.Section flush>
                <img
                  src={teamsList[i].documents.en.cover || placeholder}
                  alt="team"
                  className="team-image"
                />
              </Card.Section>
              <Card.Section subdued>
                <Stack vertical spacing="extraTight">
                  <div className="text-div">
                    <Heading>{teamName}</Heading>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: description,
                      }}
                      style={{ marginTop: 5 }}
                    />
                  </div>
                </Stack>
              </Card.Section>
            </Card>
          </div>
        </Layout.Section>
      )

      sections.push(sectionToAdd)
    }

    if (sections.length > 0) {
      if (window.innerWidth < 1084 && window.innerWidth > 823) {
        while (sections.length % 3 !== 0) {
          let sectionToAdd = (
            <Layout.Section
              oneThird
              key={sections.length + "12345"}
            ></Layout.Section>
          )
          sections.push(sectionToAdd)
        }
      }
      if (window.innerWidth > 539) {
        while (sections.length % 4 !== 0) {
          let sectionToAdd = (
            <Layout.Section
              oneThird
              key={sections.length + "12345"}
            ></Layout.Section>
          )
          sections.push(sectionToAdd)
        }
      }

      return sections
    } else {
      return null
    }
  }

  // FETCH INITIATIVES LIST
  const [initiativesList, setInitiativesList] = useState([])
  const [fetchInitiativesDone, setFetchInitiativesDone] = useState(false)
  const [refreshing, setRefreshing] = useState(false)

  // const fetchInitiatives = async () => {
  //   setLoadingInitiatives(true)
  //   setInitiativesErrorToastActive(false)

  //   let currentDate = new Date()
  //   currentDate = currentDate.toISOString()

  //   try {
  //     const { data } = await GraphApi.graphql({
  //       query: initiative.queries.listInitiatives,
  //       variables: { currentDate },
  //     })
  //     setInitiativesList(data.listInitiatives.items)
  //     setFetchInitiativesDone(false)
  //     setFetchInitiativesDone(true)
  //   } catch (e) {
  //     console.error(e)
  //     setInitiativesErrorToastActive(true)
  //     let theme
  //     if (theme.colorScheme) {
  //       theme = theme.colorScheme
  //     }
  //     localStorage.clear()
  //     if (theme) {
  //       localStorage.setItem("theme", theme)
  //     }
  //     window.location.reload()
  //     return
  //   }
  // }

  useEffect(() => {
    // fetchInitiatives()
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!loadingInitiatives) {
      let divs = document.getElementsByClassName("text-div")
      let heights = []
      for (let i = 0; i < divs.length; i++) {
        heights.push(divs[i].clientHeight)
      }
      let max = Math.max(...heights)
      for (let i = 0; i < divs.length; i++) {
        divs[i].style.height = max + "px"
      }
      localStorage.setItem("maxHeight", max)
    }
  }, [loadingInitiatives])

  // RETURNS ALL INITIATIVES ORGANIZED IN LAYOUT SECTIONS
  const layoutSectionsInitiatives = () => {
    var sections = []

    for (let i = 0; i < initiativesList.length; i++) {
      // control for test initiatives without translations
      if (
        !(
          !initiativesList[i].hasOwnProperty("translations") ||
          (initiativesList[i].hasOwnProperty("translations") &&
            initiativesList[i].translations.items.length === 0)
        )
      ) {
        let indexForLanguages
        let translations = initiativesList[i].translations.items
        let done = false
        for (let j = 0; j < translations.length; j++) {
          if (translations[j].lang === userLang) {
            indexForLanguages = j
            done = true
          }
        }
        if (!done) {
          for (let j = 0; j < translations.length; j++) {
            if (translations[j].lang === initiativesList[i].defaultLang) {
              indexForLanguages = j
            }
          }
        }

        let description
        if (initiativesList[i].name.length < 30) {
          description = initiativesList[i].translations.items[
            indexForLanguages
          ].whatDescription.slice(0, 146)
          if (description.length === 146) {
            description += "...</p>"
          }
        } else {
          description = initiativesList[i].translations.items[
            indexForLanguages
          ].whatDescription.slice(0, 105)
          if (description.length === 105) {
            description += "...</p>"
          }
        }

        let sectionToAdd = (
          <Layout.Section oneThird key={initiativesList[i].id}>
            <CSSTransition
              in={true}
              appear={true}
              timeout={1000}
              classNames="transform"
            >
              <div
                className="team-div"
                onClick={() => {
                  history.push(
                    `/dashboard/initiatives/${initiativesList[i].id}`
                  )
                }}
                style={{ position: "relative" }}
              >
                <div
                  id={initiativesList[i].id}
                  style={{
                    display: "none",
                    position: "absolute",
                    marginTop: "2%",
                    marginLeft: "2%",
                  }}
                >
                  <Badge status="success" size="medium">
                    {localize("Supporting")}
                  </Badge>
                </div>
                <Card>
                  <Card.Section flush>
                    <img
                      src={
                        initiativesList[i].translations.items[indexForLanguages]
                          .image || placeholder
                      }
                      alt="initiative"
                      className="team-image"
                    />
                  </Card.Section>
                  <Card.Section subdued>
                    <Stack vertical spacing="extraTight">
                      <div className="text-div">
                        <Heading>{initiativesList[i].name}</Heading>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: description,
                          }}
                          style={{ marginTop: 5 }}
                        />
                      </div>
                    </Stack>
                  </Card.Section>
                </Card>
              </div>
            </CSSTransition>
          </Layout.Section>
        )

        sections.push(sectionToAdd)
      }
    }

    if (sections.length > 0) {
      if (window.innerWidth < 1084 && window.innerWidth > 823) {
        while (sections.length % 3 !== 0) {
          let sectionToAdd = (
            <Layout.Section
              oneThird
              key={sections.length + "123456"}
            ></Layout.Section>
          )
          sections.push(sectionToAdd)
        }
      }
      if (window.innerWidth > 539) {
        while (sections.length % 4 !== 0) {
          let sectionToAdd = (
            <Layout.Section
              oneThird
              key={sections.length + "123456"}
            ></Layout.Section>
          )
          sections.push(sectionToAdd)
        }
      }

      return sections
    } else {
      return null
    }
  }

  // CHECK SUPPORT
  const checkSupport = async () => {
    let supportingArray = []
    let calls = []

    for (let j = 0; j < initiativesList.length; j++) {
      for (let i = 0; i < teamsList.length; i++) {
        if (
          !(
            !initiativesList[j].hasOwnProperty("translations") ||
            (initiativesList[j].hasOwnProperty("translations") &&
              initiativesList[j].translations.items.length === 0)
          )
        ) {
          let call = GraphApi.graphql({
            query: initiative.queries.isSupporting,
            variables: {
              initiativeId: initiativesList[j].id,
              teamId: teamsList[i].id.toString(),
            },
          })

          calls.push(call)
        }
      }
    }

    const data = await Promise.all(calls)
    setLoadingInitiatives(false)
    setRefreshing(false)
    let dataCounter = 0

    for (let j = 0; j < initiativesList.length; j++) {
      if (
        !(
          !initiativesList[j].hasOwnProperty("translations") ||
          (initiativesList[j].hasOwnProperty("translations") &&
            initiativesList[j].translations.items.length === 0)
        )
      ) {
        for (let i = 0; i < teamsList.length; i++) {
          const currentData = data[dataCounter].data
          dataCounter++

          if (currentData.isSupporting.items.length !== 0) {
            let circle = document.getElementById(initiativesList[j].id)
            if (circle !== null) {
              circle.style.display = "block"
            }
            supportingArray.push(
              teamsList[i].id +
                "," +
                initiativesList[j].id +
                "," +
                teamsList[i].name +
                "%" +
                teamsList[i].uid
            )
          } else {
            let circle = document.getElementById(initiativesList[j].id)
            if (circle !== null && circle.style.display !== "block") {
              circle.style.display = "none"
            }
          }
        }
        if (teamsList.length === 0) {
          let circle = document.getElementById(initiativesList[j].id)
          if (circle) {
            circle.style.display = "none"
          }
        }
      }
    }

    localStorage.setItem("supportingArray", JSON.stringify(supportingArray))
  }

  useEffect(() => {
    if (!loading && fetchInitiativesDone) {
      checkSupport()
    }
    //eslint-disable-next-line
  }, [loading, fetchInitiativesDone])

  return (
    <>
      {loading ? (
        <>
          <TeamsSkeleton isRefreshing={refreshing} />
          {teamsErrorToastMarkup}
        </>
      ) : (
        <Page
          title={
            <Stack alignment="center">
              <TextContainer>{localize("MyTeams")}</TextContainer>
              {window.innerWidth < 400 && <Stack.Item></Stack.Item>}
              {window.innerWidth < 400 && <Stack.Item></Stack.Item>}
              <div style={{ cursor: "pointer", marginTop: 2 }}>
                <Tooltip content={localize("HereYouCanSeeTeams")}>
                  <Icon source={InfoMinor} color="white" />
                </Tooltip>
              </div>
              <img
                src={theme.colorScheme === "dark" ? refreshDark : refresh}
                style={
                  window.innerWidth < 450
                    ? {
                        marginBottom: -4,
                        padding: 0,
                        width: 22,
                        height: 22,
                        cursor: "pointer",
                      }
                    : {
                        marginBottom: -5.3,
                        padding: 0,
                        width: 22,
                        height: 22,
                        cursor: "pointer",
                      }
                }
                alt=""
                onClick={() => {
                  setRefreshing(true)
                  localStorage.setItem("shouldRefresh", true)
                  fetchTeams()
                }}
              />
            </Stack>
          }
          fullWidth
          // primaryAction={{
          //   content: localize("CreateNewTeam"),
          //   url: "/dashboard/createteam",
          // }}
        >
          {layoutSections() === null ? (
            <Layout>
              <Layout.Section oneThird>
                <Card sectioned>
                  <div
                    style={{
                      height: 270,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center ",
                      color: "GrayText",
                    }}
                  >
                    <Stack vertical alignment="center" spacing="tight">
                      <p>{localize("NoTeamsHere")}</p>
                      <p
                        style={{
                          textAlign: "center",
                        }}
                      >
                        {localize("ClickOnCreateNewTeam")}
                      </p>
                    </Stack>
                  </div>
                </Card>
              </Layout.Section>
              <Layout.Section oneThird></Layout.Section>
              <Layout.Section oneThird></Layout.Section>
              <Layout.Section oneThird></Layout.Section>
            </Layout>
          ) : window.innerWidth < 1084 && window.innerWidth > 823 ? (
            <>
              <Layout>
                {layoutSections()
                  .slice(0, 3)
                  .map((value) => value)}
              </Layout>
              {layoutSections().slice(3, 6).length > 0 ? (
                <>
                  <br />
                  <Layout>
                    {layoutSections()
                      .slice(3, 6)
                      .map((value) => value)}
                  </Layout>
                </>
              ) : null}
              {layoutSections().slice(6, 9).length > 0 ? (
                <>
                  <br />
                  <Layout>
                    {layoutSections()
                      .slice(6, 9)
                      .map((value) => value)}
                  </Layout>
                </>
              ) : null}
              {layoutSections().slice(9, 12).length > 0 ? (
                <>
                  <br />
                  <Layout>
                    {layoutSections()
                      .slice(9, 12)
                      .map((value) => value)}
                  </Layout>
                </>
              ) : null}
            </>
          ) : (
            <>
              <Layout>
                {layoutSections()
                  .slice(0, 4)
                  .map((value) => value)}
              </Layout>
              {layoutSections().slice(4, 8).length > 0 ? (
                <>
                  <br />
                  <Layout>
                    {layoutSections()
                      .slice(4, 8)
                      .map((value) => value)}
                  </Layout>
                </>
              ) : null}
              {layoutSections().slice(8, 12).length > 0 ? (
                <>
                  <br />
                  <Layout>
                    {layoutSections()
                      .slice(8, 12)
                      .map((value) => value)}
                  </Layout>
                </>
              ) : null}
            </>
          )}
        </Page>
      )}
      {/* {loadingInitiatives ? (
        <>
          <InitiativesSkeleton isRefreshing={refreshing} />
          {initiativesErrorToastMarkup}
        </>
      ) : (
        <Page
          title={
            <Stack alignment="center">
              <TextContainer>{localize("Initiatives")}</TextContainer>
              <div style={{ cursor: "pointer", marginTop: 2 }}>
                <Tooltip content={localize("HereYouCanSeeInitiatives")}>
                  <Icon source={InfoMinor} color="white" />
                </Tooltip>
              </div>
              <img
                src={theme.colorScheme === "dark" ? refreshDark : refresh}
                style={
                  window.innerWidth < 450
                    ? {
                        marginBottom: -4,
                        padding: 0,
                        width: 22,
                        height: 22,
                        cursor: "pointer",
                      }
                    : {
                        marginBottom: -5.3,
                        padding: 0,
                        width: 22,
                        height: 22,
                        cursor: "pointer",
                      }
                }
                alt=""
                onClick={() => {
                  setRefreshing(true);
                  fetchInitiatives();
                }}
              />
            </Stack>
          }
          fullWidth
        >
          <div style={{ paddingTop: 9 }}>
            {layoutSectionsInitiatives() === null ? (
              <Layout>
                <Layout.Section oneThird>
                  <CSSTransition
                    in={true}
                    appear={true}
                    timeout={1000}
                    classNames="transform"
                  >
                    <Card sectioned>
                      <div
                        style={{
                          height: 256,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center ",
                          color: "GrayText",
                        }}
                      >
                        <Stack vertical alignment="center" spacing="tight">
                          <p>{localize("NoInitiativesHere")}</p>
                        </Stack>
                      </div>
                    </Card>
                  </CSSTransition>
                </Layout.Section>
                <Layout.Section oneThird></Layout.Section>
                <Layout.Section oneThird></Layout.Section>
                <Layout.Section oneThird></Layout.Section>
              </Layout>
            ) : window.innerWidth < 1084 && window.innerWidth > 823 ? (
              <>
                <Layout>
                  {layoutSectionsInitiatives()
                    .slice(0, 3)
                    .map((value) => value)}
                </Layout>
                {layoutSectionsInitiatives().slice(3, 6).length > 0 ? (
                  <>
                    <br />
                    <Layout>
                      {layoutSectionsInitiatives()
                        .slice(3, 6)
                        .map((value) => value)}
                    </Layout>
                  </>
                ) : null}
                {layoutSectionsInitiatives().slice(6, 9).length > 0 ? (
                  <>
                    <br />
                    <Layout>
                      {layoutSectionsInitiatives()
                        .slice(6, 9)
                        .map((value) => value)}
                    </Layout>
                  </>
                ) : null}
                {layoutSectionsInitiatives().slice(9, 12).length > 0 ? (
                  <>
                    <br />
                    <Layout>
                      {layoutSectionsInitiatives()
                        .slice(9, 12)
                        .map((value) => value)}
                    </Layout>
                  </>
                ) : null}
              </>
            ) : (
              <>
                <Layout>
                  {layoutSectionsInitiatives()
                    .slice(0, 4)
                    .map((value) => value)}
                </Layout>
                {layoutSectionsInitiatives().slice(4, 8).length > 0 ? (
                  <>
                    <br />
                    <Layout>
                      {layoutSectionsInitiatives()
                        .slice(4, 8)
                        .map((value) => value)}
                    </Layout>
                  </>
                ) : null}
                {layoutSectionsInitiatives().slice(8, 12).length > 0 ? (
                  <>
                    <br />
                    <Layout>
                      {layoutSectionsInitiatives()
                        .slice(8, 12)
                        .map((value) => value)}
                    </Layout>
                  </>
                ) : null}
              </>
            )}
          </div>
        </Page>
      )} */}
    </>
  )
}

export default Overview
