import { Spinner, TopBar } from "@shopify/polaris";
import {
  LockMinor,
  LogOutMinor,
  PaintBrushMajor,
} from "@shopify/polaris-icons";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import Api from "../../../../api";
import { AuthContext } from "./../../../AuthContext";

// localizer
import { useLocalizer } from "reactjs-localizer";

const Bar = (toggleMobileNavigationActive) => {
  const history = useHistory();
  const { localize } = useLocalizer();

  const { setAuth, setTheme, theme } = useContext(AuthContext);
  const useHandleLogout = () => {
    setAuth({ type: "LOGOUT" });
  };

  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState();
  const fetchCurrentUser = async () => {
    const apiCall = await Api.user.getCurrentUser();

    setCurrentUser(apiCall);
    setLoading(false);
    localStorage.setItem("currentUser", JSON.stringify(apiCall));
  };
  useEffect(() => {
    if (localStorage.getItem("currentUser") === null) {
      fetchCurrentUser();
    } else {
      setCurrentUser(JSON.parse(localStorage.getItem("currentUser")));
      setLoading(false);
    }
  }, []);

  const [userMenuActive, setUserMenuActive] = useState(false);
  const toggleUserMenuActive = useCallback(
    () => setUserMenuActive((userMenuActive) => !userMenuActive),
    [setUserMenuActive]
  );

  const userMenuActions = [
    {
      items: [
        {
          content: "Privacy",
          icon: LockMinor,
          onAction: () => {
            history.push("/dashboard/privacy");
          },
        },
        {
          content:
            theme.colorScheme === "light"
              ? localize("DarkTheme")
              : localize("LightTheme"),
          icon: PaintBrushMajor,
          onAction: () => {
            let oldTheme = JSON.parse(JSON.stringify(theme));

            if (theme.colorScheme === "light") {
              oldTheme.colorScheme = "dark";
            } else {
              oldTheme.colorScheme = "light";
            }

            setTheme(oldTheme);
          },
        },
        {
          content: "Logout",
          icon: LogOutMinor,
          onAction: useHandleLogout,
        },
      ],
    },
  ];

  const userMenuMarkup = loading ? (
    <div style={{ paddingRight: 20 }}>
      <Spinner size="small" />
    </div>
  ) : (
    <TopBar.UserMenu
      actions={userMenuActions}
      name={currentUser.first_name + " " + currentUser.last_name}
      detail={currentUser.uid}
      initials={currentUser.first_name.charAt(0)}
      open={userMenuActive}
      onToggle={toggleUserMenuActive}
    />
  );

  return (
    <TopBar
      userMenu={userMenuMarkup}
      onNavigationToggle={toggleMobileNavigationActive}
    />
  );
};

export default Bar;
