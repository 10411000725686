// utility
import languagesArray from "../../../../app/config/languagesArray";

const allLanguages = languagesArray.map((l) => {
  return { id: l.id };
});

allLanguages.unshift({ id: "en" });

var globalObjEvent = {};

for (let i = 0; i < allLanguages.length; i++) {
  globalObjEvent[allLanguages[i].id] = {
    title: "",
    body: "",
    image: "",
  };
}

const resetGlobalObjEvent = () => {
  for (let i = 0; i < allLanguages.length; i++) {
    globalObjEvent[allLanguages[i].id].title = "";
    globalObjEvent[allLanguages[i].id].body = "";
    globalObjEvent[allLanguages[i].id].image = "";
  }
};

export default globalObjEvent;
export { resetGlobalObjEvent };
