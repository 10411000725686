import React from "react"
import { Router } from "react-router-dom"
import { createBrowserHistory } from "history"
import { AuthProvider } from "./app/AuthContext"
import AppRoutes from "./app/AppRoutes"

// localizer
import { Localizer } from "reactjs-localizer"
import strings from "./localizer"

const history = createBrowserHistory()

let userLang = navigator.language || navigator.userLanguage
userLang = userLang.slice(userLang.indexOf("-") + 1)
if (userLang !== "EN" && userLang !== "IT") {
  userLang = "EN"
}

Localizer.defaultLanguage = userLang

Localizer.mount(strings)

function App() {
  return (
    <AuthProvider>
      <Router history={history}>
        <AppRoutes />
      </Router>
    </AuthProvider>
  )
}

export default App
