//amplify
import { API } from "aws-amplify";

//axios
import axios from "axios";

//aws
import Amplify from "aws-amplify";
const { Auth } = Amplify;

class Service {
  constructor(headers = null) {
    let service = API;
    // service.interceptors.request.use(this.handleRequest, this.handleRequestError);
    // service.interceptors.response.use(this.handleSuccess, this.handleError);
    this.service = service;
  }

  handleRequest(config) {
    return config;
  }

  handleRequestError(error) {
    return Promise.reject(error);
  }

  handleSuccess(response) {
    return response.data;
  }

  handleError(error) {
    return Promise.reject(error.response);
  }

  async get(apiName, path, init) {
    console.log("Request GET", { apiName, path, init });
    return this.service.get(apiName, path, init);
  }

  // get without using aws
  async getAbstract(path) {
    console.log("Request GET", { path });
    var config = {
      method: "get",
      url: path,
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };
    return axios(config);
  }

  async delete(apiName, path) {
    console.log("Request DELETE", { apiName, path });
    return this.service.del(apiName, path);
  }

  async head(apiName, path) {
    return this.service.head(apiName, path);
  }

  async options(apiName, path) {
    return this.service.options(apiName, path);
  }

  async post(apiName, path, init) {
    console.log("Request POST", { apiName, path, init });
    return this.service.post(apiName, path, init);
  }

  async awsPut(apiName, path, init) {
    console.log("Request PUT", { apiName, path, init });
    return this.service.put(apiName, path, init);
  }

  // put without using aws for images
  async put(data, path, imageType) {
    console.log("Request PUT", { path, data, imageType });
    var config = {
      method: "put",
      url: path,
      headers: {
        "Content-Type": `image/${imageType}`,
      },
      data: data,
    };
    return axios(config);
  }

  // put without using aws for other files
  async putAbstract(path, data) {
    console.log("Request PUT", { path, data });
    var config = {
      method: "put",
      url: path,
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
      data: data,
    };
    return axios(config);
  }

  async patch(apiName, data, path) {
    return this.service.patch(apiName, data, path);
  }
}

export default Service;
