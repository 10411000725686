import React, { useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Route, Redirect, Link } from "react-router-dom";

import DashboardLayout from "./layouts/dashboard";
import AuthLayout from "./layouts/auth";
import translations from "@shopify/polaris/locales/en.json";
import { AppProvider } from "@shopify/polaris";
import { AuthContext } from "./AuthContext";
import Amplify from "aws-amplify";
const { Auth } = Amplify;

const AdapterLink = ({ url, ...args }) => <Link to={url} {...args} />;

const RouteWrapper = ({ component: Component, isPrivate, ...args }) => {
  const useEffectDoneRef = useRef(false);
  const lastPath = localStorage.getItem("lastPath");
  const { theme } = useContext(AuthContext);

  const { getAuth, setAuth } = useContext(AuthContext);
  useEffect(() => {
    const storedAuth = JSON.parse(localStorage.getItem("auth") || null);
    if (storedAuth && storedAuth.isAuthenticated) {
      setAuth({
        type: "LOGIN",
        payload: Auth.currentSession(),
      });
    }
    useEffectDoneRef.current = true;

    // to check if there is a current user
    let shouldLogOut = true;
    for (let i = 0; i < localStorage.length; i++) {
      let key = localStorage.key(i);
      if (key.includes("LastAuthUser")) {
        shouldLogOut = false;
      }
    }
    if (shouldLogOut && localStorage.getItem("lastPath")) {
      setAuth({ type: "LOGOUT" });
    }

    if (
      localStorage.getItem("lastPath") &&
      !localStorage.getItem("currentUser")
    ) {
      setAuth({ type: "LOGOUT" });
    }
  }, [setAuth]);
  let signed = getAuth.isAuthenticated;

  const checkSession = async () => {
    try {
      await Auth.currentSession();
      return;
    } catch {
      let theme;
      if (localStorage.getItem("theme")) {
        theme = localStorage.getItem("theme");
      }
      localStorage.clear();
      if (theme) {
        localStorage.setItem("theme", theme);
      }
      if (window.location.pathname !== "/") {
        window.location.reload();
      }
      return;
    }
  };

  useEffect(() => {
    checkSession();
  });

  // Redirect user to SignIn page if he tries to access a private route without authentication.
  if (isPrivate && !signed) {
    if (useEffectDoneRef.current) {
      return <Redirect to="/" />;
    }
  }

  // Redirect user to Main page if he tries to access a non private route (SignIn or SignUp) after being authenticated.
  if (!isPrivate && signed) {
    var path = "/dashboard";
    var savedPath = localStorage.getItem("lastPath");

    path = savedPath ? savedPath : path;

    if (useEffectDoneRef.current) {
      return <Redirect to={path} />;
    }
  }

  const Layout = signed ? DashboardLayout : AuthLayout;
  const url = window.location.href;

  // If not included on both previous cases, redirect user to the desired route.
  return (
    <>
      {useEffectDoneRef.current ? (
        <Route
          {...args}
          render={(props) => (
            <AppProvider
              features={{ newDesignLanguage: true }}
              theme={theme}
              i18n={translations}
              linkComponent={AdapterLink}
            >
              <Layout>
                <Component {...props} />
              </Layout>
            </AppProvider>
          )}
        />
      ) : lastPath === null ? (
        url.includes("accessToken") ? (
          <>
            <Redirect to={`/${url.slice(url.indexOf("?"))}`} />
          </>
        ) : (
          <>
            <Redirect to="/" />
          </>
        )
      ) : (
        <></>
      )}
    </>
  );
};

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
};

export default RouteWrapper;
