import React from "react"
import { Switch } from "react-router-dom"
import Route from "./Route"

import SignIn from "../modules/SignIn"
import Overview from "../modules/Overview"
import TeamNew from "../modules/Team/New"
import TeamShow from "../modules/Team/Show"
import TeamEdit from "../modules/Team/Show/edit"
import Initiative from "../modules/Initiative"
import EventNew from "../modules/Initiative/Event/New"
import EventShow from "../modules/Initiative/Event/Show"
import TeamNewForm from "../modules/Team/New/form"
import Privacy from "../modules/Privacy"
import AcademyClass from "../modules/Team/Academy"
import AcademyModule from "../modules/Team/Academy/module"
import Analytics from "../modules/Team/Academy/analytics"
import Challenge from "../modules/Team/Challenge"
import GroupLeaderboard from "../modules/Team/Challenge/groupLeaderboard"

export default function Routes() {
  return (
    <Switch>
      {/* Public root / Registration */}
      <Route exact path="/" component={SignIn} />

      {/* Dashboard */}
      <Route exact path="/dashboard" component={Overview} isPrivate />

      {/* Privacy */}
      <Route exact path="/dashboard/privacy" component={Privacy} isPrivate />

      {/* New team */}
      <Route
        exact
        path="/dashboard/createteam"
        component={TeamNewForm}
        isPrivate
      />
      <Route
        exact
        path="/dashboard/createteam/new"
        component={TeamNew}
        isPrivate
      />

      {/* Edit team */}
      <Route
        exact
        path="/dashboard/myteams/:id"
        component={TeamShow}
        isPrivate
      />
      <Route
        exact
        path="/dashboard/myteams/:id/edit"
        component={TeamEdit}
        isPrivate
      />

      {/* Academy */}
      <Route
        exact
        path="/dashboard/myteams/:teamId/academy/:id"
        component={AcademyClass}
        isPrivate
      />
      <Route
        exact
        path="/dashboard/myteams/:teamId/academy/:id/analytics"
        component={Analytics}
        isPrivate
      />
      <Route
        exact
        path="/dashboard/myteams/:teamId/academy/:classId/module/:moduleId/entity/:id"
        component={AcademyModule}
        isPrivate
      />

      {/* Initiative details */}
      <Route
        exact
        path="/dashboard/initiatives/:initiativeId"
        component={Initiative}
        isPrivate
      />

      {/* Add event */}
      <Route
        exact
        path="/dashboard/initiatives/:id/addevent"
        component={EventNew}
        isPrivate
      />

      {/* Event details */}
      <Route
        exact
        path="/dashboard/initiatives/:initiativeId/:eventId"
        component={EventShow}
        isPrivate
      />
      <Route
        exact
        path="/dashboard/initiatives/:initiativeId/:eventId/justcreated"
        component={EventShow}
        isPrivate
      />

      {/* Challenge */}
      <Route
        exact
        path="/dashboard/myteams/:teamId/challenge/:id"
        component={Challenge}
        isPrivate
      />
      <Route
        exact
        path="/dashboard/myteams/:teamId/challenge/:challengeId/group/:id/:name"
        component={GroupLeaderboard}
        isPrivate
      />

      {/* redirect user to SignIn page if route does not exist and user is not authenticated */}
      <Route component={SignIn} />
    </Switch>
  )
}
