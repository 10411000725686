let strings = {
  English: {
    EN: "English",
    IT: "Inglese",
  },
  Italian: {
    EN: "Italian",
    IT: "Italiano",
  },
  Spanish: {
    EN: "Spanish",
    IT: "Spagnolo",
  },
  French: {
    EN: "French",
    IT: "Francese",
  },
  MyTeams: {
    EN: "Your teams",
    IT: "I tuoi team",
  },
  CreateNewTeam: {
    EN: "Create new team",
    IT: "Crea un nuovo team",
  },
  Initiatives: {
    EN: "Initiatives you can support with your teams",
    IT: "Iniziative che puoi supportare con i tuoi team",
  },
  Create: {
    EN: "Create",
    IT: "Crea",
  },
  Details: {
    EN: "Details",
    IT: "Dettagli",
  },
  AddOrRemoveLanguages: {
    EN: "Add or remove languages",
    IT: "Aggiungi o rimuovi lingue",
  },
  Done: {
    EN: "Done",
    IT: "Fatto",
  },
  Cancel: {
    EN: "Cancel",
    IT: "Annulla",
  },
  Name: {
    EN: "Name",
    IT: "Nome",
  },
  Description: {
    EN: "Description",
    IT: "Descrizione",
  },
  NoDescription: {
    EN: "No description",
    IT: "Nessuna descrizione",
  },
  CopyFromEnglish: {
    EN: "Copy from english",
    IT: "Copia dall'inglese",
  },
  Image: {
    EN: "Image",
    IT: "Immagine",
  },
  Logo: {
    EN: "Logo (optional)",
    IT: "Logo (opzionale)",
  },
  LogoOnly: {
    EN: "Logo",
    IT: "Logo",
  },
  CoverImage: {
    EN: "Cover image",
    IT: "Immagine di copertina",
  },
  EnglishIsRequired: {
    EN: "English is required",
    IT: "L'inglese è obbligatorio",
  },
  TakeNoteAccessLink: {
    EN: "Take note of the invite link",
    IT: "Prendi nota del link di invito",
  },
  TakeNoteAccessLinkDescription: {
    EN: "This is the link you can send to people to invite them join this team, take note of it. Then you can still see it in the summary section of the team edit page.",
    IT: "Questo è il link che puoi inviare alle persone per invitarle a unirsi a questo team, prendine nota. Poi potrai comunque ancora vederlo nella sezione di riepilogo della pagina di modifica del team.",
  },
  CropImage: {
    EN: "Crop image",
    IT: "Ritaglia immagine",
  },
  AddImage: {
    EN: "Add image",
    IT: "Aggiungi immagine",
  },
  DropImage: {
    EN: "or drop it here to upload",
    IT: "o trascinala qui per caricarla",
  },
  SaveChanges: {
    EN: "Save changes",
    IT: "Salva modifiche",
  },
  Generality: {
    EN: "Generality",
    IT: "Generalità",
  },
  Summary: {
    EN: "Summary",
    IT: "Riepilogo",
  },
  Languages: {
    EN: "Languages",
    IT: "Lingue",
  },
  AccessLink: {
    EN: "Invite link",
    IT: "Link di invito",
  },
  Timing: {
    EN: "Timing",
    IT: "Tempo",
  },
  CreatedAt: {
    EN: "Created at",
    IT: "Creato il",
  },
  UpdatedAt: {
    EN: "Updated at",
    IT: "Aggiornato il",
  },
  ChangesSaved: {
    EN: "Changes saved",
    IT: "Modifiche salvate",
  },
  None: {
    EN: "none",
    IT: "nessuno",
  },
  NotAvailable: {
    EN: "not available",
    IT: "non disponibile",
  },
  NoActions: {
    EN: "No actions",
    IT: "Nessuna azione",
  },
  SupportInitiativeFirst: {
    EN: "Support the initiative first",
    IT: "Prima supporta l'iniziativa",
  },
  InitiativeSupported: {
    EN: "Initiative supported",
    IT: "Iniziativa supportata",
  },
  MembersInvited: {
    EN: "Members invited",
    IT: "Membri invitati",
  },
  SupportingWith: {
    EN: "supporting with",
    IT: "supportata con",
  },
  SuppWith: {
    EN: "supp. with",
    IT: "supp. con",
  },
  NotSupporting: {
    EN: "not supporting",
    IT: "non supportata",
  },
  Supporting: {
    EN: "supporting",
    IT: "supportata",
  },
  Support: {
    EN: "Support",
    IT: "Supporta",
  },
  AboutThisInitiative: {
    EN: "About this initiative",
    IT: "Descrizione",
  },
  Events: {
    EN: "Events",
    IT: "Eventi",
  },
  Search: {
    EN: "Search",
    IT: "Cerca",
  },
  AddEvent: {
    EN: "Add event",
    IT: "Aggiungi evento",
  },
  From: {
    EN: "from ",
    IT: "dal ",
  },
  To: {
    EN: " to ",
    IT: " al ",
  },
  FromHour: {
    EN: "from ",
    IT: "dalle ",
  },
  ToHour: {
    EN: " to ",
    IT: " alle ",
  },
  Teams: {
    EN: "teams",
    IT: "team",
  },
  GeneralInformation: {
    EN: "General information",
    IT: "Informazioni generali",
  },
  Duration: {
    EN: "Duration",
    IT: "Durata",
  },
  OrganizingTeam: {
    EN: "Organizing team",
    IT: "Team organizzatore",
  },
  PeoplePartecipating: {
    EN: "People partecipating",
    IT: "Persone che partecipano",
  },
  Attendees: {
    EN: "Attendees",
    IT: "Persone iscritte",
  },
  TeamsSupporting: {
    EN: "Teams supporting",
    IT: "Team che supportano",
  },
  ActionsTaken: {
    EN: "Actions taken",
    IT: "Azioni fatte",
  },
  ActionsOfTheInitiative: {
    EN: "Actions of the initiative",
    IT: "Azioni dell'iniziativa",
  },
  AlreadySupportedTheInitiative: {
    EN: "You have already supported the initiative with all your teams.",
    IT: "Hai già supportato l'iniziativa con tutti i tuoi team.",
  },
  AtLeastOneTeam: {
    EN: "You must have at least one team to support an initiative.",
    IT: "Devi avere almeno un team per poter supportare un'iniziativa.",
  },
  SelectTheTeamToSupport: {
    EN: "Select the team to support the initiative with:",
    IT: "Seleziona il team con cui supportare l'iniziativa:",
  },
  NoteThat: {
    EN: "Note that all members of the team that is used to support the initiative will be invited to participate.",
    IT: "Nota che tutti i membri del team che viene utilizzato per supportare l'iniziativa saranno invitati a partecipare.",
  },
  JAN: {
    EN: "JAN",
    IT: "GEN",
  },
  FEB: {
    EN: "FEB",
    IT: "FEB",
  },
  MAR: {
    EN: "MAR",
    IT: "MAR",
  },
  APR: {
    EN: "APR",
    IT: "APR",
  },
  MAY: {
    EN: "MAY",
    IT: "MAG",
  },
  JUN: {
    EN: "JUN",
    IT: "GIU",
  },
  JUL: {
    EN: "JUL",
    IT: "LUG",
  },
  AUG: {
    EN: "AUG",
    IT: "AGO",
  },
  SEP: {
    EN: "SEP",
    IT: "SET",
  },
  OCT: {
    EN: "OCT",
    IT: "OTT",
  },
  NOV: {
    EN: "NOV",
    IT: "NOV",
  },
  DEC: {
    EN: "DEC",
    IT: "DIC",
  },
  NoImage: {
    EN: "No image",
    IT: "Nessuna immagine",
  },
  NoTeamsHere: {
    EN: "No teams here!",
    IT: "Non ci sono team qui!",
  },
  ClickOnCreateNewTeam: {
    EN: 'Click on "Create new team" to add one',
    IT: 'Clicca su "Crea un nuovo team" per aggiungerne uno',
  },
  NoInitiativesHere: {
    EN: "No initiatives here!",
    IT: "Non ci sono iniziative qui!",
  },
  ErrorLoadingInitiatives: {
    EN: "Error loading initiatives",
    IT: "Errore durante il caricamento delle iniziative",
  },
  ErrorLoadingTeams: {
    EN: "Error loading teams",
    IT: "Errore durante il caricamento dei team",
  },
  ErrorLoadingInitiative: {
    EN: "Error loading initiative",
    IT: "Errore durante il caricamento dell'iniziativa",
  },
  Date: {
    EN: "Date",
    IT: "Data",
  },
  PickDate: {
    EN: "Pick date",
    IT: "Scegli data",
  },
  PickStartDate: {
    EN: "Pick start date",
    IT: "Scegli data di inizio",
  },
  PickEndDate: {
    EN: "Pick end date",
    IT: "Scegli data di fine",
  },
  Time: {
    EN: "Time",
    IT: "Ora",
  },
  Add: {
    EN: "Add",
    IT: "Aggiungi",
  },
  AddEventFor: {
    EN: "Add event for",
    IT: "Aggiungi evento per",
  },
  TeamNameMustBe: {
    EN: "Team name must be at least 4 characters long in each language",
    IT: "Il nome del team deve avere una lunghezza di almeno 4 caratteri in ogni lingua",
  },
  NoEventsFound: {
    EN: "No events found",
    IT: "Nessun evento trovato",
  },
  ActionsOfTheEvent: {
    EN: "Action of the event",
    IT: "Azione dell'evento",
  },
  CreatorTeam: {
    EN: "Creator team",
    IT: "Team creatore",
  },
  InvalidTime: {
    EN: "Invalid time",
    IT: "Orario non valido",
  },
  SecondTimeMustBeGreater: {
    EN: "The end time cannot be earlier than the start one",
    IT: "L'orario di fine non può essere precedente a quello di inizio",
  },
  InvalidDate: {
    EN: "The end date cannot be earlier than the start one",
    IT: "La data di fine non può essere precedente a quella di inizio",
  },
  Place: {
    EN: "Place",
    IT: "Luogo",
  },
  SomethingWentWrong: {
    EN: "Something went wrong",
    IT: "Qualcosa è andato storto",
  },
  Information: {
    EN: "Information",
    IT: "Informazioni",
  },
  Action: {
    EN: "Action",
    IT: "Azione",
  },
  CopyToClipboard: {
    EN: "Copy to clipboard",
    IT: "Copia negli appunti",
  },
  CopiedToClipboard: {
    EN: "Copied to clipboard",
    IT: "Copiato negli appunti",
  },
  FilterByProximity: {
    EN: "Filter by proximity",
    IT: "Filtra per prossimità",
  },
  Apply: {
    EN: "Apply",
    IT: "Applica",
  },
  Within: {
    EN: "within",
    IT: "entro",
  },
  RemoveFilter: {
    EN: "Remove filter",
    IT: "Rimuovi filtro",
  },
  WaitingForPosition: {
    EN: "Loading position",
    IT: "Carico la posizione",
  },
  UnableToLoadPosition: {
    EN: "Unable to load position",
    IT: "Impossibile caricare la posizione",
  },
  Invite: {
    EN: "Invite",
    IT: "Invita",
  },
  InviteUsers: {
    EN: "Invite to partecipate",
    IT: "Invita a partecipare",
  },
  GenerateInviteLink: {
    EN: "Generate invite link",
    IT: "Genera link di invito",
  },
  SelectTeam: {
    EN: "Select the team you want to invite people to join this initiative with:",
    IT: "Seleziona il team con cui vuoi invitare le persone a partecipare a questa iniziativa:",
  },
  TakeNoteInitiativeLinkDescription: {
    EN: "This is the link you can send to people to invite them partecipate in this initiative, take note of it.",
    IT: "Questo è il link che puoi inviare alle persone per invitarle a partecipare a questa iniziativa, prendine nota.",
  },
  SignInWith: {
    EN: "Sign in with",
    IT: "Accedi con",
  },
  Or: {
    EN: "or",
    IT: "oppure",
  },
  GetACode: {
    EN: "get a code emailed to you",
    IT: "ricevi un codice di accesso via email",
  },
  EmailAddress: {
    EN: "Email address",
    IT: "Indirizzo email",
  },
  EmailMeALoginLink: {
    EN: "Email me a login code",
    IT: "Inviami un codice di accesso",
  },
  InsertAValidEmail: {
    EN: "Insert a valid email address",
    IT: "Inserisci un indirizzo email valido",
  },
  CheckYourEmail: {
    EN: "Check your email",
    IT: "Controlla la tua email",
  },
  ToConfirmYourAccount: {
    EN: "To confirm your account enter the code we sent you",
    IT: "Per confermare il tuo account inserisci il codice che ti abbiamo inviato",
  },
  InsertAValidEmailAddress: {
    EN: "Insert a valid email address",
    IT: "Inserisci un indirizzo email valido",
  },
  EnterTheCode: {
    EN: "Enter the code",
    IT: "Inserisci il codice",
  },
  Next: {
    EN: "Next",
    IT: "Avanti",
  },
  WrongCode: {
    EN: "Wrong code",
    IT: "Codice errato",
  },
  HowShouldWeCallYou: {
    EN: "How should we call you?",
    IT: "Come ti chiami?",
  },
  WhichUsernameDoYouWantToUse: {
    EN: "Which username do you want to use?",
    IT: "Quale username vuoi usare?",
  },
  Continue: {
    EN: "Continue",
    IT: "Continua",
  },
  FirstName: {
    EN: "Your first name",
    IT: "Il tuo nome",
  },
  LastName: {
    EN: "Your last name",
    IT: "Il tuo cognome",
  },
  FirstNameOnly: {
    EN: "First name",
    IT: "Nome",
  },
  LastNameOnly: {
    EN: "Last name",
    IT: "Cognome",
  },
  Username: {
    EN: "Your username",
    IT: "Il tuo username",
  },
  AreYouAPrivate: {
    EN: "Are you a private individual or an organization?",
    IT: "Sei un privato o un'organizzazione?",
  },
  WhereAreYouFrom: {
    EN: "Where are you from?",
    IT: "Da dove vieni?",
  },
  HowManyPeople: {
    EN: "How many people do you think will join your team?",
    IT: "Quante persone pensi che entreranno a far parte del tuo team?",
  },
  WhatIsYourPhoneNumber: {
    EN: "What is your phone number?",
    IT: "Qual è il tuo numero di telefono?",
  },
  WhatIsYourEmail: {
    EN: "What is your contact email?",
    IT: "Qual è la tua email di contatto?",
  },
  PrivateIndividual: {
    EN: "Private individual",
    IT: "Privato",
  },
  Company: {
    EN: "Company",
    IT: "Azienda",
  },
  Institutional: {
    EN: "Institution",
    IT: "Istituzione",
  },
  Association: {
    EN: "Association",
    IT: "Associazione",
  },
  Other: {
    EN: "Other",
    IT: "Altro",
  },
  LessThan: {
    EN: "Less than",
    IT: "Meno di",
  },
  MoreThan: {
    EN: "More than",
    IT: "Più di",
  },
  FromUpperCase: {
    EN: "From",
    IT: "Da",
  },
  ToPeople: {
    EN: "to",
    IT: "a",
  },
  FirstTellUsSomething: {
    EN: "First tell us something about you",
    IT: "Prima dicci qualcosa di te",
  },
  ThisUsernameIsAlreadyTaken: {
    EN: "This username is already taken",
    IT: "Questo username non è disponibile",
  },
  WhatsThisAllAbout: {
    EN: "What's this all about?",
    IT: "Di cosa si tratta?",
  },
  HowCanIGetInvolved: {
    EN: "How can I get involved?",
    IT: "Come posso essere coinvolto?",
  },
  WhenCanITakePart: {
    EN: "When can I take part?",
    IT: "Quando posso partecipare?",
  },
  Privacy: {
    EN: "Privacy",
    IT: "Privacy",
  },
  TermsAndConditions: {
    EN: "Terms and Conditions",
    IT: "Termini e Condizioni",
  },
  TermsAndConditionsLowerCase: {
    EN: "terms and conditions",
    IT: "termini e condizioni",
  },
  ByContinuingYouAcceptThe: {
    EN: "By continuing you accept",
    IT: "Proseguendo accetti",
  },
  TheName: {
    EN: "The name",
    IT: "Il nome",
  },
  EnterAValidPhoneNumber: {
    EN: "Enter a valid phone number",
    IT: "Inserisci un numero di telefono valido",
  },
  EnterAValidEmailAddress: {
    EN: "Enter a valid email address",
    IT: "Inserisci un indirizzo email valido",
  },
  At: {
    EN: "at",
    IT: "alle",
  },
  Start: {
    EN: "Start",
    IT: "Inizio",
  },
  End: {
    EN: "End",
    IT: "Fine",
  },
  OptionalImage: {
    EN: "Image (optional)",
    IT: "Immagine (opzionale)",
  },
  OnlyLettersNumbers: {
    EN: "Only letters, numbers and the following characters are allowed:",
    IT: "Sono ammessi solo lettere, numeri e i seguenti caratteri:",
  },
  City: {
    EN: "City",
    IT: "Città",
  },
  HereYouCanSeeTeams: {
    EN: "Here you can see the teams you own.",
    IT: "Qui puoi vedere i team di cui sei proprietario.",
  },
  HereYouCanSeeInitiatives: {
    EN: "Here you can see the initiatives in progress.",
    IT: "Qui puoi vedere le iniziative in corso.",
  },
  Congratulations: {
    EN: "Congratulations, your event has been created!",
    IT: "Congratulazioni, il tuo evento è stato creato!",
  },
  EnterTheEventStartTime: {
    EN: "Enter the event start time",
    IT: "Inserisci l'ora di inizio dell'evento",
  },
  EnterTheEventEndTime: {
    EN: "Enter the event end time",
    IT: "Inserisci l'ora di fine dell'evento",
  },
  EnterTheCity: {
    EN: "Enter the city",
    IT: "Inserisci la città",
  },
  SomeDataAreMissing: {
    EN: "Some data are missing",
    IT: "Alcuni dati sono mancanti",
  },
  TheTitleIn: {
    EN: "The title in",
    IT: "Il titolo in",
  },
  TheDescriptionIn: {
    EN: "The description in",
    IT: "La descrizione in",
  },
  IsMissing: {
    EN: "is missing",
    IT: "è mancante",
  },
  LoadMoreEvents: {
    EN: "Load more events",
    IT: "Carica altri eventi",
  },
  LoadMore: {
    EN: "Load more",
    IT: "Carica altro",
  },
  Warning: {
    EN: "Warning",
    IT: "Avviso",
  },
  Yes: {
    EN: "Yes",
    IT: "Sì",
  },
  ByContinuingYouWillBeginToSupport: {
    EN: "By continuing you will begin to support ",
    IT: "Continuando inizierai a supportare ",
  },
  WithYourTeam: {
    EN: "with your team ",
    IT: "con il tuo team ",
  },
  DoYouWantToContinue: {
    EN: ". Do you want to continue?",
    IT: ". Vuoi proseguire?",
  },
  DarkTheme: {
    EN: "Dark theme",
    IT: "Tema scuro",
  },
  LightTheme: {
    EN: "Light theme",
    IT: "Tema chiaro",
  },
  BusinessLogin: {
    EN: "Business login",
    IT: "Accesso Business",
  },
  SelectOrganization: {
    EN: "Select organization",
    IT: "Seleziona organizzazione",
  },
  ThePastedText: {
    EN: "The pasted text exceeds 4000 characters",
    IT: "Il testo incollato supera i 4000 caratteri",
  },
  YouDoNotSupportInitiatives: {
    EN: "You do not support Initiatives with this Team",
    IT: "Non supporti Iniziative con questo Team",
  },
  Impact: {
    EN: "Impact",
    IT: "Impatto",
  },
  UnableToLoadImpact: {
    EN: "Unable to load impact",
    IT: "Impossibile caricare l'impatto",
  },
  ErrorLoadingImpact: {
    EN: "Error loading impact",
    IT: "Errore durante il caricamento dell'impatto",
  },
  NumberOfEvents: {
    EN: "Number of Events",
    IT: "Numero di Eventi",
  },
  Edit: {
    EN: "Edit",
    IT: "Modifica",
  },
  Education: {
    EN: "Education",
    IT: "Piano Formativo",
  },
  AccessQRCode: {
    EN: "Access QR Code",
    IT: "Codice QR di invito",
  },
  JoinTeam: {
    EN: "Join Team",
    IT: "Unisciti al Team",
  },
  Close: {
    EN: "Close",
    IT: "Chiudi",
  },
  Academy: {
    EN: "Academy",
    IT: "Piani Formativi",
  },
  AcademySingular: {
    EN: "Academy",
    IT: "Piano Formativo",
  },
  StartDate: {
    EN: "Start date",
    IT: "Data di inizio",
  },
  EndDate: {
    EN: "End date",
    IT: "Data di fine",
  },
  Modules: {
    EN: "Modules",
    IT: "Moduli",
  },
  Module: {
    EN: "Module",
    IT: "Modulo",
  },
  Episodes: {
    EN: "Episodes",
    IT: "Episodi",
  },
  JourneysAndSurveys: {
    EN: "Journeys and Surveys",
    IT: "Journey e Survey",
  },
  YouHaveNoAcademy: {
    EN: "You have no active Academy in this Team",
    IT: "Non hai Piani Formativi attivi in questo Team",
  },
  Analytics: {
    EN: "Analytics",
    IT: "Analitica",
  },
  Completed: {
    EN: "completed",
    IT: "completati",
  },
  Completion: {
    EN: "Completion",
    IT: "Completamento",
  },
  SelectDate: {
    EN: "Select date",
    IT: "Seleziona data",
  },
  Select: {
    EN: "Select",
    IT: "Seleziona",
  },
  LastUpdate: {
    EN: "Last update",
    IT: "Ultimo aggiornamento",
  },
  CompletedFemale: {
    EN: "completed",
    IT: "completate",
  },
  CompletedUppercase: {
    EN: "Completed",
    IT: "Completato",
  },
  Journeys: {
    EN: "Journeys",
    IT: "Journey",
  },
  InProgress: {
    EN: "In progress",
    IT: "In corso",
  },
  DistributionInModules: {
    EN: "Distribution in Modules",
    IT: "Distribuzione nei Moduli",
  },
  AcademyStatus: {
    EN: "Academy status",
    IT: "Stato Piano Formativo",
  },
  AverageModuleEvaluation: {
    EN: "Average Modules evaluation",
    IT: "Valutazione media Moduli",
  },
  AverageJourneyEvaluation: {
    EN: "Average Journeys evaluation",
    IT: "Valutazione media Journey",
  },
  GeneratingAnalytics: {
    EN: "Loading Analytics",
    IT: "Caricamento Analitica",
  },
  AcademyDetails: {
    EN: "Academy details",
    IT: "Dettagli Piano Formativo",
  },
  ModuleDetails: {
    EN: "Module #{id} details",
    IT: "Dettagli Modulo #{id}",
  },
  AverageEvaluation: {
    EN: "Average evaluation",
    IT: "Valutazione media",
  },
  TotalUsers: {
    EN: "Total users",
    IT: "Utenti totali",
  },
  NA: {
    EN: "N/A",
    IT: "N/D",
  },
  Journey: {
    EN: "Journey",
    IT: "Journey",
  },
  Overview: {
    EN: "Overview",
    IT: "Panoramica",
  },
  AcademyCompletion: {
    EN: "Academy completion",
    IT: "Completamento Piano Formativo",
  },
  DownloadAsCSV: {
    EN: "Download as CSV",
    IT: "Scarica come CSV",
  },
  DownloadGlobalAsCSV: {
    EN: "Download Global as CSV",
    IT: "Scarica Globale come CSV",
  },
  DownloadGroupsAsCSV: {
    EN: "Download Groups as CSV",
    IT: "Scarica Gruppi come CSV",
  },
  Distribution: {
    EN: "{module} distribution",
    IT: "Distribuzione {module}",
  },
  TeamMembers: {
    EN: "Team members",
    IT: "Membri del Team",
  },
  ParticipantsInThisAcademy: {
    EN: "Participants in the Academy",
    IT: "Partecipanti al Piano Formativo",
  },
  TotalNumberOfTeamMembers: {
    EN: "Total number of members of the Team this Academy belongs to",
    IT: "Numero totale di membri del Team a cui appartiene questo Piano Formativo",
  },
  NumberOfTeamMembersWho: {
    EN: "Number of Team members who initiated this Learning Plan. It represents 100% in the data below",
    IT: "Numero di membri del Team che hanno iniziato questo Piano Formativo. Rappresenta il 100% nei dati che seguono",
  },
  PercentageOfUsers: {
    EN: "Percentage of users who have completed the Academy out of the total",
    IT: "Percentuale di utenti che hanno completato il Piano Formativo sul totale",
  },
  NumberOfTimesThatModules: {
    EN: "Number of times that Modules in this Academy have been completed",
    IT: "Numero di volte che i moduli di questo Piano Formativo sono stati completati",
  },
  NumberOfTimesThatJourneys: {
    EN: "Number of times that Journeys in this Academy have been completed",
    IT: "Numero di volte che le Journey di questo Piano Formativo sono state completate",
  },
  NumberOfTimesThatEpisodes: {
    EN: "Number of times that Episodes in this Academy have been completed",
    IT: "Numero di volte che gli Episodi di questo Piano Formativo sono stati completati",
  },
  DistributionOfUsersInModules: {
    EN: "Distribution of users in Modules",
    IT: "Distribuzione degli utenti nei Moduli",
  },
  AverageEvaluationOfAllModules: {
    EN: "Average evaluation calculated across all Modules",
    IT: "Valutazione media calcolata tra tutti i Moduli",
  },
  AverageEvaluationOfAllJourneys: {
    EN: "Average evaluation calculated across all Journeys",
    IT: "Valutazione media calcolata tra tutte le Journey",
  },
  NoDataForTheSelectedDay: {
    EN: "No data for the selected day",
    IT: "Nessun dato per il giorno selezionato",
  },
  TodayAt: {
    EN: "today at",
    IT: "oggi alle",
  },
  Today: {
    EN: "Today",
    IT: "Oggi",
  },
  Challenges: {
    EN: "Challenges",
    IT: "Challenge",
  },
  YouHaveNoCurrentChallenges: {
    EN: "You have no current Challenges in this Team",
    IT: "Non hai Challenge correnti in questo Team",
  },
  YouHaveNoPastChallenges: {
    EN: "You have no past Challenges in this Team",
    IT: "Non hai Challenge passate in questo Team",
  },
  Leaderboard: {
    EN: "Leaderboard based on the number of actions",
    IT: "Classifica per numero di azioni",
  },
  LeaderboardOnly: {
    EN: "Leaderboard",
    IT: "Classifica",
  },
  Position: {
    EN: "Position",
    IT: "Posizione",
  },
  Global: {
    EN: "Global",
    IT: "Globale",
  },
  Groups: {
    EN: "Groups",
    IT: "Gruppi",
  },
  GroupName: {
    EN: "Group name",
    IT: "Nome gruppo",
  },
  GlobalLeaderboard: {
    EN: "Global leaderboard",
    IT: "Classifica globale",
  },
  GroupsLeaderboard: {
    EN: "Groups leaderboard",
    IT: "Classifica gruppi",
  },
  ClickOnAGroupToSeeItsLeaderboard: {
    EN: "Click on a Group to see its leaderboard",
    IT: "Clicca su un gruppo per vedere la sua classifica",
  },
  Members: {
    EN: "members",
    IT: "membri",
  },
  CurrentChallenges: {
    EN: "Current Challenges",
    IT: "Challenge correnti",
  },
  PastChallenges: {
    EN: "Past Challenges",
    IT: "Challenge passate",
  },
  Average: {
    EN: "average",
    IT: "media",
  },
  ImageResolutionIsTooHigh: {
    EN: "Image resolution is too high",
    IT: "La risoluzione dell'immagine è troppo alta",
  },
}

export default strings
