import React, { createContext, useEffect, useReducer, useState } from "react"

//aws
import Amplify from "aws-amplify"
const { Auth } = Amplify

const initialState = {
  isAuthenticated: false,
  email: null,
  user: null,
  tenant: null,
  cognitoUser: null,
}

const AuthContext = createContext()

let cognitoUser = null

const environment = {
  region: process.env.REACT_APP_REGION,
  userPoolId: process.env.REACT_APP_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_USER_WEB_CLIENT_ID,
}

const awsmobile = {
  aws_project_region: process.env.REACT_APP_AWS_REGION,
  aws_cognito_region: process.env.REACT_APP_AWS_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id:
    process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  oauth: {
    domain: process.env.REACT_APP_AWS_DOMAIN,
    scope: [
      "aws.cognito.signin.user.admin",
      "email",
      "openid",
      "phone",
      "profile",
    ],
    redirectSignIn: "https://web.aworld.org/",
    redirectSignOut: "https://web.aworld.org/",
    responseType: "code",
  },
  federationTarget: "COGNITO_USER_POOLS",
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_AWS_GRAPHQL_ENDPOINT,
  aws_appsync_region: process.env.REACT_APP_AWS_REGION,
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
}

// configure amplify
Amplify.configure({
  ...awsmobile,
  Auth: environment,
  API: {
    endpoints: [
      {
        name: "myTeamsEndpoint",
        endpoint: process.env.REACT_APP_API_BASEURL + "/teams/my",
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getAccessToken()
              .getJwtToken()}`,
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
            Expires: "0",
          }
        },
      },
      {
        name: "teamsEndpoint",
        endpoint: process.env.REACT_APP_API_BASEURL + "/teams",
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getAccessToken()
              .getJwtToken()}`,
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
            Expires: "0",
          }
        },
      },
      {
        name: "userEndpoint",
        endpoint: process.env.REACT_APP_API_BASEURL + "/user",
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getAccessToken()
              .getJwtToken()}`,
          }
        },
      },
      {
        name: "educationEndpoint",
        endpoint: process.env.REACT_APP_API_BASEURL + "/class",
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getAccessToken()
              .getJwtToken()}`,
          }
        },
      },
      {
        name: "challengesEndpoint",
        endpoint: process.env.REACT_APP_API_BASEURL + "/challenges",
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getAccessToken()
              .getJwtToken()}`,
            "aw-lang": navigator.language.slice(
              0,
              navigator.language.indexOf("-")
            ),
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
            Expires: "0",
          }
        },
      },
      {
        name: "signedUrlEndpoint",
        endpoint: process.env.REACT_APP_API_BASEURL,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getAccessToken()
              .getJwtToken()}`,
          }
        },
      },
    ],
  },
})

async function signIn(username) {
  try {
    const response = await Auth.signIn(username)
    cognitoUser = response

    return response
  } catch (e) {
    console.error(e)
    return "error"
  }
}

async function federatedSignIn(provider) {
  try {
    const response = await Auth.federatedSignIn({ provider: provider })
    cognitoUser = response

    return response
  } catch (e) {
    console.error(e)
    return "error"
  }
}

async function signUp(username, password, name, surname, uid) {
  try {
    const response = await Auth.signUp({
      username,
      password,
      attributes: {
        name: name + " " + surname,
        preferred_username: uid,
        "custom:tenant": "aworld",
      },
    })
    cognitoUser = response

    return response
  } catch (e) {
    console.log(e)
    return e.code
  }
}

async function answerCustomChallenge(code) {
  // Send the answer to the User Pool
  // This will throw an error if it’s the 3rd wrong answer TODO
  var user
  try {
    user = await Auth.sendCustomChallengeAnswer(cognitoUser, code)
  } catch {
    console.log("3rd wrong answer")
    return "3rd"
  }
  cognitoUser = user

  // It we get here, the answer was sent successfully,
  // but it might have been wrong (1st or 2nd time)
  // So we should test if the user is authenticated now
  try {
    // This will throw an error if the user is not yet authenticated:
    const sess = await Auth.currentSession()
    console.log("sess", sess)

    return sess
  } catch {
    console.log("Apparently the user did not enter the right code")

    return false
  }
}

const authReducer = (state, action) => {
  // console.log('state', state);
  // console.log("action", action);
  switch (action.type) {
    case "LOGIN":
      const auth = {
        isAuthenticated: true,
        cognitoUser: cognitoUser,
        session: action.payload.session,
        tenant: "aworld",
      }
      localStorage.setItem("auth", JSON.stringify(auth))
      return { ...state, ...auth }
    case "LOGOUT":
      let theme
      if (localStorage.getItem("theme")) {
        theme = localStorage.getItem("theme")
      }
      localStorage.clear()
      if (theme) {
        localStorage.setItem("theme", theme)
      }
      return { ...state, ...initialState }
    default:
      return state
  }
}

const AuthProvider = ({ children }) => {
  const [getAuth, setAuth] = useReducer(authReducer, initialState)
  const [theme, setTheme] = useState({
    colorScheme: localStorage.getItem("theme")
      ? localStorage.getItem("theme")
      : window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
      ? "dark"
      : "light",
    colors: {
      topBar: {
        background: "#FFFFF",
      },
    },
    logo: {
      width: 32,
      colorScheme: "light",
      background: "transparent",
      topBarSource: "https://assets.aworld.org/render/images/logos/aworld.png",
      contextualSaveBarSource:
        "https://assets.aworld.org/render/images/logos/aworld.png",
      url: "/dashboard",
      accessibilityLabel: "Aworld Partner Admin",
    },
  })

  useEffect(() => {
    localStorage.setItem("theme", theme.colorScheme)
    //eslint-disable-next-line
  }, [])

  return (
    <AuthContext.Provider value={{ getAuth, setAuth, theme, setTheme }}>
      {children}
    </AuthContext.Provider>
  )
}

export {
  AuthContext,
  AuthProvider,
  signIn,
  federatedSignIn,
  signUp,
  answerCustomChallenge,
}
