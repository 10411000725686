import React, { useState, useCallback, useEffect } from "react";
import { Page, Card, Select, TextField, Stack } from "@shopify/polaris";
import { useHistory } from "react-router-dom";

// animation
import { CSSTransition } from "react-transition-group";

//style
import "./styles.css";

// localizer
import { useLocalizer } from "reactjs-localizer";

// countries list
import { countries } from "countries-list";

// to validate phone numbers
import parsePhoneNumber from "libphonenumber-js";

// function to remove event listener
import debounce from "../../../app/config/debounceFunction";

const TeamNewForm = () => {
  const { localize } = useLocalizer();
  const history = useHistory();

  // REMOVE RESIZE END EVENT
  if (window.innerWidth > 450) {
    window.removeEventListener("resize", debounce);
  }

  // PRIVATE OR ORGANIZATION TYPE SELECT
  const [selectedType, setSelectedType] = useState("none");

  const handleTypeSelectChange = useCallback(
    (value) => setSelectedType(value),
    []
  );

  const typeOptions = [
    { label: "-", value: "none" },
    { label: localize("PrivateIndividual"), value: "private" },
    { label: localize("Company"), value: "company" },
    { label: localize("Institutional"), value: "institutional" },
    { label: localize("Association"), value: "association" },
    { label: localize("Other"), value: "other" },
  ];

  // COUNTRY SELECT
  const [selectedCountry, setSelectedCountry] = useState("none");

  const handleCountrySelectChange = useCallback(
    (value) => setSelectedCountry(value),
    []
  );

  const [countryOptions, setCountryOptions] = useState([]);

  useEffect(() => {
    var userLang = navigator.language || navigator.userLanguage;
    userLang = userLang.slice(userLang.indexOf("-") + 1);

    let options = [{ label: "-", value: "none" }];

    for (let key in countries) {
      options.push({ label: countries[key].native, value: key });
    }
    options.sort(function (a, b) {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });

    let currentCountry = options.filter((item) => item.value === userLang);
    if (currentCountry.length > 0) {
      setSelectedCountry(currentCountry[0].value);
    }

    setCountryOptions(options);
  }, []);

  // PEOPLE SELECT
  const [selectedPeople, setSelectedPeople] = useState("none");

  const handlePeopleSelectChange = useCallback(
    (value) => setSelectedPeople(value),
    []
  );

  const peopleOptions = [
    { label: "-", value: "none" },
    { label: `${localize("LessThan")} 10`, value: "less-than-10" },
    {
      label: `${localize("FromUpperCase")} 10 ${localize("ToPeople")} 50`,
      value: "from-10-to-50",
    },
    {
      label: `${localize("FromUpperCase")} 50 ${localize("ToPeople")} 100`,
      value: "from-50-to-100",
    },
    {
      label: `${localize("FromUpperCase")} 100 ${localize("ToPeople")} 200`,
      value: "from-100-to-200",
    },
    {
      label: `${localize("FromUpperCase")} 200 ${localize("ToPeople")} 1.000`,
      value: "from-200-to-1000",
    },
    {
      label: `${localize("FromUpperCase")} 1.000 ${localize("ToPeople")} 2.000`,
      value: "from-1000-to-2000",
    },
    { label: `${localize("MoreThan")} 2.000`, value: "more-than-2000" },
  ];

  // PHONE NUMBER
  const [numberValue, setNumberValue] = useState("");

  const handleNumberChange = useCallback((value) => {
    let regex = /^[0-9]*$/g;

    if (regex.test(value)) {
      setPhoneNumberError(false);
      setNumberValue(value);
    }
  }, []);

  // PHONE NUMBER PREFIX SELECT
  const [selectedPrefix, setSelectedPrefix] = useState("none");

  const handlePrefixSelectChange = useCallback(
    (value) => setSelectedPrefix(value),
    []
  );

  const [prefixOptions, setPrefixOptions] = useState([]);

  useEffect(() => {
    let options = [{ label: "-\xa0\xa0", value: "none" }];

    for (let key in countries) {
      options.push({
        label:
          countries[key].emoji +
          "\xa0\xa0+" +
          countries[key].phone +
          "\xa0(" +
          countries[key].native +
          ")",
        value: key,
      });
    }
    options.sort(function (a, b) {
      if (
        a.label.slice(a.label.indexOf("(") + 1) <
        b.label.slice(b.label.indexOf("(") + 1)
      ) {
        return -1;
      }
      if (
        a.label.slice(a.label.indexOf("(") + 1) >
        b.label.slice(b.label.indexOf("(") + 1)
      ) {
        return 1;
      }
      return 0;
    });

    setPrefixOptions(options);
  }, []);

  useEffect(() => {
    setSelectedPrefix(selectedCountry);
  }, [selectedCountry]);

  // EMAIL
  const [emailValue, setEmailValue] = useState("");

  const handleEmailChange = useCallback((value) => {
    setEmailError(false);
    setEmailValue(value);
  }, []);

  // NEXT BUTTON DISABLED
  const [nextButtonDisabled, setNextButtonDisabled] = useState(true);

  useEffect(() => {
    if (
      numberValue.length < 1 ||
      emailValue.length < 1 ||
      selectedType === "none" ||
      selectedCountry === "none" ||
      selectedPeople === "none" ||
      selectedPrefix === "none"
    ) {
      setNextButtonDisabled(true);
    } else {
      setNextButtonDisabled(false);
    }
    //eslint-disable-next-line
  }, [
    numberValue,
    emailValue,
    selectedType,
    selectedCountry,
    selectedPeople,
    selectedPrefix,
  ]);

  // INVALID PHONE NUMBER ERROR
  const [phoneNumberError, setPhoneNumberError] = useState(false);

  // INVALID EMAIL ERROR
  const [emailError, setEmailError] = useState(false);

  // NEXT ONACTION
  const next = () => {
    let isPhoneNumberValid = false;

    if (numberValue !== "") {
      let currentPhoneNumber = prefixOptions.filter(
        (value) => value.value === selectedPrefix
      );
      currentPhoneNumber =
        currentPhoneNumber[0].label.slice(
          currentPhoneNumber[0].label.indexOf("+"),
          currentPhoneNumber[0].label.indexOf("(") - 1
        ) + numberValue;

      let currentPhoneNumberParsed = parsePhoneNumber(currentPhoneNumber);

      if (currentPhoneNumberParsed !== undefined) {
        isPhoneNumberValid = currentPhoneNumberParsed.isValid();
      }
    }

    let regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let isEmailValid = regex.test(emailValue);

    if (!isPhoneNumberValid) {
      setPhoneNumberError(true);
      return;
    } else if (!isEmailValid) {
      setEmailError(true);
      return;
    }

    let country;
    for (let key in countries) {
      if (key === selectedCountry) {
        country = countries[key].name;
      }
    }

    let phoneNumberPrefix = prefixOptions.filter(
      (value) => value.value === selectedPrefix
    );
    phoneNumberPrefix = phoneNumberPrefix[0].label.slice(
      phoneNumberPrefix[0].label.indexOf("+")
    );
    let number = phoneNumberPrefix + numberValue;

    let teamOwnerInfos = {
      type: selectedType,
      country: country,
      expected_members: selectedPeople,
      contact_phone: number,
      contact_email: emailValue,
    };

    localStorage.setItem("teamOwnerInfos", JSON.stringify(teamOwnerInfos));

    history.push("/dashboard/createteam/new");
  };

  return (
    <Page
      title={localize("FirstTellUsSomething")}
      breadcrumbs={[{ content: "Overview", url: "/dashboard" }]}
      primaryAction={{
        content: localize("Next"),
        onAction: () => {
          next();
        },
        disabled: nextButtonDisabled,
      }}
    >
      <CSSTransition
        in={true}
        appear={true}
        timeout={1000}
        classNames="transform"
      >
        <Card sectioned title={localize("AreYouAPrivate")}>
          <Select
            options={typeOptions}
            onChange={handleTypeSelectChange}
            value={selectedType}
          />
        </Card>
      </CSSTransition>
      <CSSTransition
        in={true}
        appear={true}
        timeout={1000}
        classNames="transform"
      >
        <Card sectioned title={localize("WhereAreYouFrom")}>
          <Select
            options={countryOptions}
            onChange={handleCountrySelectChange}
            value={selectedCountry}
          />
        </Card>
      </CSSTransition>
      <CSSTransition
        in={true}
        appear={true}
        timeout={1000}
        classNames="transform"
      >
        <Card sectioned title={localize("HowManyPeople")}>
          <Select
            options={peopleOptions}
            onChange={handlePeopleSelectChange}
            value={selectedPeople}
          />
        </Card>
      </CSSTransition>
      <CSSTransition
        in={true}
        appear={true}
        timeout={1000}
        classNames="transform"
      >
        <Card sectioned title={localize("WhatIsYourPhoneNumber")}>
          <Stack>
            <Select
              options={prefixOptions}
              onChange={handlePrefixSelectChange}
              value={selectedPrefix}
            />
            <Stack.Item fill>
              <TextField
                value={numberValue}
                onChange={handleNumberChange}
                inputMode="tel"
                maxLength={10}
                error={
                  phoneNumberError ? localize("EnterAValidPhoneNumber") : false
                }
              />
            </Stack.Item>
          </Stack>
        </Card>
      </CSSTransition>
      <CSSTransition
        in={true}
        appear={true}
        timeout={1000}
        classNames="transform"
      >
        <Card sectioned title={localize("WhatIsYourEmail")}>
          <TextField
            value={emailValue}
            onChange={handleEmailChange}
            inputMode="email"
            error={emailError ? localize("EnterAValidEmailAddress") : false}
          />
        </Card>
      </CSSTransition>
    </Page>
  );
};

export default TeamNewForm;
