import React, { useState, useCallback, useEffect, useContext } from "react"
import {
  Page,
  Card,
  TextField,
  Layout,
  Tabs,
  Stack,
  Modal,
  ChoiceList,
  DropZone,
  Spinner,
  Toast,
  Button,
  Icon,
} from "@shopify/polaris"
import { ClipboardMinor } from "@shopify/polaris-icons"
import { useHistory } from "react-router-dom"

// animation
import { CSSTransition } from "react-transition-group"

// global object
import globalObjTeams from "../Utilities/globalObjTeams"

// text editor
import { Editor } from "react-draft-wysiwyg"
import { EditorState, ContentState, convertToRaw } from "draft-js"
import draftToHtml from "draftjs-to-html"
import htmlToDraft from "html-to-draftjs"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

//image cropper
import Slider from "@material-ui/core/Slider"
import Cropper from "react-easy-crop"
import { getCroppedImg } from "./../Utilities/canvasUtils"

//style
import "./styles.css"

// api
import Api from "../../../api"

// all languages array
import languagesArray from "../../../app/config/languagesArray"

// localizer
import { useLocalizer } from "reactjs-localizer"

// function to remove event listener
import debounce from "../../../app/config/debounceFunction"

// to parse uid
import slugify from "react-slugify"

// image compression
import imageCompression from "browser-image-compression"

// context
import { AuthContext } from "../../../app/AuthContext"

const TeamNew = () => {
  const history = useHistory()
  const [saving, setSaving] = useState(false)
  const { localize } = useLocalizer()
  const { theme } = useContext(AuthContext)

  const allLanguages = languagesArray.map((l) => {
    return { id: l.id, content: localize(l.content) }
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  if (!localStorage.getItem("teamOwnerInfos")) {
    history.push("/dashboard/createteam")
  }

  // REMOVE RESIZE END EVENT
  if (window.innerWidth > 450) {
    window.removeEventListener("resize", debounce)
  }

  // IMAGE RESOLUTION TOO HIGH TOAST
  const [resolutionToastActive, setResolutionToastActive] = useState(false)

  const toggleResolutionToastActive = useCallback(
    () =>
      setResolutionToastActive(
        (resolutionToastActive) => !resolutionToastActive
      ),
    []
  )

  const resolutionToastMarkup = resolutionToastActive ? (
    <Toast
      content={localize("ImageResolutionIsTooHigh")}
      error
      onDismiss={toggleResolutionToastActive}
    />
  ) : null

  // TOAST THAT SAYS TO USER TO FILL ENGLISH LANGUAGE
  const [createToastActive, setCreateToastActive] = useState(false)

  const toggleCreateToastActive = useCallback(
    () => setCreateToastActive((createToastActive) => !createToastActive),
    []
  )

  const showCreateToast = useCallback(() => setCreateToastActive(true), [])

  const createToastMarkup = createToastActive ? (
    <Toast
      content={localize("EnglishIsRequired")}
      onDismiss={toggleCreateToastActive}
      duration={2500}
    />
  ) : null

  useEffect(() => {
    let primaryActionButton = document.getElementsByClassName(
      "Polaris-Page-Header__PrimaryActionWrapper"
    )[0]
    primaryActionButton.addEventListener("click", () => {
      showCreateToast()
    })
    if (saving) {
      primaryActionButton.style.cursor = "default"
    } else {
      primaryActionButton.style.cursor = "pointer"
    }
    //eslint-disable-next-line
  }, [saving])

  // TABS
  const [selectedTab, setSelectedTab] = useState(0)

  const handleTabChange = useCallback(
    (selectedTabIndex) => setSelectedTab(selectedTabIndex),
    [setSelectedTab]
  )

  const [tabs, setTabs] = useState([
    {
      id: "en",
      content: localize("English"),
    },
  ])

  // CHOICE LIST
  const [selectedChoices, setSelectedChoices] = useState([])
  const [selectedChoicesWhenModalOpen, setSelectedChoicesWhenModalOpen] =
    useState([])
  const handleChoicesSelectionChange = useCallback(
    (value) => setSelectedChoices(value),
    [setSelectedChoices]
  )

  // MODAl
  const [modalActive, setModalActive] = useState(false)

  const handleModalOpen = useCallback(() => {
    setModalActive(true)
    setSelectedChoicesWhenModalOpen(selectedChoices)
  }, [selectedChoices])

  const handleModalClose = useCallback(() => {
    setModalActive(false)
    setSelectedChoices(selectedChoicesWhenModalOpen)
  }, [selectedChoicesWhenModalOpen])

  const handleLanguagesManaged = useCallback(() => {
    setModalActive(!modalActive)

    let newTabs = [
      {
        id: "en",
        content: localize("English"),
      },
    ]
    for (let i = 0; i < selectedChoices.length; i++) {
      for (let j = 0; j < allLanguages.length; j++) {
        if (selectedChoices[i] === allLanguages[j].id) {
          newTabs.push(allLanguages[j])
        }
      }
    }

    if (selectedTab >= newTabs.length) {
      setSelectedTab(0)
    }

    setTabs([...newTabs])
  }, [modalActive, selectedChoices, selectedTab, allLanguages, localize])

  // NAME LENGTH MUST BE > 3
  const [nameLengthError, setNameLengthError] = useState(false)

  // NAME
  const [name, setName] = useState("")
  const nameChange = useCallback(
    (value) => {
      let regex = /^$|[0-9A-Za-z-.,_#?!' \u00C0-\u00F6\u00F8-\u00FF]+$/
      if (regex.test(value)) {
        setName(value)
      }
      setNameLengthError(false)
    },
    [setName, setNameLengthError]
  )

  // COPY NAME FROM ENGLISH
  const copyNameFromEnglish = () => {
    let nameToSet = globalObjTeams.en.heading
    setName(nameToSet)
  }

  // EDITOR
  const [editorState, setEditorState] = useState(EditorState.createEmpty())

  // LOGO CROP
  // IMAGE CROP
  const [cropLogo, setCropLogo] = useState({ x: 0, y: 0 })
  const [zoomLogo, setZoomLogo] = useState(1)
  const [croppedAreaPixelsLogo, setCroppedAreaPixelsLogo] = useState(null)
  const [croppedImageLogo, setCroppedImageLogo] = useState(null)
  const [imageSrcLogo, setImageSrcLogo] = useState(null)
  const [doneButtonDisabledLogo, setDoneButtonDisabledLogo] = useState(false)
  const onCropCompleteLogo = useCallback(
    (croppedAreaLogo, croppedAreaPixelsLogo) => {
      setCroppedAreaPixelsLogo(croppedAreaPixelsLogo)
    },
    []
  )

  // read file dropped in the drop zone
  function readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.addEventListener("load", () => resolve(reader.result), false)
      reader.readAsDataURL(file)
    })
  }

  // cropped image displayed
  const croppedImageVisualLogo = croppedImageLogo && (
    <img src={croppedImageLogo} alt="" className="cropped-image-visual-logo" />
  )

  // IMAGE UPLOAD
  const [uploadedImageLogo, setUploadedImageLogo] = useState()

  const fileUploadLogo = !uploadedImageLogo && !croppedImageLogo && (
    <DropZone.FileUpload
      actionTitle={localize("AddImage")}
      actionHint={localize("DropImage")}
    />
  )

  const onFileChangeLogo = useCallback(async () => {
    let imageDataUrl = await readFile(uploadedImageLogo)
    setImageSrcLogo(imageDataUrl)
  }, [uploadedImageLogo])

  // MODAL FOR CROPPING
  const [modalForCroppingActiveLogo, setModalForCroppingActiveLogo] =
    useState(false)

  const handleModalForCroppingChangeLogo = useCallback(() => {
    setModalForCroppingActiveLogo(!modalForCroppingActiveLogo)
    setCropLogo({ x: 0, y: 0 })
    setZoomLogo(1)
  }, [modalForCroppingActiveLogo])

  const handleModalForCroppingCloseLogo = useCallback(() => {
    setModalForCroppingActiveLogo(!modalForCroppingActiveLogo)
    setUploadedImageLogo()
    setCropLogo({ x: 0, y: 0 })
    setZoomLogo(1)
  }, [modalForCroppingActiveLogo])

  useEffect(() => {
    if (uploadedImageLogo) {
      onFileChangeLogo()
    }
  }, [uploadedImageLogo, onFileChangeLogo])

  // handle the dropped file
  const handleDropZoneDropLogo = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) => {
      if (acceptedFiles.length > 0) {
        setUploadedImageLogo(acceptedFiles[0])
        handleModalForCroppingChangeLogo()
      }
    },
    [handleModalForCroppingChangeLogo]
  )

  const takeCroppedImageLogo = async () => {
    setDoneButtonDisabledLogo(true)

    try {
      const croppedImageToSave = await getCroppedImg(
        imageSrcLogo,
        croppedAreaPixelsLogo
      )

      if (!croppedImageToSave) {
        toggleResolutionToastActive()
        setDoneButtonDisabledLogo(false)
        return
      }

      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      }
      try {
        const blobNotCompressed = await fetch(croppedImageToSave).then((r) =>
          r.blob()
        )

        const blob = await imageCompression(blobNotCompressed, options)

        const apiCall = await Api.teams.getSignedUrl()
        const imageUrl = apiCall.getUrl
        const path = apiCall.s3PutObjectUrl

        await Api.teams.putS3Object(
          blob,
          path,
          blob.type.slice(blob.type.indexOf("/") + 1)
        )
        setCroppedImageLogo(imageUrl)
        handleModalForCroppingChangeLogo()
        setUploadedImageLogo()
        setDoneButtonDisabledLogo(false)
      } catch (e) {
        console.log(e)
      }
    } catch (e) {
      console.error(e)
      setDoneButtonDisabledLogo(false)
    }
  }

  // COVER CROP
  // IMAGE CROP
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)
  const [imageSrc, setImageSrc] = useState(null)
  const [doneButtonDisabled, setDoneButtonDisabled] = useState(false)
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  // cropped image displayed
  const croppedImageVisual = croppedImage && (
    <img src={croppedImage} alt="" className="cropped-image-visual" />
  )

  // IMAGE UPLOAD
  const [uploadedImage, setUploadedImage] = useState()

  const fileUpload = !uploadedImage && !croppedImage && (
    <DropZone.FileUpload
      actionTitle={localize("AddImage")}
      actionHint={localize("DropImage")}
    />
  )

  const onFileChange = useCallback(async () => {
    let imageDataUrl = await readFile(uploadedImage)
    setImageSrc(imageDataUrl)
  }, [uploadedImage])

  // MODAL FOR CROPPING
  const [modalForCroppingActive, setModalForCroppingActive] = useState(false)

  const handleModalForCroppingChange = useCallback(() => {
    setModalForCroppingActive(!modalForCroppingActive)
    setCrop({ x: 0, y: 0 })
    setZoom(1)
  }, [modalForCroppingActive])

  const handleModalForCroppingClose = useCallback(() => {
    setModalForCroppingActive(!modalForCroppingActive)
    setUploadedImage()
    setCrop({ x: 0, y: 0 })
    setZoom(1)
  }, [modalForCroppingActive])

  useEffect(() => {
    if (uploadedImage) {
      onFileChange()
    }
  }, [uploadedImage, onFileChange])

  // handle the dropped file
  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) => {
      if (acceptedFiles.length > 0) {
        setUploadedImage(acceptedFiles[0])
        handleModalForCroppingChange()
      }
    },
    [handleModalForCroppingChange]
  )

  const takeCroppedImage = useCallback(async () => {
    setDoneButtonDisabled(true)

    try {
      const croppedImageToSave = await getCroppedImg(
        imageSrc,
        croppedAreaPixels
      )

      if (!croppedImageToSave) {
        toggleResolutionToastActive()
        setDoneButtonDisabled(false)
        return
      }

      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      }
      try {
        const blobNotCompressed = await fetch(croppedImageToSave).then((r) =>
          r.blob()
        )

        const blob = await imageCompression(blobNotCompressed, options)

        const apiCall = await Api.teams.getSignedUrl()
        const imageUrl = apiCall.getUrl
        const path = apiCall.s3PutObjectUrl
        console.log(imageUrl)

        await Api.teams.putS3Object(blob, path)
        setCroppedImage(imageUrl)
        handleModalForCroppingChange()
        setUploadedImage()
        setDoneButtonDisabled(false)
      } catch (e) {
        console.log(e)
      }
    } catch (e) {
      console.error(e)
      setDoneButtonDisabled(false)
    }
  }, [imageSrc, croppedAreaPixels, handleModalForCroppingChange])

  // DISABLE CREATE BUTTON
  const [createButtonDisabled, setCreateButtonDisabled] = useState(true)

  // WRITE ON GLOBAL OBJECT
  useEffect(() => {
    globalObjTeams[tabs[selectedTab].id].heading = name
    globalObjTeams[tabs[selectedTab].id].body = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    )
    if (croppedImage) {
      for (let key in globalObjTeams) {
        globalObjTeams[key].cover = croppedImage
      }
    }
    if (croppedImageLogo) {
      for (let key in globalObjTeams) {
        globalObjTeams[key].img = croppedImageLogo
      }
    }

    if (
      globalObjTeams.en.heading !== "" &&
      !globalObjTeams.en.body.startsWith("<p></p>") &&
      globalObjTeams.en.cover !== ""
    ) {
      setCreateButtonDisabled(false)
    } else {
      setCreateButtonDisabled(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, editorState, croppedImage, croppedImageLogo])

  // READ FROM GLOBAL OBJECT
  useEffect(() => {
    setName(globalObjTeams[tabs[selectedTab].id].heading)

    const blocksFromHtml = htmlToDraft(
      globalObjTeams[tabs[selectedTab].id].body
    )
    const { contentBlocks, entityMap } = blocksFromHtml
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    )
    setEditorState(EditorState.createWithContent(contentState))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab])

  // SAVE
  const [errorToastActive, setErrorToastActive] = useState(false)
  const [errorToastMessage, setErrorToastMessage] = useState("")

  const toggleErrorToastActive = useCallback(
    () => setErrorToastActive((errorToastActive) => !errorToastActive),
    []
  )

  const errorToastMarkup = errorToastActive ? (
    <Toast
      content={errorToastMessage}
      onDismiss={toggleErrorToastActive}
      error
    />
  ) : null

  const onSave = async () => {
    setCreateButtonDisabled(true)
    setSaving(true)
    setErrorToastActive(false)

    let globalObjToPass = JSON.parse(JSON.stringify(globalObjTeams))

    let languagesToMantein = []
    for (let i = 0; i < tabs.length; i++) {
      languagesToMantein.push(tabs[i].id)
    }

    for (let key in globalObjToPass) {
      if (!languagesToMantein.includes(key)) {
        delete globalObjToPass[key]
      }
    }

    for (let i = 0; i < languagesToMantein.length; i++) {
      if (
        globalObjToPass[languagesToMantein[i]].heading === "" ||
        globalObjToPass[languagesToMantein[i]].body === "" ||
        globalObjToPass[languagesToMantein[i]].body.startsWith("<p></p>")
      ) {
        delete globalObjToPass[languagesToMantein[i]]
      } else {
        globalObjToPass[languagesToMantein[i]].body = globalObjToPass[
          languagesToMantein[i]
        ].body.slice(0, globalObjToPass[languagesToMantein[i]].body.length - 1)
      }
    }

    let canCreate = true
    for (let key in globalObjToPass) {
      if (globalObjToPass[key].img === "") {
        globalObjToPass[key].img =
          "https://cdn.aworld.io/assets/placeholder_team_icon.png"
      }
      if (globalObjToPass[key].heading.length < 4) {
        canCreate = false
      }
    }

    for (let key in globalObjToPass) {
      globalObjToPass[key].body = globalObjToPass[key].body.replace(
        /(\r\n|\n|\r)/gm,
        ""
      )
    }

    const teamOwnerInfos = JSON.parse(localStorage.getItem("teamOwnerInfos"))

    const body = {
      body: {
        name: globalObjTeams.en.heading,
        uid: slugify(globalObjTeams.en.heading, { delimiter: "-" }),
        active: 1,
        sorting: 0,
        showcase: 0,
        access_type: "public",
        metrics: 0,
        leaderboard: 0,
        documents: globalObjToPass,
        meta_fields: teamOwnerInfos,
      },
    }

    if (canCreate) {
      try {
        await Api.teams.createTeam(body)
        localStorage.setItem("shouldRefresh", true)
      } catch (e) {
        if (
          e.response.data.errors &&
          e.response.data.errors[0] === "The UID is already in use"
        ) {
          setErrorToastMessage(
            `Il nome "${globalObjTeams.en.heading}" non è disponibile`
          )
        } else {
          setErrorToastMessage(e.message)
        }
        setErrorToastActive(true)
        setCreateButtonDisabled(false)
        setSaving(false)
        return
      }

      setCreateButtonDisabled(false)
      setSaving(false)

      history.push("/dashboard")
    } else {
      setCreateButtonDisabled(false)
      setSaving(false)
      setNameLengthError(true)
    }
  }

  // MODAL FOR ACCESS LINK
  const [modalLinkActive, setModalLinkActive] = useState(false)

  const handleModalLinkOpen = useCallback(() => {
    setModalLinkActive(true)
  }, [])

  const handleModalLinkClose = useCallback(() => {
    setModalLinkActive(false)
  }, [])

  // FUNCTION TO COPY ACCESS LINK IN CLIPBOARD
  const [clipboardToastActive, setClipboardToastActive] = useState(false)

  const toggleClipboardToast = useCallback(
    () =>
      setClipboardToastActive((clipboardToastActive) => !clipboardToastActive),
    []
  )

  const toggleClipboardToastActive = useCallback(
    () => setClipboardToastActive(true),
    []
  )

  const clipboardToastMarkup = clipboardToastActive ? (
    <Toast
      content={localize("CopiedToClipboard")}
      onDismiss={toggleClipboardToast}
    />
  ) : null

  const copyToClipboard = () => {
    var copyText = document.getElementById("access-link")

    copyText.select()
    copyText.setSelectionRange(0, 99999)

    document.execCommand("copy")

    toggleClipboardToastActive()
  }

  // ERROR WHEN A TEXT WITH 4000 CHARACTERS OR MORE IS PASTED
  const [lengthToastActive, setLengthToastActive] = useState(false)

  const toggleLengthToastActive = useCallback(
    () => setLengthToastActive((lengthToastActive) => !lengthToastActive),
    []
  )

  const lengthToastMarkup = lengthToastActive ? (
    <Toast
      content={localize("ThePastedText")}
      onDismiss={toggleLengthToastActive}
      error
      duration={3500}
    />
  ) : null

  return (
    <Page
      title={localize("CreateNewTeam")}
      breadcrumbs={[{ content: "Overview", url: "/dashboard" }]}
      primaryAction={{
        content: saving ? <Spinner size="small" /> : localize("Create"),
        onAction: () => {
          toggleCreateToastActive()
          handleModalLinkOpen()
        },
        disabled: createButtonDisabled,
      }}
    >
      <Modal
        open={modalLinkActive}
        onClose={handleModalLinkClose}
        title={localize("TakeNoteAccessLink")}
        primaryAction={{
          content: "Ok",
          onAction: () => {
            handleModalLinkClose()
            onSave()
          },
        }}
      >
        <Modal.Section>
          <Stack vertical>
            <Stack alignment="center">
              <div
                onClick={copyToClipboard}
                title={localize("CopyToClipboard")}
                style={{
                  cursor: "pointer",
                }}
              >
                <Icon source={ClipboardMinor} color="inkLight" />
              </div>
              <input
                className={theme.colorScheme === "dark" && "input-dark"}
                readOnly
                id="access-link"
                type="text"
                value={
                  "https://aworld.org/join/team/" +
                  globalObjTeams.en.heading.toLowerCase().replace(/ /g, "-")
                }
                style={{
                  fontWeight: "bold",
                  fontSize: 14,
                  border: "none",
                  cursor: "default",
                  width: "90vw",
                  maxWidth: 530,
                }}
              />
            </Stack>
            <p>{localize("TakeNoteAccessLinkDescription")}</p>
          </Stack>
        </Modal.Section>
      </Modal>
      <Modal
        open={modalActive}
        onClose={handleModalClose}
        title={localize("AddOrRemoveLanguages")}
        primaryAction={{
          content: localize("Done"),
          onAction: handleLanguagesManaged,
        }}
      >
        <Modal.Section>
          <ChoiceList
            allowMultiple
            choices={[
              {
                label: localize("English"),
                value: "en",
              },
            ]}
            selected={["en"]}
            disabled
          />
          <ChoiceList
            allowMultiple
            choices={[
              {
                label: localize("Italian"),
                value: "it",
              },
              {
                label: localize("Spanish"),
                value: "es",
              },
              {
                label: localize("French"),
                value: "fr",
              },
            ]}
            selected={selectedChoices}
            onChange={handleChoicesSelectionChange}
          />
        </Modal.Section>
      </Modal>
      <Layout>
        {/* <Layout.Section>
          <Banner>
            <p>Stai creando un team con funzionalità limitate ecc.</p>
          </Banner>
        </Layout.Section> */}
        <Layout.Section>
          <CSSTransition
            in={true}
            appear={true}
            timeout={1000}
            classNames="transform"
          >
            <Card
              title={localize("Details")}
              actions={[
                {
                  content: localize("AddOrRemoveLanguages"),
                  onAction: handleModalOpen,
                },
              ]}
            >
              <Tabs
                tabs={tabs}
                selected={selectedTab}
                onSelect={handleTabChange}
              ></Tabs>
              <Card.Section>
                <Stack vertical>
                  {selectedTab === 0 ? (
                    <TextField
                      type="text"
                      label={localize("Name")}
                      helpText={
                        !nameLengthError &&
                        localize("OnlyLettersNumbers") + " #?!-,_."
                      }
                      placeholder={
                        localize("Name") +
                        " (" +
                        tabs[selectedTab].content +
                        ")"
                      }
                      onBlur={() => {
                        setName(name.trim())
                      }}
                      error={
                        nameLengthError ? localize("TeamNameMustBe") : null
                      }
                      value={name}
                      onChange={nameChange}
                      clearButton={true}
                      onClearButtonClick={() => {
                        setName("")
                        setNameLengthError(false)
                      }}
                      maxLength={60}
                      showCharacterCount
                    />
                  ) : window.innerWidth < 439 ? (
                    <Stack vertical>
                      <TextField
                        type="text"
                        label={localize("Name")}
                        helpText={
                          !nameLengthError &&
                          localize("OnlyLettersNumbers") + " #?!-,_."
                        }
                        placeholder={
                          localize("Name") +
                          " (" +
                          tabs[selectedTab].content +
                          ")"
                        }
                        onBlur={() => {
                          setName(name.trim())
                        }}
                        error={
                          nameLengthError ? localize("TeamNameMustBe") : null
                        }
                        value={name}
                        onChange={nameChange}
                        clearButton={true}
                        onClearButtonClick={() => {
                          setName("")
                          setNameLengthError(false)
                        }}
                        maxLength={60}
                        showCharacterCount
                      />
                      <Button fullWidth onClick={copyNameFromEnglish}>
                        {localize("CopyFromEnglish")}
                      </Button>
                    </Stack>
                  ) : (
                    <Stack alignment="center">
                      <Stack.Item fill>
                        <TextField
                          type="text"
                          label={localize("Name")}
                          helpText={
                            !nameLengthError &&
                            localize("OnlyLettersNumbers") + " #?!-,_."
                          }
                          placeholder={
                            localize("Name") +
                            " (" +
                            tabs[selectedTab].content +
                            ")"
                          }
                          onBlur={() => {
                            setName(name.trim())
                          }}
                          error={
                            nameLengthError ? localize("TeamNameMustBe") : null
                          }
                          value={name}
                          onChange={nameChange}
                          clearButton={true}
                          onClearButtonClick={() => {
                            setName("")
                            setNameLengthError(false)
                          }}
                          maxLength={60}
                          showCharacterCount
                        />
                      </Stack.Item>
                      <Stack.Item>
                        <Button fullWidth onClick={copyNameFromEnglish}>
                          {localize("CopyFromEnglish")}
                        </Button>
                      </Stack.Item>
                    </Stack>
                  )}
                  <p className="negative-margin-bottom">
                    {localize("Description")}
                  </p>
                  <Editor
                    toolbarClassName={
                      theme.colorScheme === "dark" && "rdw-editor-toolbar-dark"
                    }
                    onBlur={() => {
                      // to remove whitespaces before and after string
                      let currentContent = draftToHtml(
                        convertToRaw(editorState.getCurrentContent())
                      )
                      currentContent = currentContent.replace(/  +/g, " ")
                      currentContent = currentContent.replaceAll("&nbsp;", "")
                      const blocksFromHtml = htmlToDraft(currentContent)
                      const { contentBlocks, entityMap } = blocksFromHtml
                      const contentState = ContentState.createFromBlockArray(
                        contentBlocks,
                        entityMap
                      )
                      setEditorState(
                        EditorState.createWithContent(contentState)
                      )
                    }}
                    editorState={editorState}
                    placeholder={
                      localize("Description") +
                      " (" +
                      tabs[selectedTab].content +
                      ")"
                    }
                    handleBeforeInput={(val) => {
                      const textLength = editorState
                        .getCurrentContent()
                        .getPlainText().length
                      if (val && textLength >= 4000) {
                        return "handled"
                      }
                      return "not-handled"
                    }}
                    handlePastedText={(val) => {
                      const textLength = editorState
                        .getCurrentContent()
                        .getPlainText().length
                      if (val.length + textLength >= 4000) {
                        toggleLengthToastActive()
                        return true
                      } else {
                        return false
                      }
                    }}
                    onEditorStateChange={setEditorState}
                    toolbar={{
                      options: ["inline", "list", "history", "link"],
                      inline: {
                        options: ["bold", "italic", "underline"],
                      },
                      list: {
                        options: ["unordered"],
                      },
                    }}
                  />
                </Stack>
              </Card.Section>
            </Card>
          </CSSTransition>
          <CSSTransition
            in={true}
            appear={true}
            timeout={1000}
            classNames="transform"
          >
            <Card title={localize("CoverImage")}>
              <Card.Section>
                <Stack vertical>
                  <DropZone
                    allowMultiple={false}
                    type="image"
                    onDrop={handleDropZoneDrop}
                    accept="image/*"
                  >
                    <div
                      style={{
                        height: "auto",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {croppedImageVisual}
                    </div>
                    {fileUpload}
                  </DropZone>
                  <Modal
                    open={modalForCroppingActive}
                    onClose={handleModalForCroppingClose}
                    title={localize("CropImage")}
                    primaryAction={
                      doneButtonDisabled
                        ? {
                            content: <Spinner size="small" />,
                            disabled: true,
                          }
                        : {
                            content: localize("Done"),
                            onAction: takeCroppedImage,
                          }
                    }
                    secondaryActions={[
                      {
                        content: localize("Cancel"),
                        onAction: handleModalForCroppingClose,
                      },
                    ]}
                  >
                    <Modal.Section>
                      <div className="cropper-container">
                        <Cropper
                          image={imageSrc}
                          crop={crop}
                          zoom={zoom}
                          aspect={16 / 9}
                          onCropChange={setCrop}
                          onCropComplete={onCropComplete}
                          onZoomChange={setZoom}
                        />
                      </div>
                      <div
                        className="slider-container"
                        style={{
                          backgroundColor:
                            theme.colorScheme === "dark" ? "#202123" : "white",
                        }}
                      >
                        <Slider
                          value={zoom}
                          min={1}
                          max={3}
                          step={0.1}
                          aria-labelledby="Zoom"
                          onChange={(e, zoom) => setZoom(zoom)}
                        />
                      </div>
                    </Modal.Section>
                  </Modal>
                </Stack>
              </Card.Section>
            </Card>
          </CSSTransition>
          <CSSTransition
            in={true}
            appear={true}
            timeout={1000}
            classNames="transform"
          >
            <Card title={localize("Logo")}>
              <Card.Section>
                <Stack vertical>
                  <DropZone
                    allowMultiple={false}
                    type="image"
                    onDrop={handleDropZoneDropLogo}
                    accept="image/*"
                  >
                    <div
                      style={{
                        height: "auto",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {croppedImageVisualLogo}
                    </div>
                    {fileUploadLogo}
                  </DropZone>
                  <Modal
                    open={modalForCroppingActiveLogo}
                    onClose={handleModalForCroppingCloseLogo}
                    title={localize("CropImage")}
                    primaryAction={
                      doneButtonDisabledLogo
                        ? {
                            content: <Spinner size="small" />,
                            disabled: true,
                          }
                        : {
                            content: localize("Done"),
                            onAction: takeCroppedImageLogo,
                          }
                    }
                    secondaryActions={[
                      {
                        content: localize("Cancel"),
                        onAction: handleModalForCroppingCloseLogo,
                      },
                    ]}
                  >
                    <Modal.Section>
                      <div className="cropper-container">
                        <Cropper
                          image={imageSrcLogo}
                          crop={cropLogo}
                          zoom={zoomLogo}
                          aspect={1 / 1}
                          onCropChange={setCropLogo}
                          onCropComplete={onCropCompleteLogo}
                          onZoomChange={setZoomLogo}
                        />
                      </div>
                      <div
                        className="slider-container"
                        style={{
                          backgroundColor:
                            theme.colorScheme === "dark" ? "#202123" : "white",
                        }}
                      >
                        <Slider
                          value={zoomLogo}
                          min={1}
                          max={3}
                          step={0.1}
                          aria-labelledby="Zoom"
                          onChange={(e, zoom) => setZoomLogo(zoom)}
                        />
                      </div>
                    </Modal.Section>
                  </Modal>
                </Stack>
              </Card.Section>
            </Card>
          </CSSTransition>
        </Layout.Section>
      </Layout>
      {errorToastMarkup}
      {createToastMarkup}
      {clipboardToastMarkup}
      {lengthToastMarkup}
      {resolutionToastMarkup}
    </Page>
  )
}

export default TeamNew
