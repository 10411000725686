/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAttendees = /* GraphQL */ `
    mutation CreateAttendees(
        $input: CreateAttendeesInput!
        $condition: ModelAttendeesConditionInput
    ) {
        createAttendees(input: $input, condition: $condition) {
            attendeesAt
            blob
            createdAt
            eventId
            id
            sub
            team {
                documents
                id
            }
            updatedAt
            user {
                firstName
                lastName
                sub
                uid
            }
        }
    }
`;
export const createParticipants = /* GraphQL */ `
    mutation CreateParticipants(
        $condition: ModelParticipantsConditionInput
        $input: CreateParticipantsInput!
    ) {
        createParticipants(condition: $condition, input: $input) {
            blob
            checkInAt
            checkInLocation {
                latitude
                longitude
            }
            createdAt
            eventId
            id
            sub
            team {
                documents
                id
            }
            updatedAt
            user {
                firstName
                lastName
                sub
                uid
            }
        }
    }
`;
export const logAction = /* GraphQL */ `
    mutation LogAction($input: LogActionInput) {
        logAction(input: $input) {
            actionId
            amount
            blob
            coefficient
            createdAt
            eventId
            id
            initiativeId
            postId
            sub
            taskId
            teamId
            total
            updatedAt
        }
    }
`;

export const supportInitiative = /* GraphQL */ `
    mutation SupportInitiative($teamId: String!, $initiativesId: String!) {
        supportInitiatives(
            input: { teamId: $teamId, initiativesId: $initiativesId }
        ) {
            id
        }
    }
`;

export const createEvents = /* GraphQL */ `
    mutation CreateEvents($input: CreateEventsCustomInput!) {
        createEvents(input: $input) {
            id
        }
    }
`;

export const createEventTranslations = /* GraphQL */ `
    mutation CreateEventTranslations($input: CreateEventTranslationsInput!) {
        createEventTranslations(input: $input) {
            id
        }
    }
`;
