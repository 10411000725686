import React, { useEffect, useState, useCallback, useContext } from "react"
import {
  Card,
  DataTable,
  DatePicker,
  Heading,
  Icon,
  Modal,
  Page,
  Spinner,
  Stack,
  TextField,
  TextStyle,
  Tooltip as PolarisTooltip,
} from "@shopify/polaris"
import { useParams } from "react-router"

// icons
import { CalendarMinor, InfoMinor } from "@shopify/polaris-icons"
import bookLight from "../../../images/book-light.png"
import bookDark from "../../../images/book-dark.png"

// localizer
import { useLocalizer } from "reactjs-localizer"

// context
import { AuthContext } from "../../../app/AuthContext"

// api
import Api from "../../../api"

// animation
import { CSSTransition } from "react-transition-group"

// csv download
import { CSVLink } from "react-csv"

// charts
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  PointElement,
  LineElement,
} from "chart.js"
import { Doughnut } from "react-chartjs-2"
import { Bar } from "react-chartjs-2"
import { Line } from "react-chartjs-2"

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title
)

const Analytics = () => {
  const { teamId, id } = useParams()
  const { localize } = useLocalizer()
  const [loading, setLoading] = useState(true)
  const { theme } = useContext(AuthContext)

  useEffect(() => {
    if (!loading && !noData) {
      let pie = document.getElementById("pie-container")
      let bar = document.getElementById("bar-container")

      bar.style.height = pie.clientHeight + "px"
    }
    //eslint-disable-next-line
  }, [loading])

  const today = new Date().toLocaleDateString().replaceAll("/", "-")

  // UTILITY
  const dateToString = (date) => {
    let months = [
      localize("JAN"),
      localize("FEB"),
      localize("MAR"),
      localize("APR"),
      localize("MAY"),
      localize("JUN"),
      localize("JUL"),
      localize("AUG"),
      localize("SEP"),
      localize("OCT"),
      localize("NOV"),
      localize("DEC"),
    ]

    let dateToPrint =
      date.getDate() + " " + months[date.getMonth()] + " " + date.getFullYear()

    return dateToPrint
  }

  const removeDays = (date, days) => {
    let dateToRemoveFrom = new Date(date)
    dateToRemoveFrom.setDate(dateToRemoveFrom.getDate() - days)
    return dateToRemoveFrom.toLocaleDateString()
  }

  // DATE PICKER
  const [pickedDate, setPickedDate] = useState(new Date())

  const [{ month, year }, setDate] = useState({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  })

  const [selectedDates, setSelectedDates] = useState({
    start: new Date(),
    end: new Date(),
  })

  const handleMonthChange = useCallback(
    (month, year) => setDate({ month, year }),
    []
  )

  // MODAL TO PICK DATE
  const [active, setActive] = useState(false)

  const handleChange = useCallback(() => setActive(!active), [active])

  const reloadAnalytics = () => {
    setPickedDate(selectedDates.start)
    fetchAll(selectedDates.start)

    handleChange()
  }

  // STATES
  const [classCompletedPercentState, setClassCompletedPercentState] =
    useState(0)
  const [completedModulesState, setCompletedModulesState] = useState(0)
  const [completedJourneysState, setCompletedJourneysState] = useState(0)
  const [completedEpisodesState, setCompletedEpisodesState] = useState(0)
  const [modulesAverageEvaluationState, setModulesAverageEvaluationState] =
    useState(0)
  // const [classAverageEvaluationState, setClassAverageEvaluationState] =
  //   useState(0);
  const [classTotalUsersState, setClassTotalUsersState] = useState(0)
  const [teamMembersState, setTeamMembersState] = useState(0)
  const [journeysAverageEvaluationState, setJourneysAverageEvaluationState] =
    useState(0)

  // NO DATA
  const [noData, setNoData] = useState(false)

  // CLASS DETAILS ROWS
  const [classRows, setClassRows] = useState()

  // MODULES DETAILS ROWS
  const [modulesDetailsRows, setModulesDetailsRows] = useState()

  // DOUGHNUT CHART
  const data = {
    labels: [localize("CompletedUppercase"), localize("InProgress")],
    datasets: [
      {
        data: [classCompletedPercentState, 100 - classCompletedPercentState],
        backgroundColor: ["#1b79ea", "#65aef2"],
        borderWidth: 0,
      },
    ],
  }

  // BAR CHART
  const barOptions = {
    layout: {
      padding: {
        right: 10,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          borderWidth: 0,
        },
        ticks: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
          borderWidth: 0,
        },
        ticks: {
          color: theme.colorScheme === "dark" ? "white" : "black",
        },
      },
    },
    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 0,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: "right",
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return (
              tooltipItem.label +
              ": " +
              barData.datasets[0].data[tooltipItem.dataIndex] +
              "%"
            )
          },
          title: function () {
            return ""
          },
        },
      },
    },
  }

  const [barLabels, setBarLabels] = useState([])
  const [barDataToShow, setBarDataToShow] = useState()
  const [barColors, setBarColors] = useState([])

  const barData = {
    labels: barLabels,
    datasets: [
      {
        data: barDataToShow,
        backgroundColor: barColors,
        barThickness: 16,
      },
    ],
  }

  // LINE CHART FOR COMPLETED MODULES
  const [dataForModulesLineChart, setDataForModulesLineChart] = useState([])

  const modulesLineOptions = {
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 15,
        right: 15,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          borderWidth: 0,
        },
        ticks: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
          borderWidth: 0,
        },
        ticks: {
          display: false,
        },
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          label: function (tooltipItem) {
            if (modulesLineData.datasets[0].data[tooltipItem.dataIndex] === 0) {
              return "0"
            } else {
              return modulesLineData.datasets[0].data[tooltipItem.dataIndex]
            }
          },
        },
      },
    },
  }

  const lineLabels = [
    removeDays(selectedDates.start, 6),
    removeDays(selectedDates.start, 5),
    removeDays(selectedDates.start, 4),
    removeDays(selectedDates.start, 3),
    removeDays(selectedDates.start, 2),
    removeDays(selectedDates.start, 1),
    selectedDates.start.toLocaleDateString(),
  ]

  const modulesLineData = {
    labels: lineLabels,
    datasets: [
      {
        label: "Dataset 1",
        data: dataForModulesLineChart,
        borderColor: "#1a79ea",
        backgroundColor: "#1a79ea",
      },
    ],
  }

  // LINE CHART FOR COMPLETED JOURNEYS
  const [dataForJourneysLineChart, setDataForJourneysLineChart] = useState([])

  const journeysLineOptions = {
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 15,
        right: 15,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          borderWidth: 0,
        },
        ticks: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
          borderWidth: 0,
        },
        ticks: {
          display: false,
        },
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          label: function (tooltipItem) {
            if (
              journeysLineData.datasets[0].data[tooltipItem.dataIndex] === 0
            ) {
              return "0"
            } else {
              return journeysLineData.datasets[0].data[tooltipItem.dataIndex]
            }
          },
        },
      },
    },
  }

  const journeysLineData = {
    labels: lineLabels,
    datasets: [
      {
        label: "Dataset 1",
        data: dataForJourneysLineChart,
        borderColor: "#1a79ea",
        backgroundColor: "#1a79ea",
      },
    ],
  }

  // LINE CHART FOR COMPLETED EPISODES
  const [dataForEpisodesLineChart, setDataForEpisodesLineChart] = useState([])

  const episodesLineOptions = {
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 15,
        right: 15,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          borderWidth: 0,
        },
        ticks: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
          borderWidth: 0,
        },
        ticks: {
          display: false,
        },
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          label: function (tooltipItem) {
            if (
              episodesLineData.datasets[0].data[tooltipItem.dataIndex] === 0
            ) {
              return "0"
            } else {
              return episodesLineData.datasets[0].data[tooltipItem.dataIndex]
            }
          },
        },
      },
    },
  }

  const episodesLineData = {
    labels: lineLabels,
    datasets: [
      {
        label: "Dataset 1",
        data: dataForEpisodesLineChart,
        borderColor: "#1a79ea",
        backgroundColor: "#1a79ea",
      },
    ],
  }

  // FETCH ALL
  const fetchAll = async (dateForAnalytics = null) => {
    setLoading(true)
    setNoData(false)

    let todayDate
    let weekAgo
    if (dateForAnalytics) {
      todayDate = new Date(dateForAnalytics)
      todayDate.setTime(todayDate.getTime() + 1 * 60 * 60 * 1000)
      weekAgo = new Date(todayDate)
      todayDate = todayDate.toISOString()
    } else {
      todayDate = new Date().toISOString()
      weekAgo = new Date()
    }
    todayDate = todayDate.slice(0, todayDate.indexOf("T"))
    weekAgo.setDate(weekAgo.getDate() - 6)
    weekAgo = weekAgo.toISOString()
    weekAgo = weekAgo.slice(0, weekAgo.indexOf("T"))

    // team members number
    const teamMembers = await Api.teams.getTeamMembers(teamId)
    setTeamMembersState(teamMembers.membersCount)

    // class
    const currentClass = await Api.education.getClass(teamId)

    // modules
    let modulesCalls = []
    for (let i = 0; i < currentClass.items.modules.length; i++) {
      modulesCalls.push(
        Api.education.getModule(id, currentClass.items.modules[i].id)
      )
    }
    const modules = await Promise.all(modulesCalls)

    let rowsForTable = []
    let chartLabels = []
    for (let i = 0; i < modules.length; i++) {
      rowsForTable.push([
        <img
          src={theme.colorScheme === "dark" ? bookDark : bookLight}
          style={{ height: 35, margin: 0, marginLeft: 15 }}
          alt=""
        />,
        localize("Module") + " #" + modules[i].module.id,
      ])
      chartLabels.push(localize("Module") + " #" + modules[i].module.id)
    }
    setBarLabels(chartLabels)

    // journeys and episodes
    let journeys = []
    let rowsForModulesTables = {}
    for (let i = 0; i < modules.length; i++) {
      rowsForModulesTables[modules[i].module.id] = []

      for (let j = 0; j < modules[i].module.entities.length; j++) {
        if (modules[i].module.entities[j].entity_type === "journey") {
          journeys.push(modules[i].module.entities[j])
          rowsForModulesTables[modules[i].module.id].push([
            <img
              src={modules[i].module.entities[j].entity.data.image}
              style={{ height: 40, margin: 0, marginLeft: 19 }}
              alt=""
            />,
            modules[i].module.entities[j].entity.data.title.length > 35
              ? modules[i].module.entities[j].entity.data.title.slice(0, 35) +
                "..."
              : modules[i].module.entities[j].entity.data.title,
          ])
        }
      }
    }

    // class status
    const classStatus = await Api.education.getClassAnalytics(
      id,
      todayDate,
      todayDate
    )
    if (!classStatus.in_progress[0] && !classStatus.completed[0]) {
      setLoading(false)
      setNoData(true)
      return
    }

    let averageEvaluationClass = 0
    let averageClassCounter = 0
    if (classStatus.completed[0]) {
      averageEvaluationClass += classStatus.completed[0].average_evaluation
      averageClassCounter++
    }
    if (classStatus.in_progress[0]) {
      averageEvaluationClass += classStatus.in_progress[0].average_evaluation
      averageClassCounter++
    }
    // setClassAverageEvaluationState(
    //   Math.round((averageEvaluationClass / averageClassCounter) * 10) / 10
    // );

    let classTotalUsers = 0
    let classCompleted = 0
    if (classStatus.completed[0]) {
      classTotalUsers += classStatus.completed[0].total_users
      classCompleted = classStatus.completed[0].total_users
    }
    if (classStatus.in_progress[0]) {
      classTotalUsers += classStatus.in_progress[0].total_users
    }
    let classCompletedPercent = Math.round(
      (classCompleted * 100) / classTotalUsers
    )
    setClassCompletedPercentState(classCompletedPercent)
    setClassTotalUsersState(classTotalUsers)

    // completed modules
    let modulesAnalyticsCalls = []
    for (let i = 0; i < currentClass.items.modules.length; i++) {
      modulesAnalyticsCalls.push(
        Api.education.getModuleAnalytics(
          currentClass.items.modules[i].id,
          weekAgo,
          todayDate
        )
      )
    }
    const modulesAnalytics = await Promise.all(modulesAnalyticsCalls)

    // in time
    let modulesTimeData = [0, 0, 0, 0, 0, 0, 0]
    for (let i = 0; i < modulesAnalytics.length; i++) {
      if (modulesAnalytics[i].completed.length > 0) {
        for (let j = modulesAnalytics[i].completed.length - 1; j >= 0; j--) {
          modulesTimeData[j] += modulesAnalytics[i].completed[j].total_users
        }
      }
    }
    setDataForModulesLineChart(modulesTimeData)

    for (let i = 0; i < modulesAnalytics.length; i++) {
      if (modulesAnalytics[i].completed.length > 0) {
        modulesAnalytics[i].completed = [
          modulesAnalytics[i].completed[
            modulesAnalytics[i].completed.length - 1
          ],
        ]
      }
      if (modulesAnalytics[i].in_progress.length > 0) {
        modulesAnalytics[i].in_progress = [
          modulesAnalytics[i].in_progress[
            modulesAnalytics[i].in_progress.length - 1
          ],
        ]
      }
    }

    // static
    let completedModules = 0
    let modulesAverageEvaluation = 0
    let modulesAverageCount = 0
    for (let i = 0; i < modulesAnalytics.length; i++) {
      let averageForRow = 0
      let averageCountForRow = 0
      let totalUsersForRow = 0
      let completedUsersForRow = 0
      let completionForRow = 0

      if (modulesAnalytics[i].completed[0]) {
        completedModules += modulesAnalytics[i].completed[0].total_users
        modulesAverageEvaluation +=
          modulesAnalytics[i].completed[0].average_evaluation
        modulesAverageCount++

        totalUsersForRow += modulesAnalytics[i].completed[0].total_users
        completedUsersForRow = modulesAnalytics[i].completed[0].total_users
        averageForRow += modulesAnalytics[i].completed[0].average_evaluation
        averageCountForRow++
      }
      if (modulesAnalytics[i].in_progress[0]) {
        modulesAverageEvaluation +=
          modulesAnalytics[i].in_progress[0].average_evaluation
        modulesAverageCount++

        totalUsersForRow += modulesAnalytics[i].in_progress[0].total_users
        averageForRow += modulesAnalytics[i].in_progress[0].average_evaluation
        averageCountForRow++
      }

      averageForRow = Math.round((averageForRow / averageCountForRow) * 10) / 10
      completionForRow = Math.round(
        (completedUsersForRow * 100) / totalUsersForRow
      )
      rowsForTable[i].push(
        averageForRow ? averageForRow : localize("NA"),
        totalUsersForRow,
        completionForRow ? completionForRow + "%" : "0%"
      )
    }

    setClassRows(rowsForTable)

    setCompletedModulesState(completedModules)
    setModulesAverageEvaluationState(
      Math.round((modulesAverageEvaluation / modulesAverageCount) * 10) / 10
    )

    // bar chart
    let inProgressPeople = 0
    for (let i = 0; i < modulesAnalytics.length; i++) {
      if (modulesAnalytics[i].in_progress[0]) {
        inProgressPeople += modulesAnalytics[i].in_progress[0].total_users
      }
    }
    let dataForBars = []
    let colorsForBars = []
    for (let i = 0; i < modulesAnalytics.length; i++) {
      if (modulesAnalytics[i].in_progress[0]) {
        let percent = Math.round(
          (modulesAnalytics[i].in_progress[0].total_users * 100) /
            inProgressPeople
        )
        dataForBars.push(percent)
        if (percent <= 10) {
          colorsForBars.push("#a7caf8")
        } else if (percent <= 35) {
          colorsForBars.push("#579bef")
        } else {
          colorsForBars.push("#1b79ea")
        }
      } else {
        dataForBars.push(0)
        colorsForBars.push("#a7caf8")
      }
    }
    setBarDataToShow(dataForBars)
    setBarColors(colorsForBars)

    // completed journeys and episodes
    let journeysAnalyticsCalls = []
    for (let i = 0; i < journeys.length; i++) {
      journeysAnalyticsCalls.push(
        Api.education.getJourneyAnalytics(journeys[i].id, weekAgo, todayDate)
      )
    }
    const journeysAnalytics = await Promise.all(journeysAnalyticsCalls)

    // in time
    let journeysTimeData = [0, 0, 0, 0, 0, 0, 0]
    let episodesTimeData = [0, 0, 0, 0, 0, 0, 0]
    for (let i = 0; i < journeysAnalytics.length; i++) {
      if (journeysAnalytics[i].completed.length > 0) {
        let episodesNumber = journeys[i].entity.nested.length

        for (let j = journeysAnalytics[i].completed.length - 1; j >= 0; j--) {
          journeysTimeData[j] += journeysAnalytics[i].completed[j].total_users
          episodesTimeData[j] +=
            journeysAnalytics[i].completed[j].total_users * episodesNumber
        }
      }
    }
    setDataForJourneysLineChart(journeysTimeData)
    setDataForEpisodesLineChart(episodesTimeData)

    for (let i = 0; i < journeysAnalytics.length; i++) {
      if (journeysAnalytics[i].completed.length > 0) {
        journeysAnalytics[i].completed = [
          journeysAnalytics[i].completed[
            journeysAnalytics[i].completed.length - 1
          ],
        ]
      }
      if (journeysAnalytics[i].in_progress.length > 0) {
        journeysAnalytics[i].in_progress = [
          journeysAnalytics[i].in_progress[
            journeysAnalytics[i].in_progress.length - 1
          ],
        ]
      }
    }

    // static
    let completedJourneys = 0
    let completedEpisodes = 0
    for (let i = 0; i < journeysAnalytics.length; i++) {
      if (journeysAnalytics[i].completed[0]) {
        let totalUsers = journeysAnalytics[i].completed[0].total_users
        completedJourneys += totalUsers
        let episodesNumber = journeys[i].entity.nested.length
        completedEpisodes += totalUsers * episodesNumber
      }
    }
    setCompletedJourneysState(completedJourneys)
    setCompletedEpisodesState(completedEpisodes)

    let averageEvaluationOfAllJourneys = 0
    let averageEvaluationOfAllJourneysCounter = 0
    let globalCounter = 0
    for (let key in rowsForModulesTables) {
      for (let i = 0; i < rowsForModulesTables[key].length; i++) {
        let totalUsersJourney = 0
        let completedUsersJourney = 0
        let averageEvaluationJourney
        if (journeysAnalytics[globalCounter].completed[0]) {
          totalUsersJourney +=
            journeysAnalytics[globalCounter].completed[0].total_users
          completedUsersJourney =
            journeysAnalytics[globalCounter].completed[0].total_users
          averageEvaluationJourney =
            journeysAnalytics[globalCounter].completed[0].average_evaluation
          averageEvaluationOfAllJourneys +=
            journeysAnalytics[globalCounter].completed[0].average_evaluation
          averageEvaluationOfAllJourneysCounter++
        }
        if (journeysAnalytics[globalCounter].in_progress[0]) {
          totalUsersJourney +=
            journeysAnalytics[globalCounter].in_progress[0].total_users
        }

        let completion = Math.round(
          (completedUsersJourney * 100) / totalUsersJourney
        )

        rowsForModulesTables[key][i].push(
          averageEvaluationJourney
            ? Math.round(averageEvaluationJourney * 10) / 10
            : localize("NA"),
          totalUsersJourney,
          completion ? completion + "%" : "0%"
        )

        globalCounter++
      }
    }
    setModulesDetailsRows(rowsForModulesTables)
    setJourneysAverageEvaluationState(
      Math.round(
        (averageEvaluationOfAllJourneys /
          averageEvaluationOfAllJourneysCounter) *
          10
      ) / 10
    )

    // loading
    setLoading(false)
  }

  useEffect(() => {
    fetchAll()
    //eslint-disable-next-line
  }, [])

  // DOWNLOAD AS CSV
  const [csvData, setCsvData] = useState([])

  useEffect(() => {
    if (barDataToShow && classRows && modulesDetailsRows) {
      let modulesProgress = barDataToShow.map((item, index) => [
        localize("Distribution").replace("{module}", barData.labels[index]),
        item + "%",
      ])

      let academyDetails = classRows.map((item) => [
        item[1],
        item[2].toString().replace(".", ","),
        item[3].toString(),
        item[4],
      ])

      let modulesDetails = []
      for (let key in modulesDetailsRows) {
        modulesDetails.push([""])
        modulesDetails.push([localize("ModuleDetails").replace("{id}", key)])
        modulesDetails.push([
          localize("Journey"),
          localize("AverageEvaluation"),
          localize("TotalUsers"),
          localize("Completion"),
        ])
        let modulesToPush = modulesDetailsRows[key].map((item) => [
          item[1],
          item[2].toString().replace(".", ","),
          item[3].toString(),
          item[4],
        ])
        modulesDetails.push(...modulesToPush)
      }

      setCsvData([
        [localize("Overview")],
        [
          `${localize("Modules")} ${localize("Completed")}`,
          completedModulesState,
        ],
        [
          `${localize("Journeys")} ${localize("CompletedFemale")}`,
          completedJourneysState,
        ],
        [
          `${localize("Episodes")} ${localize("Completed")}`,
          completedEpisodesState,
        ],
        [`${localize("AcademyCompletion")}`, classCompletedPercentState + "%"],
        ...modulesProgress,
        [
          `${localize("AverageModuleEvaluation")}`,
          modulesAverageEvaluationState.toString().replace(".", ","),
        ],
        [
          `${localize("AverageJourneyEvaluation")}`,
          journeysAverageEvaluationState !== 0
            ? journeysAverageEvaluationState.toString().replace(".", ",")
            : localize("NA"),
        ],
        [""],
        [`${localize("AcademyDetails")}`],
        [
          localize("Module"),
          localize("AverageEvaluation"),
          localize("TotalUsers"),
          localize("Completion"),
        ],
        ...academyDetails,
        ...modulesDetails,
      ])
    }
    //eslint-disable-next-line
  }, [
    classCompletedPercentState,
    completedModulesState,
    completedJourneysState,
    completedEpisodesState,
    modulesAverageEvaluationState,
    journeysAverageEvaluationState,
    barDataToShow,
    classRows,
    modulesDetailsRows,
  ])

  return (
    <Page
      title={`${localize("AcademySingular")} #${id} | ${localize("Analytics")}`}
      primaryAction={{
        content: loading ? (
          localize("DownloadAsCSV")
        ) : (
          <CSVLink
            data={csvData}
            filename={`${localize("AcademySingular")} #${id} ${today}.csv`}
            target="_blank"
            style={{
              textDecoration: "none",
              color: "white",
              paddingTop: 10,
              paddingBottom: 10,
            }}
          >
            {localize("DownloadAsCSV")}
          </CSVLink>
        ),
        disabled: loading,
      }}
      breadcrumbs={[
        {
          content: "Team",
          url: window.location.pathname.slice(
            0,
            window.location.pathname.indexOf("academy") - 1
          ),
        },
      ]}
    >
      {loading ? (
        <div
          style={{
            width: "100%",
            height: "calc(100vh - 180px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CSSTransition
            in={true}
            appear={true}
            timeout={1000}
            classNames="transform"
          >
            <Stack>
              <p>{localize("GeneratingAnalytics")}</p>
              <Spinner size="small" />
            </Stack>
          </CSSTransition>
        </div>
      ) : noData ? (
        <>
          <CSSTransition
            in={true}
            appear={true}
            timeout={1000}
            classNames="transform"
          >
            <Card sectioned>
              <TextField
                prefix={<Icon source={CalendarMinor} color="base" />}
                suffix={
                  pickedDate.toLocaleDateString() ===
                  new Date().toLocaleDateString()
                    ? `${localize("LastUpdate")}: ${localize("TodayAt")} 04:00`
                    : null
                }
                value={
                  dateToString(pickedDate) === dateToString(new Date())
                    ? localize("Today")
                    : dateToString(pickedDate)
                }
                readOnly
                onFocus={handleChange}
              />
            </Card>
          </CSSTransition>
          <div
            style={{
              width: "100%",
              height: "calc(100vh - 256px)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CSSTransition
              in={true}
              appear={true}
              timeout={1000}
              classNames="transform"
            >
              <p>{localize("NoDataForTheSelectedDay")}</p>
            </CSSTransition>
          </div>
        </>
      ) : (
        <Stack vertical>
          <CSSTransition
            in={true}
            appear={true}
            timeout={1000}
            classNames="transform"
          >
            <Card sectioned>
              <TextField
                prefix={<Icon source={CalendarMinor} color="base" />}
                suffix={
                  pickedDate.toLocaleDateString() ===
                  new Date().toLocaleDateString()
                    ? `${localize("LastUpdate")}: ${localize("TodayAt")} 04:00`
                    : null
                }
                value={
                  dateToString(pickedDate) === dateToString(new Date())
                    ? localize("Today")
                    : dateToString(pickedDate)
                }
                readOnly
                onFocus={handleChange}
              />
            </Card>
          </CSSTransition>
          <Stack distribution="fillEvenly">
            <CSSTransition
              in={true}
              appear={true}
              timeout={1000}
              classNames="transform"
            >
              <Card
                sectioned
                title={
                  <Stack alignment="center" spacing="tight">
                    <Heading>{localize("TeamMembers")}</Heading>
                    <div
                      style={{
                        marginTop: 2,
                        cursor: "pointer",
                        width: 16,
                        height: 16,
                      }}
                    >
                      <PolarisTooltip
                        content={localize("TotalNumberOfTeamMembers")}
                      >
                        <Icon source={InfoMinor} color="base" />
                      </PolarisTooltip>
                    </div>
                  </Stack>
                }
              >
                <p style={{ fontSize: 20 }}>
                  <TextStyle variation="strong">{teamMembersState}</TextStyle>
                </p>
              </Card>
            </CSSTransition>
            <CSSTransition
              in={true}
              appear={true}
              timeout={1000}
              classNames="transform"
            >
              <Card
                sectioned
                title={
                  <Stack alignment="center" spacing="tight">
                    <Heading>{localize("ParticipantsInThisAcademy")}</Heading>
                    <div
                      style={{
                        marginTop: 2,
                        cursor: "pointer",
                        width: 16,
                        height: 16,
                      }}
                    >
                      <PolarisTooltip
                        content={localize("NumberOfTeamMembersWho")}
                      >
                        <Icon source={InfoMinor} color="base" />
                      </PolarisTooltip>
                    </div>
                  </Stack>
                }
              >
                <p style={{ fontSize: 20 }}>
                  <TextStyle variation="strong">
                    {classTotalUsersState}
                  </TextStyle>
                </p>
              </Card>
            </CSSTransition>
          </Stack>
          <Stack distribution="fillEvenly">
            <CSSTransition
              in={true}
              appear={true}
              timeout={1000}
              classNames="transform"
            >
              <Card
                sectioned
                title={
                  <Stack alignment="center" spacing="tight">
                    <Heading>{`${localize("Modules")} ${localize(
                      "Completed"
                    )}`}</Heading>
                    <div
                      style={{
                        marginTop: 2,
                        cursor: "pointer",
                        width: 16,
                        height: 16,
                      }}
                    >
                      <PolarisTooltip
                        content={localize("NumberOfTimesThatModules")}
                      >
                        <Icon source={InfoMinor} color="base" />
                      </PolarisTooltip>
                    </div>
                  </Stack>
                }
              >
                <Stack alignment="center">
                  <p style={{ fontSize: 20 }}>
                    <TextStyle variation="strong">
                      {completedModulesState}
                    </TextStyle>
                  </p>
                  <Line
                    options={modulesLineOptions}
                    data={modulesLineData}
                    width={window.innerWidth < 415 ? "300px" : "200px"}
                    height={"70px"}
                  />
                </Stack>
              </Card>
            </CSSTransition>
            <CSSTransition
              in={true}
              appear={true}
              timeout={1000}
              classNames="transform"
            >
              <Card
                sectioned
                title={
                  <Stack alignment="center" spacing="tight">
                    <Heading>{`${localize("Journeys")} ${localize(
                      "CompletedFemale"
                    )}`}</Heading>
                    <div
                      style={{
                        marginTop: 2,
                        cursor: "pointer",
                        width: 16,
                        height: 16,
                      }}
                    >
                      <PolarisTooltip
                        content={localize("NumberOfTimesThatJourneys")}
                      >
                        <Icon source={InfoMinor} color="base" />
                      </PolarisTooltip>
                    </div>
                  </Stack>
                }
              >
                <Stack alignment="center">
                  <p style={{ fontSize: 20 }}>
                    <TextStyle variation="strong">
                      {completedJourneysState}
                    </TextStyle>
                  </p>
                  <Line
                    options={journeysLineOptions}
                    data={journeysLineData}
                    width={window.innerWidth < 415 ? "300px" : "200px"}
                    height={"70px"}
                  />
                </Stack>
              </Card>
            </CSSTransition>
            <CSSTransition
              in={true}
              appear={true}
              timeout={1000}
              classNames="transform"
            >
              <Card
                sectioned
                title={
                  <Stack alignment="center" spacing="tight">
                    <Heading>{`${localize("Episodes")} ${localize(
                      "Completed"
                    )}`}</Heading>
                    <div
                      style={{
                        marginTop: 2,
                        cursor: "pointer",
                        width: 16,
                        height: 16,
                      }}
                    >
                      <PolarisTooltip
                        content={localize("NumberOfTimesThatEpisodes")}
                      >
                        <Icon source={InfoMinor} color="base" />
                      </PolarisTooltip>
                    </div>
                  </Stack>
                }
              >
                <Stack alignment="center">
                  <p style={{ fontSize: 20 }}>
                    <TextStyle variation="strong">
                      {completedEpisodesState}
                    </TextStyle>
                  </p>
                  <Line
                    options={episodesLineOptions}
                    data={episodesLineData}
                    width={window.innerWidth < 415 ? "300px" : "200px"}
                    height={"70px"}
                  />
                </Stack>
              </Card>
            </CSSTransition>
          </Stack>
          <Stack distribution="fillEvenly">
            <CSSTransition
              in={true}
              appear={true}
              timeout={1000}
              classNames="transform"
            >
              <Card
                sectioned
                title={
                  <Stack alignment="center" spacing="tight">
                    <Heading>{localize("AcademyStatus")}</Heading>
                    <div
                      style={{
                        marginTop: 2,
                        cursor: "pointer",
                        width: 16,
                        height: 16,
                      }}
                    >
                      <PolarisTooltip content={localize("PercentageOfUsers")}>
                        <Icon source={InfoMinor} color="base" />
                      </PolarisTooltip>
                    </div>
                  </Stack>
                }
              >
                <div id="pie-container">
                  <Doughnut
                    data={data}
                    height={"290px"}
                    options={{
                      maintainAspectRatio: false,
                      layout: {
                        padding: 10,
                      },
                      color: theme.colorScheme === "dark" ? "white" : "black",
                      cutout: "70%",
                      plugins: {
                        legend: {
                          position: "bottom",
                          labels: {
                            padding: 40,
                          },
                        },
                        tooltip: {
                          callbacks: {
                            label: function (tooltipItem) {
                              return (
                                tooltipItem.label +
                                ": " +
                                data.datasets[0].data[tooltipItem.dataIndex] +
                                "%"
                              )
                            },
                          },
                        },
                      },
                    }}
                  />
                </div>
              </Card>
            </CSSTransition>
            <Stack.Item fill>
              <CSSTransition
                in={true}
                appear={true}
                timeout={1000}
                classNames="transform"
              >
                <Card
                  sectioned
                  title={
                    <Stack alignment="center" spacing="tight">
                      <Heading>{localize("DistributionInModules")}</Heading>
                      <div
                        style={{
                          marginTop: 2,
                          cursor: "pointer",
                          width: 16,
                          height: 16,
                        }}
                      >
                        <PolarisTooltip
                          content={localize("DistributionOfUsersInModules")}
                        >
                          <Icon source={InfoMinor} color="base" />
                        </PolarisTooltip>
                      </div>
                    </Stack>
                  }
                >
                  <div id="bar-container">
                    <Bar options={barOptions} data={barData} />
                  </div>
                </Card>
              </CSSTransition>
            </Stack.Item>
          </Stack>
          <Stack distribution="fillEvenly">
            <CSSTransition
              in={true}
              appear={true}
              timeout={1000}
              classNames="transform"
            >
              <Card
                sectioned
                title={
                  <Stack alignment="center" spacing="tight">
                    <Heading>{localize("AverageModuleEvaluation")}</Heading>
                    <div
                      style={{
                        marginTop: 2,
                        cursor: "pointer",
                        width: 16,
                        height: 16,
                      }}
                    >
                      <PolarisTooltip
                        content={localize("AverageEvaluationOfAllModules")}
                      >
                        <Icon source={InfoMinor} color="base" />
                      </PolarisTooltip>
                    </div>
                  </Stack>
                }
              >
                <p style={{ fontSize: 20 }}>
                  <TextStyle variation="strong">
                    {modulesAverageEvaluationState}
                  </TextStyle>
                </p>
              </Card>
            </CSSTransition>
            <CSSTransition
              in={true}
              appear={true}
              timeout={1000}
              classNames="transform"
            >
              <Card
                sectioned
                title={
                  <Stack alignment="center" spacing="tight">
                    <Heading>{localize("AverageJourneyEvaluation")}</Heading>
                    <div
                      style={{
                        marginTop: 2,
                        cursor: "pointer",
                        width: 16,
                        height: 16,
                      }}
                    >
                      <PolarisTooltip
                        content={localize("AverageEvaluationOfAllJourneys")}
                      >
                        <Icon source={InfoMinor} color="base" />
                      </PolarisTooltip>
                    </div>
                  </Stack>
                }
              >
                <p style={{ fontSize: 20 }}>
                  <TextStyle variation="strong">
                    {journeysAverageEvaluationState}
                  </TextStyle>
                </p>
              </Card>
            </CSSTransition>
          </Stack>
          <CSSTransition
            in={true}
            appear={true}
            timeout={1000}
            classNames="transform"
          >
            <Card title={localize("AcademyDetails")} sectioned>
              <DataTable
                columnContentTypes={[
                  "text",
                  "text",
                  "numeric",
                  "numeric",
                  "numeric",
                ]}
                headings={[
                  localize("Image"),
                  localize("Module"),
                  localize("AverageEvaluation"),
                  localize("TotalUsers"),
                  localize("Completion"),
                ]}
                rows={classRows}
                verticalAlign="middle"
              />
            </Card>
          </CSSTransition>
          {Object.keys(modulesDetailsRows).map((key) => {
            return (
              <Card
                sectioned
                key={key}
                title={localize("ModuleDetails").replace("{id}", key)}
              >
                <DataTable
                  columnContentTypes={[
                    "text",
                    "text",
                    "numeric",
                    "numeric",
                    "numeric",
                  ]}
                  headings={[
                    localize("Image"),
                    localize("Journey"),
                    localize("AverageEvaluation"),
                    localize("TotalUsers"),
                    localize("Completion"),
                  ]}
                  rows={modulesDetailsRows[key]}
                  verticalAlign="middle"
                />
              </Card>
            )
          })}
        </Stack>
      )}
      <Modal
        open={active}
        onClose={handleChange}
        title={localize("SelectDate")}
        primaryAction={{
          content: localize("Select"),
          onAction: reloadAnalytics,
        }}
        secondaryActions={[
          {
            content: localize("Cancel"),
            onAction: handleChange,
          },
        ]}
      >
        <Modal.Section>
          <DatePicker
            month={month}
            year={year}
            onChange={setSelectedDates}
            onMonthChange={handleMonthChange}
            selected={selectedDates}
            disableDatesAfter={new Date()}
          />
        </Modal.Section>
      </Modal>
    </Page>
  )
}

export default Analytics
