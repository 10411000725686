import React, { useEffect, useState, useCallback } from "react"
import { useParams } from "react-router-dom"
import {
  Card,
  DatePicker,
  Loading,
  Modal,
  Page,
  SkeletonBodyText,
  SkeletonPage,
  TextField,
  Icon,
  Stack,
  TextContainer,
  Spinner,
  ResourceList,
  ResourceItem,
  TextStyle,
  Caption,
  Button,
} from "@shopify/polaris"

// csv export
import { CSVLink } from "react-csv"

// animation
import { CSSTransition } from "react-transition-group"

// api
import Api from "../../../api"

// localizer
import { useLocalizer } from "reactjs-localizer"

// icons
import { CalendarMinor } from "@shopify/polaris-icons"

// utilities
import { parseDate } from "./utils"

const GroupLeaderboard = () => {
  const { challengeId, id, name } = useParams()
  const { localize } = useLocalizer()

  // UTILITIES
  const dateToString = (date) => {
    let months = [
      localize("JAN"),
      localize("FEB"),
      localize("MAR"),
      localize("APR"),
      localize("MAY"),
      localize("JUN"),
      localize("JUL"),
      localize("AUG"),
      localize("SEP"),
      localize("OCT"),
      localize("NOV"),
      localize("DEC"),
    ]

    let dateToPrint =
      date.getDate() + " " + months[date.getMonth()] + " " + date.getFullYear()

    return dateToPrint
  }

  // LOADING
  const [loading, setLoading] = useState(true)

  // FETCH CHALLENGE
  const [challengeLoading, setChallengeLoading] = useState(true)
  const [challengeStart, setChallengeStart] = useState()
  const [challengeEnd, setChallengeEnd] = useState()

  useEffect(() => {
    const fetchChallenge = async () => {
      const { item } = await Api.challenges.getChallenge(challengeId)
      setChallengeStart(new Date(item.starts_at))

      if (new Date(item.ends_at) < new Date()) {
        setSelectedDates({
          start: new Date(item.ends_at),
          end: new Date(item.ends_at),
        })
        setPickedDate(new Date(item.ends_at))
        setChallengeEnd(new Date(item.ends_at))
      }

      setChallengeLoading(false)
    }

    fetchChallenge()
  }, [])

  // FETCH LEADERBOARD
  const [leaderboardListLoading, setLeaderboardListLoading] = useState(false)
  const [leaderboard, setLeaderboard] = useState()
  const [nextToken, setNextToken] = useState()
  const [leaderboardLength, setLeaderboardLength] = useState(0)
  const [leaderboardLengthLoading, setLeaderboardLengthLoading] = useState(true)

  const getLeaderboardLength = async (nextToken) => {
    const data = await Api.challenges.getGroupLeaderboard(
      challengeId,
      id,
      parseDate(selectedDates.start),
      nextToken,
      10000
    )
    setLeaderboardLength(leaderboardLength + data.items.length)
    if (data.next) {
      getLeaderboardLength(data.next)
    } else {
      setLeaderboardLengthLoading(false)
    }
  }

  const fetchLeaderboard = async () => {
    const data = await Api.challenges.getGroupLeaderboard(
      challengeId,
      id,
      parseDate(selectedDates.start)
    )
    setLeaderboard(data.items)
    setNextToken(data.next)

    setLoading(false)
    setLeaderboardListLoading(false)
  }

  const fetchLeaderboardNext = async () => {
    setLeaderboardListLoading(true)

    const data = await Api.challenges.getGroupLeaderboard(
      challengeId,
      id,
      parseDate(selectedDates.start),
      nextToken
    )
    setLeaderboard([...leaderboard, ...data.items])
    setNextToken(data.next)

    setLeaderboardListLoading(false)
  }

  useEffect(() => {
    if (!challengeLoading) {
      getLeaderboardLength()
      fetchLeaderboard()
    }
    //eslint-disable-next-line
  }, [challengeLoading])

  // DATE PICKER
  const [pickedDate, setPickedDate] = useState(new Date())

  const [{ month, year }, setDate] = useState({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  })

  const [selectedDates, setSelectedDates] = useState({
    start: new Date(),
    end: new Date(),
  })

  const handleMonthChange = useCallback(
    (month, year) => setDate({ month, year }),
    []
  )

  // MODAL TO PICK DATE
  const [active, setActive] = useState(false)

  const handleChange = useCallback(() => setActive(!active), [active])

  const reloadLeaderboard = async () => {
    setPickedDate(selectedDates.start)
    setLeaderboardListLoading(true)
    handleChange()

    fetchLeaderboard()
  }

  // DOWNLOAD AS CSV
  const [csvButtonLoading, setCsvButtonLoading] = useState(true)
  const [csvData, setCsvData] = useState([])

  useEffect(() => {
    const fetchDataForCsv = async () => {
      setCsvButtonLoading(true)

      const { items } = await Api.challenges.getGroupLeaderboard(
        challengeId,
        id,
        parseDate(pickedDate),
        null,
        500
      )

      let dataToSet = [
        [
          localize("Position"),
          localize("FirstNameOnly"),
          localize("LastNameOnly"),
          localStorage.getItem("currentChallengeMetric"),
        ],
      ]
      for (let i = 0; i < items.length; i++) {
        dataToSet.push([
          i + 1,
          items[i].first_name,
          items[i].last_name,
          Math.round(items[i].total_amount * 100) / 100,
        ])
      }
      setCsvData(dataToSet)

      setCsvButtonLoading(false)
    }

    fetchDataForCsv()
  }, [pickedDate])

  return loading ? (
    <>
      <Loading />
      <SkeletonPage>
        <CSSTransition
          in={true}
          appear={true}
          timeout={1000}
          classNames="transform"
        >
          <Card sectioned>
            <div style={{ paddingBottom: 8 }}>
              <SkeletonBodyText lines={2} />
            </div>
          </Card>
        </CSSTransition>
        <CSSTransition
          in={true}
          appear={true}
          timeout={1000}
          classNames="transform"
        >
          <Card sectioned>
            <div style={{ paddingBottom: 2 }}>
              <SkeletonBodyText lines={10} />
            </div>
          </Card>
        </CSSTransition>
      </SkeletonPage>
    </>
  ) : (
    <Page
      title={name + " | " + localize("LeaderboardOnly")}
      breadcrumbs={[
        {
          content: "Challenge",
          url: window.location.pathname.slice(
            0,
            window.location.pathname.indexOf("group") - 1
          ),
        },
      ]}
      primaryAction={{
        content: (
          <CSVLink
            data={csvData}
            filename={`${localize(
              "LeaderboardOnly"
            )} - ${name} - ${dateToString(pickedDate)}.csv`}
            target="_blank"
            style={{
              textDecoration: "none",
              color: "white",
              paddingTop: 10,
              paddingBottom: 10,
            }}
          >
            {localize("DownloadAsCSV")}
          </CSVLink>
        ),
        loading: csvButtonLoading,
        disabled: leaderboard.length === 0,
      }}
    >
      <Stack vertical>
        <Card sectioned>
          <TextField
            prefix={<Icon source={CalendarMinor} color="base" />}
            suffix={
              pickedDate.toLocaleDateString() ===
              new Date().toLocaleDateString()
                ? `${localize("LastUpdate")}: ${localize("TodayAt")} 04:00`
                : null
            }
            value={
              dateToString(pickedDate) === dateToString(new Date())
                ? localize("Today")
                : dateToString(pickedDate)
            }
            readOnly
            onFocus={handleChange}
          />
        </Card>
        <Card sectioned>
          {leaderboard.length === 0 ? (
            <Stack vertical>
              <TextContainer>
                {localize("NoDataForTheSelectedDay")}
              </TextContainer>
              <div
                style={{
                  height: 20,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {leaderboardListLoading && <Spinner />}
              </div>
            </Stack>
          ) : (
            <Stack vertical>
              {leaderboardLengthLoading ? (
                <div style={{ height: 20 }}>
                  <Spinner size="small" />
                </div>
              ) : (
                <TextContainer>
                  {leaderboardLength + " " + localize("Members")}
                </TextContainer>
              )}
              <Card>
                <ResourceList
                  resourceName={{ singular: "user", plural: "users" }}
                  items={leaderboard}
                  loading={leaderboardListLoading}
                  renderItem={(item, id, index) => {
                    const {
                      uid,
                      first_name,
                      last_name,
                      user_sub,
                      total_amount,
                    } = item

                    return (
                      <ResourceItem id={uid}>
                        <Stack alignment="center" spacing="loose">
                          <div
                            style={{
                              marginRight: index + 1 < 10 ? 7 : 0,
                              fontSize: 15,
                            }}
                          >
                            <TextStyle variation="strong">
                              {index + 1}
                            </TextStyle>
                          </div>
                          <div
                            style={{
                              width: 30,
                              height: 30,
                              borderRadius: 15,
                              backgroundImage: `url(http://cdn.aworld.io/users/${user_sub}/profile.jpg), url(https://cdn.aworld.io/users/default/profile.jpg)`,
                              backgroundSize: "cover",
                            }}
                          />
                          <Stack vertical spacing="none">
                            <TextStyle variation="strong">
                              {first_name + " " + last_name}
                            </TextStyle>
                            <Caption>
                              <TextStyle variation="subdued">{uid}</TextStyle>
                            </Caption>
                          </Stack>
                          <Stack.Item fill>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                fontSize: 15,
                                fontWeight: 300,
                              }}
                            >
                              <div style={{ marginRight: 6 }}>
                                {Math.round(total_amount * 100) / 100}
                              </div>
                              <div
                                style={{
                                  marginRight: 14,
                                  fontSize: 10,
                                  marginTop: 2,
                                }}
                              >
                                <TextStyle variation="subdued">
                                  {localStorage.getItem(
                                    "currentChallengeMetric"
                                  )}
                                </TextStyle>
                              </div>
                            </div>
                          </Stack.Item>
                        </Stack>
                      </ResourceItem>
                    )
                  }}
                />
              </Card>
              <Button
                disabled={!nextToken}
                loading={leaderboardListLoading}
                fullWidth
                onClick={nextToken ? fetchLeaderboardNext : null}
              >
                {localize("LoadMore")}
              </Button>
            </Stack>
          )}
        </Card>
      </Stack>
      <Modal
        open={active}
        onClose={handleChange}
        title={localize("SelectDate")}
        primaryAction={{
          content: localize("Select"),
          onAction: reloadLeaderboard,
        }}
        secondaryActions={[
          {
            content: localize("Cancel"),
            onAction: handleChange,
          },
        ]}
      >
        <Modal.Section>
          <DatePicker
            month={month}
            year={year}
            onChange={setSelectedDates}
            onMonthChange={handleMonthChange}
            selected={selectedDates}
            disableDatesAfter={challengeEnd ? challengeEnd : new Date()}
            disableDatesBefore={challengeStart}
          />
        </Modal.Section>
      </Modal>
    </Page>
  )
}

export default GroupLeaderboard
