import Service from "../service";
const Api = new Service();

const user = {
  async getCurrentUser() {
    return Api.get("userEndpoint", "");
  },

  async updateCurrentUser(body) {
    return Api.putAbstract(process.env.REACT_APP_API_BASEURL + "/user", body);
  },
};

export default user;
