// there isn't english because it's the default language and it must always be present
const allLanguages = [
    {
        id: "it",
        content: "Italian",
    },
    {
        id: "es",
        content: "Spanish",
    },
    {
        id: "fr",
        content: "French",
    },
];

export default allLanguages;
