import React, { useEffect, useState } from "react"
import {
  Page,
  Card,
  Loading,
  ResourceList,
  ResourceItem,
  SkeletonPage,
  SkeletonBodyText,
  Stack,
  TextContainer,
} from "@shopify/polaris"
import { useParams } from "react-router"

// animation
import { CSSTransition } from "react-transition-group"

// api
import Api from "../../../api"

// localizer
import { useLocalizer } from "reactjs-localizer"

// style
import "./style.css"

const AcademyModule = () => {
  const { classId, moduleId, id } = useParams()
  const { localize } = useLocalizer()

  // FETCH ENTITY
  const [currentEntity, setCurrentEntity] = useState()
  const [loading, setLoading] = useState(true)

  const fetchEntity = async () => {
    const data = await Api.education.getEntity(classId, moduleId, id)
    console.log(data.entity)

    setCurrentEntity(data.entity)
    setLoading(false)
  }

  useEffect(() => {
    fetchEntity()
    //eslint-disable-next-line
  }, [])

  return loading ? (
    <>
      <Loading />
      <SkeletonPage>
        <CSSTransition
          in={true}
          appear={true}
          timeout={1000}
          classNames="transform"
        >
          <Card sectioned title={localize("Description")}>
            <div style={{ paddingBottom: 2 }}>
              <SkeletonBodyText lines={4} />
            </div>
          </Card>
        </CSSTransition>
        <CSSTransition
          in={true}
          appear={true}
          timeout={1000}
          classNames="transform"
        >
          <Card sectioned title={localize("Episodes")}>
            <div style={{ paddingBottom: 2 }}>
              <SkeletonBodyText lines={6} />
            </div>
          </Card>
        </CSSTransition>
      </SkeletonPage>
    </>
  ) : (
    <Page
      title={currentEntity.data.title}
      breadcrumbs={[
        {
          content: "Team",
          url: window.location.pathname.slice(
            0,
            window.location.pathname.indexOf("module") - 1
          ),
        },
      ]}
    >
      {currentEntity.nested ? (
        <>
          <Card sectioned title={localize("Description")}>
            <div
              dangerouslySetInnerHTML={{
                __html: currentEntity.data.body[0].content,
              }}
            />
          </Card>
          <Card sectioned title={localize("Episodes")}>
            <div className="episodes-container">
              <Card>
                <ResourceList
                  resourceName={{ singular: "customer", plural: "customers" }}
                  items={currentEntity.nested}
                  renderItem={(item) => {
                    const { id, data } = item

                    return (
                      <ResourceItem id={id}>
                        <Stack spacing="loose" alignment="center" wrap={false}>
                          <img
                            src={data.image}
                            style={{
                              height: "100%",
                              maxHeight: 50,
                              margin: 0,
                            }}
                            alt=""
                          />
                          <Stack vertical spacing="extraTight">
                            <p
                              style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                fontWeight: 600,
                                width: "55vw",
                                maxWidth: 600,
                              }}
                            >
                              {data.title}
                            </p>
                          </Stack>
                        </Stack>
                      </ResourceItem>
                    )
                  }}
                />
              </Card>
            </div>
          </Card>
        </>
      ) : (
        <>
          <Card sectioned title={localize("Description")}>
            {currentEntity.data.description.length > 9 ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: currentEntity.data.description,
                }}
              />
            ) : (
              <TextContainer>{localize("NoDescription")}</TextContainer>
            )}
          </Card>
          <Card sectioned title="URL">
            <TextContainer>
              <a
                target="_blank"
                href={currentEntity.data.url}
                style={{ color: "white" }}
              >
                {currentEntity.data.url}
              </a>
            </TextContainer>
          </Card>
        </>
      )}
    </Page>
  )
}

export default AcademyModule
