/*global google*/
import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Page,
  Card,
  Layout,
  Stack,
  Icon,
  TextField,
  DatePicker,
  Modal,
  Button,
  Tabs,
  ChoiceList,
  Select,
  Toast,
  Image,
  DropZone,
  Spinner,
  TextContainer,
  TextStyle,
} from "@shopify/polaris";
import { BankMajor, CalendarMinor, ClockMinor } from "@shopify/polaris-icons";
import { useHistory, useParams } from "react-router-dom";

// animation
import { CSSTransition } from "react-transition-group";

// google places
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
// import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";

// images
import locationIcon from "./../../../../images/locations.svg";
import locationIconDarkTheme from "./../../../../images/locations-dark-theme.svg";
import poweredByGoogle from "../../../../images/powered-by-google.png";
import poweredByGoogleWhite from "../../../../images/powered-by-google-white.png";

// global object
import globalObjEvent from "../Utilities/globalObjEvent";

// text editor
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// all languages array
import languagesArray from "../../../../app/config/languagesArray";

// localizer
import { useLocalizer } from "reactjs-localizer";

// api
import Api, { GraphApi } from "../../../../api";
import initiative from "../../../../api/modules/initiatives";

// function to remove event listener
import debounce from "../../../../app/config/debounceFunction";

//image cropper
import Slider from "@material-ui/core/Slider";
import Cropper from "react-easy-crop";
import { getCroppedImg } from "../../../Team/Utilities/canvasUtils";

// image compression
import imageCompression from "browser-image-compression";

// context
import { AuthContext } from "../../../../app/AuthContext";

const EventNew = () => {
  const { id } = useParams();
  const { localize } = useLocalizer();
  const history = useHistory();
  var userLang = navigator.language || navigator.userLanguage;
  userLang = userLang.slice(0, userLang.indexOf("-"));

  const allLanguages = languagesArray.map((l) => {
    return { id: l.id, content: localize(l.content) };
  });
  allLanguages.unshift({ id: "en", content: localize("English") });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { theme } = useContext(AuthContext);

  // REMOVE RESIZE END EVENT
  if (window.innerWidth > 450) {
    window.removeEventListener("resize", debounce);
  }

  // DARK OR LIGHT THEME
  useEffect(() => {
    let input = document.getElementById("react-select-2-input");

    input.style.transition = "none";
    if (theme.colorScheme === "dark") {
      input.style.backgroundColor = "#202123";
      input.parentElement.style.backgroundColor = "#202123";
      input.parentElement.parentElement.style.backgroundColor = "#202123";
      input.parentElement.parentElement.parentElement.style.backgroundColor =
        "#202123";
      input.parentElement.parentElement.parentElement.parentElement.style.backgroundColor =
        "#202123";
      input.style.color = "white";
    }
    //eslint-disable-next-line
  }, []);

  // TABS
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = useCallback(
    (selectedTabIndex) => setSelectedTab(selectedTabIndex),
    [setSelectedTab]
  );

  const [tabs, setTabs] = useState([]);

  // CHOICE LIST
  const [langChoices, setLangChoices] = useState();
  useEffect(() => {
    let choices = [];
    for (let i = 0; i < allLanguages.length; i++) {
      if (allLanguages[i].id !== localStorage.getItem("defaultLang")) {
        let choiceToPush = {
          label: allLanguages[i].content,
          value: allLanguages[i].id,
        };
        choices.push(choiceToPush);
      }
    }
    setLangChoices(choices);
    //eslint-disable-next-line
  }, []);
  const [selectedChoices, setSelectedChoices] = useState([]);
  const [selectedChoicesWhenModalOpen, setSelectedChoicesWhenModalOpen] =
    useState([]);

  const handleChoicesSelectionChange = useCallback(
    (value) => setSelectedChoices(value),
    [setSelectedChoices]
  );

  // IMAGE CROP
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [doneButtonDisabled, setDoneButtonDisabled] = useState(false);
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  // read file dropped in the drop zone
  function readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  }

  // cropped image displayed
  const croppedImageVisual = croppedImage && (
    <img src={croppedImage} alt="" className="cropped-image-visual" />
  );

  // IMAGE UPLOAD
  const [uploadedImage, setUploadedImage] = useState();

  const fileUpload = !uploadedImage && !croppedImage && (
    <DropZone.FileUpload
      actionTitle={localize("AddImage")}
      actionHint={localize("DropImage")}
    />
  );

  const onFileChange = useCallback(async () => {
    let imageDataUrl = await readFile(uploadedImage);
    setImageSrc(imageDataUrl);
  }, [uploadedImage]);

  // MODAL FOR CROPPING
  const [modalForCroppingActive, setModalForCroppingActive] = useState(false);

  const handleModalForCroppingChange = useCallback(() => {
    setModalForCroppingActive(!modalForCroppingActive);
    setCrop({ x: 0, y: 0 });
    setZoom(1);
  }, [modalForCroppingActive]);

  const handleModalForCroppingClose = useCallback(() => {
    setModalForCroppingActive(!modalForCroppingActive);
    setUploadedImage();
    setCrop({ x: 0, y: 0 });
    setZoom(1);
  }, [modalForCroppingActive]);

  useEffect(() => {
    if (uploadedImage) {
      onFileChange();
    }
  }, [uploadedImage, onFileChange]);

  // handle the dropped file
  const handleDropZoneDrop = useCallback(
    async (_dropFiles, acceptedFiles, _rejectedFiles) => {
      if (acceptedFiles.length > 0) {
        setUploadedImage(acceptedFiles[0]);
        handleModalForCroppingChange();
      }
    },
    [handleModalForCroppingChange]
  );

  const takeCroppedImage = useCallback(async () => {
    setDoneButtonDisabled(true);

    try {
      const croppedImageToSave = await getCroppedImg(
        imageSrc,
        croppedAreaPixels
      );

      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      try {
        const blobNotCompressed = await fetch(croppedImageToSave).then((r) =>
          r.blob()
        );

        const blob = await imageCompression(blobNotCompressed, options);

        const apiCall = await Api.teams.getSignedUrl();
        const imageUrl = apiCall.getUrl;
        const path = apiCall.s3PutObjectUrl;

        await Api.teams.putS3Object(blob, path);
        setCroppedImage(imageUrl);
        handleModalForCroppingChange();
        setUploadedImage();
        setDoneButtonDisabled(false);
      } catch (e) {
        console.log(e);
      }
    } catch (e) {
      console.error(e);
      setDoneButtonDisabled(false);
    }
  }, [imageSrc, croppedAreaPixels, handleModalForCroppingChange]);

  // MODAl
  const [choiceModalActive, setChoiceModalActive] = useState(false);

  const handleChoiceModalOpen = useCallback(() => {
    setChoiceModalActive(true);
    setSelectedChoicesWhenModalOpen(selectedChoices);
  }, [selectedChoices]);

  const handleChoiceModalClose = useCallback(() => {
    setChoiceModalActive(false);
    setSelectedChoices(selectedChoicesWhenModalOpen);
  }, [selectedChoicesWhenModalOpen]);

  const handleLanguagesManaged = () => {
    setChoiceModalActive(!choiceModalActive);

    let newTabs = [];
    for (let i = 0; i < allLanguages.length; i++) {
      if (allLanguages[i].id === localStorage.getItem("defaultLang")) {
        newTabs.push(allLanguages[i]);
      }
    }
    for (let i = 0; i < selectedChoices.length; i++) {
      for (let j = 0; j < allLanguages.length; j++) {
        if (selectedChoices[i] === allLanguages[j].id) {
          newTabs.push(allLanguages[j]);
        }
      }
    }

    if (selectedTab >= newTabs.length) {
      setSelectedTab(0);
    }

    setTabs([...newTabs]);
  };

  // EDITOR
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  // START DATE PICKER
  const [{ startMonth, startYear }, setStartDateDisplayed] = useState({
    startMonth: null,
    startYear: null,
  });

  const [selectedStartDates, setSelectedStartDates] = useState({
    start: new Date(),
    end: new Date(),
  });

  const handleStartMonthChange = useCallback(
    (startMonth, startYear) => setStartDateDisplayed({ startMonth, startYear }),
    [setStartDateDisplayed]
  );

  // END DATE PICKER
  const [{ endMonth, endYear }, setEndDateDisplayed] = useState({
    endMonth: null,
    endYear: null,
  });

  const [selectedEndDates, setSelectedEndDates] = useState({
    start: new Date(),
    end: new Date(),
  });

  const handleEndMonthChange = useCallback(
    (endMonth, endYear) => setEndDateDisplayed({ endMonth, endYear }),
    [setEndDateDisplayed]
  );

  // MODAL FOR START DATE PICKER
  const [startModalActive, setStartModalActive] = useState(false);

  const handleStartModalChange = useCallback(
    () => setStartModalActive(!startModalActive),
    [startModalActive]
  );

  // MODAL FOR END DATE PICKER
  const [endModalActive, setEndModalActive] = useState(false);

  const handleEndModalChange = useCallback(
    () => setEndModalActive(!endModalActive),
    [endModalActive]
  );

  // GOOGLE PLACES
  const [value, setValue] = useState(null);

  // CITY
  const [cityInput, setCityInput] = useState("");
  const handleCityChange = useCallback((value) => {
    setMissingCityError(false);
    setCityInput(value);
  }, []);

  // TIME
  const [timeFrom, setTimeFrom] = useState("");
  const [timeTo, setTimeTo] = useState("");

  const timeFromChange = useCallback(
    (value) => {
      setTimeFromError(false);
      setInvalidTimeError(false);
      setMissingTimeFromError(false);

      //eslint-disable-next-line
      var regex = /^$|[0-9\:]+$/;

      if (
        regex.test(value) &&
        value[value.length - 1] !== " " &&
        value.length < 6
      ) {
        if (value.length === 3) {
          if (value[value.length - 1] !== ":") {
            value = value.slice(0, 2) + ":" + value.slice(2);
          } else {
            value = value.slice(0, value.length - 1);
          }
          setTimeFrom(value);
        } else if (value[value.length - 1] !== ":") {
          setTimeFrom(value);
        }
      }
    },
    [setTimeFrom]
  );

  const timeToChange = useCallback(
    (value) => {
      setTimeToError(false);
      setInvalidTimeError(false);
      setMissingTimeToError(false);

      //eslint-disable-next-line
      var regex = /^$|[0-9\:]+$/;

      if (
        regex.test(value) &&
        value[value.length - 1] !== " " &&
        value.length < 6
      ) {
        if (value.length === 3) {
          if (value[value.length - 1] !== ":") {
            value = value.slice(0, 2) + ":" + value.slice(2);
          } else {
            value = value.slice(0, value.length - 1);
          }
          setTimeTo(value);
        } else if (value[value.length - 1] !== ":") {
          setTimeTo(value);
        }
      }
    },
    [setTimeTo]
  );

  // TEAM SELECT
  const [selectedTeam, setSelectedTeam] = useState("");
  const handleTeamSelectChange = useCallback(
    (value) => setSelectedTeam(value),
    []
  );

  const [teamOptions, setTeamOptions] = useState([]);

  // ADD BUTTON
  const [addButtonDisabled, setAddButtonDisabled] = useState(true);

  // INITIALIZATION
  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [dateToDisableBefore, setDateToDisableBefore] = useState();
  const [dateToDisableAfter, setDateToDisableAfter] = useState();

  useEffect(() => {
    let data = JSON.parse(localStorage.getItem("initiativeData"));

    let todayDate = new Date();
    let startD = new Date(data.startDate);
    let endD = new Date(data.endDate);

    setName(data.name);

    setImage(data.image);

    if (todayDate > startD) {
      setStartDateDisplayed({
        startMonth: todayDate.getMonth(),
        startYear: todayDate.getFullYear(),
      });
      setEndDateDisplayed({
        endMonth: todayDate.getMonth(),
        endYear: todayDate.getFullYear(),
      });

      todayDate.setDate(todayDate.getDate() - 1);
      setDateToDisableBefore(todayDate);
    } else {
      setSelectedStartDates({
        start: new Date(data.startDate),
        end: new Date(data.startDate),
      });
      setSelectedEndDates({
        start: new Date(data.startDate),
        end: new Date(data.startDate),
      });

      setStartDateDisplayed({
        startMonth: startD.getMonth(),
        startYear: startD.getFullYear(),
      });
      setEndDateDisplayed({
        endMonth: startD.getMonth(),
        endYear: startD.getFullYear(),
      });

      startD.setDate(startD.getDate() - 1);
      setDateToDisableBefore(startD);
    }

    setDateToDisableAfter(endD);

    // ALL LANGUAGES ARRAY CONTAINING ALSO ENGLISH
    let langs = allLanguages;
    langs.unshift({
      id: "en",
      content: localize("English"),
    });

    let langToSetTab = langs.filter((l) => l.id === data.defaultLang);

    setTabs([langToSetTab[0]]);

    // TEAM OPTIONS
    let teamsArray = JSON.parse(localStorage.getItem("teamsArray"));
    let supportingTeams = [];

    for (let i = 0; i < teamsArray.length; i++) {
      let teamId = teamsArray[i].slice(teamsArray[i].indexOf("%") + 1);
      let teamName = teamsArray[i].slice(0, teamsArray[i].indexOf("%"));

      supportingTeams.push({ label: teamName, value: teamId });
    }

    setTeamOptions(supportingTeams);
    setSelectedTeam(supportingTeams[0].value);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setStartDateDisplayed({
      startMonth: selectedStartDates.start.getMonth(),
      startYear: selectedStartDates.start.getFullYear(),
    });
    setEndDateDisplayed({
      endMonth: selectedEndDates.start.getMonth(),
      endYear: selectedEndDates.start.getFullYear(),
    });
  }, [selectedStartDates, selectedEndDates]);

  // PLACE DETAILS
  const [placeDetails, setPlaceDetails] = useState();

  useEffect(() => {
    if (value !== null) {
      var request = {
        placeId: value.value.place_id,
        fields: ["name", "geometry", "address_components", "formatted_address"],
      };

      let service = new google.maps.places.PlacesService(
        document.createElement("div")
      );
      service.getDetails(request, callback);

      function callback(place, status) {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          setPlaceDetails(place);
        }
      }
    }
  }, [value]);

  // TITLE
  const [title, setTitle] = useState("");
  const handleTitleChange = useCallback((value) => {
    let regex = /^$|[0-9A-Za-z#?!\-@'_., \u00C0-\u00F6\u00F8-\u00FF]+$/;
    if (regex.test(value)) {
      setTitle(value);
    }
  }, []);

  useEffect(() => {
    if (placeDetails) {
      let city;
      for (let i = 0; i < placeDetails.address_components.length; i++) {
        if (placeDetails.address_components[i].types[0] === "locality") {
          city = placeDetails.address_components[i].long_name;
        } else if (
          placeDetails.address_components[i].types[0] === "colloquial_area" &&
          city === undefined
        ) {
          city = placeDetails.address_components[i].long_name;
        } else if (
          placeDetails.address_components[i].types[0] ===
            "administrative_area_level_3" &&
          city === undefined
        ) {
          city = placeDetails.address_components[i].long_name;
        } else if (
          placeDetails.address_components[i].types[0] === "postal_town" &&
          city === undefined
        ) {
          city = placeDetails.address_components[i].long_name;
        }
      }

      if (city) {
        setTitle(name + " - " + city);
        for (let key in globalObjEvent) {
          globalObjEvent[key].title = name + " - " + city;
        }
        setCityInput(city);
      } else {
        setTitle("");
        for (let key in globalObjEvent) {
          globalObjEvent[key].title = "";
        }
        setCityInput("");
      }
    }
    //eslint-disable-next-line
  }, [placeDetails]);

  // ACTIONS
  const [selectedActions, setSelectedActions] = useState([
    JSON.parse(localStorage.getItem("actionsList"))[0].action.id,
  ]);
  const [choices, setChoices] = useState([]);

  const handleSelectionChange = useCallback(
    (value) => setSelectedActions(value),
    []
  );

  useEffect(() => {
    let actionsList = JSON.parse(localStorage.getItem("actionsList"));
    let defaultLanguage = localStorage.getItem("defaultLang");
    let cards = [];

    for (let i = 0; i < actionsList.length; i++) {
      let actionId = actionsList[i].action.id;

      let action = actionsList[i].action.translations.items;
      let actionInUserLang = action.filter((value) => value.lang === userLang);
      if (actionInUserLang.length === 0) {
        actionInUserLang = action.filter(
          (value) => value.lang === defaultLanguage
        );
      }
      actionInUserLang = actionInUserLang[0];

      cards.push({
        label: (
          <Stack alignment="leading" spacing="loose">
            <div className="img-container">
              <Image
                source={actionInUserLang.image}
                alt="Action"
                style={{
                  height: "40px",
                  borderRadius: 5,
                  paddingBottom: 5,
                  marginTop: -12,
                }}
              />
            </div>
            <p>
              {actionInUserLang.title.includes("Task")
                ? actionInUserLang.title.slice(
                    actionInUserLang.title.indexOf("-") + 2
                  )
                : actionInUserLang.title}
            </p>
          </Stack>
        ),
        value: actionId,
      });
    }

    setChoices(cards);
    //eslint-disable-next-line
  }, []);

  // READ FROM GLOBAL OBJECT
  useEffect(() => {
    if (tabs.length !== 0) {
      setTitle(globalObjEvent[tabs[selectedTab].id].title);
      const blocksFromHtml = htmlToDraft(
        globalObjEvent[tabs[selectedTab].id].body
      );
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      setEditorState(EditorState.createWithContent(contentState));
    }
    //eslint-disable-next-line
  }, [selectedTab]);

  // WRITE TO GLOBAL OBJECT
  useEffect(() => {
    if (tabs.length !== 0) {
      globalObjEvent[tabs[selectedTab].id].title = title;
      globalObjEvent[tabs[selectedTab].id].body = draftToHtml(
        convertToRaw(editorState.getCurrentContent())
      );
    }
    if (croppedImage) {
      for (let key in globalObjEvent) {
        globalObjEvent[key].image = croppedImage;
      }
    }

    if (value === null || selectedActions.length === 0) {
      setAddButtonDisabled(true);
    } else {
      setAddButtonDisabled(false);
    }
    //eslint-disable-next-line
  }, [
    timeFrom,
    timeTo,
    value,
    cityInput,
    editorState,
    selectedActions,
    title,
    croppedImage,
  ]);

  // SAVE ERROR TOAST
  const [errorToastActive, setErrorToastActive] = useState(false);

  const toggleErrorToastActive = useCallback(
    () => setErrorToastActive((errorToastActive) => !errorToastActive),
    []
  );

  const errorToastMarkup = errorToastActive ? (
    <Toast
      content={localize("SomethingWentWrong")}
      error
      onDismiss={toggleErrorToastActive}
    />
  ) : null;

  // SUPPORT MODAL
  const [supportModalActive, setSupportModalActive] = useState(false);

  const handleSupportModalChange = useCallback(
    () => setSupportModalActive(!supportModalActive),
    [supportModalActive]
  );

  const handleSupportModalYes = () => {
    setSupportModalActive(!supportModalActive);
    save(true);
  };

  // SAVE
  const [saving, setSaving] = useState(false);
  const [timeFromError, setTimeFromError] = useState(false);
  const [timeToError, setTimeToError] = useState(false);
  const [invalidTimeError, setInvalidTimeError] = useState(false);
  const [invalidDateError, setInvalidDateError] = useState(false);
  const [missingTimeFromError, setMissingTimeFromError] = useState(false);
  const [missingTimeToError, setMissingTimeToError] = useState(false);
  const [missingCityError, setMissingCityError] = useState(false);

  // data missing toast
  const [dataMissingToastActive, setDataMissingToastActive] = useState(false);

  const toggleDataMissingToastActive = useCallback(
    () => setDataMissingToastActive((active) => !active),
    []
  );

  const dataMissingToastMarkup = dataMissingToastActive ? (
    <Toast
      duration={2500}
      content={localize("SomeDataAreMissing")}
      error
      onDismiss={toggleDataMissingToastActive}
    />
  ) : null;

  // document missing toast
  const [languageForToast, setLanguageForToast] = useState("");
  const [wordForToast, setWordForToast] = useState("");
  const [documentMissingToastActive, setDocumentMissingToastActive] =
    useState(false);

  const toggleDocumentMissingToastActive = useCallback(
    () => setDocumentMissingToastActive((active) => !active),
    []
  );

  const documentMissingToastMarkup = documentMissingToastActive ? (
    <Toast
      duration={2500}
      content={`${localize(
        wordForToast
      )} ${languageForToast.toLowerCase()}  ${localize("IsMissing")}`}
      error
      onDismiss={toggleDocumentMissingToastActive}
    />
  ) : null;

  const checkFields = async () => {
    setErrorToastActive(false);
    setInvalidTimeError(false);
    setTimeFromError(false);
    setTimeToError(false);
    setMissingTimeFromError(false);
    setMissingTimeToError(false);
    setMissingCityError(false);

    let defaultLanguage = localStorage.getItem("defaultLang");

    let langForToast = allLanguages.filter(
      (value) => value.id === defaultLanguage
    );
    langForToast = langForToast[0];
    setLanguageForToast(langForToast.content);

    // fields check
    let error = false;
    if (timeFrom.length === 0) {
      setMissingTimeFromError(true);
      error = true;
    }
    if (timeTo.length === 0) {
      setMissingTimeToError(true);
      error = true;
    }
    if (cityInput.length === 0) {
      setMissingCityError(true);
      error = true;
    }

    if (error) {
      setSaving(false);
      toggleDataMissingToastActive();
      return;
    }

    // title and description in default language check
    if (globalObjEvent[defaultLanguage].title === "") {
      setWordForToast("TheTitleIn");
      setSaving(false);
      toggleDocumentMissingToastActive();
      return;
    }
    if (
      globalObjEvent[defaultLanguage].body.startsWith("<p></p>") ||
      globalObjEvent[defaultLanguage].body === ""
    ) {
      setWordForToast("TheDescriptionIn");
      setSaving(false);
      toggleDocumentMissingToastActive();
      return;
    }

    // date check
    if (selectedStartDates.start > selectedEndDates.start) {
      setInvalidDateError(true);
      setSaving(false);
      return;
    }

    // time check
    let hourFrom = timeFrom.slice(0, timeFrom.indexOf(":"));
    let minutesFrom = timeFrom.slice(timeFrom.indexOf(":") + 1);
    let hourTo = timeTo.slice(0, timeTo.indexOf(":"));
    let minutesTo = timeTo.slice(timeTo.indexOf(":") + 1);

    let timeError = false;
    if (parseInt(hourFrom) > 24 || parseInt(minutesFrom) > 59) {
      setTimeFromError(true);
      timeError = true;
    }
    if (parseInt(hourTo) > 24 || parseInt(minutesTo) > 59) {
      setTimeToError(true);
      timeError = true;
    }
    if (timeError) {
      setSaving(false);
      return;
    }

    if (
      selectedStartDates.start.toDateString() ===
      selectedEndDates.start.toDateString()
    ) {
      if (parseInt(hourTo) < parseInt(hourFrom)) {
        setInvalidTimeError(true);
        setSaving(false);
        return;
      } else if (parseInt(hourTo) === parseInt(hourFrom)) {
        if (
          parseInt(minutesTo) < parseInt(minutesFrom) ||
          parseInt(minutesTo) === parseInt(minutesFrom)
        ) {
          setInvalidTimeError(true);
          setSaving(false);
          return;
        }
      }
    }

    // aggiungere controllo per vedere se il team supporta già l'iniziativa o no
    let supportingArray = JSON.parse(localStorage.getItem("supportingArray"));
    let alreadySupporting = false;
    for (let i = 0; i < supportingArray.length; i++) {
      if (supportingArray[i].includes(id)) {
        let currentId = supportingArray[i].slice(
          0,
          supportingArray[i].indexOf(",")
        );

        if (currentId === selectedTeam.toString()) {
          alreadySupporting = true;
        }
      }
    }

    if (alreadySupporting) {
      save();
    } else {
      handleSupportModalChange();
    }
  };

  const save = async (shouldSupport = false) => {
    setSaving(true);

    if (shouldSupport) {
      try {
        await GraphApi.graphql({
          query: initiative.mutations.supportInitiative,
          variables: {
            teamId: selectedTeam.toString(),
            initiativesId: id.toString(),
          },
        });

        let allTeams = JSON.parse(localStorage.getItem("apiCall"));
        let currentTeam = allTeams.filter(
          (item) => item.id.toString() === selectedTeam.toString()
        );
        currentTeam = currentTeam[0];
        let supportingArray = JSON.parse(
          localStorage.getItem("supportingArray")
        );
        supportingArray.push(
          selectedTeam +
            "," +
            id +
            "," +
            currentTeam.name +
            "%" +
            currentTeam.uid
        );
        localStorage.setItem(
          "supportingArray",
          JSON.stringify(supportingArray)
        );
      } catch (e) {
        console.log(e);
        setErrorToastActive(true);
        setSaving(false);
      }
    }

    let defaultLanguage = localStorage.getItem("defaultLang");

    // action
    let actionId = selectedActions[0];

    // scope
    let scope = "public";

    // location type
    let locationType = "phisical";

    // initiative id
    let initiativeId = id;

    // team creator
    let teamCreator = { id: selectedTeam };
    let teamCreatorId = selectedTeam;

    // time
    let offset = new Date().getTimezoneOffset();

    let selectedStart = new Date(
      selectedStartDates.start.getTime() + -offset * 60 * 1000
    );
    let selectedEnd = new Date(
      selectedEndDates.start.getTime() + -offset * 60 * 1000
    );

    let startsAt =
      selectedStart
        .toISOString()
        .slice(0, selectedStart.toISOString().indexOf("T") + 1) +
      timeFrom +
      ":00.000Z";
    let endsAt =
      selectedEnd
        .toISOString()
        .slice(0, selectedEnd.toISOString().indexOf("T") + 1) +
      timeTo +
      ":00.000Z";

    let first = new Date(startsAt);
    var firstMilliseconds = Date.parse(first);
    firstMilliseconds = firstMilliseconds + offset * 60 * 1000;
    first = new Date(firstMilliseconds);
    let second = new Date(endsAt);
    var secondMilliseconds = Date.parse(second);
    secondMilliseconds = secondMilliseconds + offset * 60 * 1000;
    second = new Date(secondMilliseconds);

    startsAt = first.toISOString();
    endsAt = second.toISOString();

    // place
    let location = {
      lat: placeDetails.geometry.location.lat(),
      lon: placeDetails.geometry.location.lng(),
    };
    let locationLabel = value.label;
    let state;
    let country;
    let city = cityInput;
    let address = locationLabel;
    for (let i = 0; i < placeDetails.address_components.length; i++) {
      if (
        placeDetails.address_components[i].types[0] ===
        "administrative_area_level_1"
      ) {
        state = placeDetails.address_components[i].long_name;
      }
      if (placeDetails.address_components[i].types[0] === "route") {
        address = placeDetails.address_components[i].long_name;
      }
      if (placeDetails.address_components[i].types[0] === "country") {
        country = placeDetails.address_components[i].long_name;
      }
    }

    //input
    let input = {
      actionId: actionId,
      address: address,
      city: city,
      country: country,
      endsAt: endsAt,
      initiativeId: initiativeId,
      location: location,
      locationType: locationType,
      locationLabel: locationLabel,
      name: globalObjEvent[defaultLanguage].title,
      scope: scope,
      startsAt: startsAt,
      state: state,
      teamCreator: teamCreator,
      teamCreatorId: teamCreatorId,
    };

    // global object
    let globalObjCopy = JSON.parse(JSON.stringify(globalObjEvent));

    for (let key in globalObjCopy) {
      let shouldDelete = true;

      for (let i = 0; i < tabs.length; i++) {
        if (key === tabs[i].id) {
          shouldDelete = false;
        }
      }

      if (shouldDelete) {
        delete globalObjCopy[key];
      }
    }

    for (let key in globalObjCopy) {
      if (
        globalObjCopy[key].title === "" ||
        globalObjCopy[key].body === "" ||
        globalObjCopy[key].body.startsWith("<p></p>")
      ) {
        delete globalObjCopy[key];
      }
    }

    if (globalObjCopy[defaultLanguage].image === "") {
      for (let key in globalObjCopy) {
        globalObjCopy[key].image = image;
      }
    }

    // call
    try {
      const { data } = await GraphApi.graphql({
        query: initiative.mutations.createEvents,
        variables: { input: input },
      });
      const eventId = data.createEvents.id;

      for (let key in globalObjCopy) {
        await GraphApi.graphql({
          query: initiative.mutations.createEventTranslations,
          variables: {
            input: {
              description: globalObjCopy[key].body.replace(
                /(\r\n|\n|\r)/gm,
                ""
              ),
              image: globalObjCopy[key].image,
              lang: key,
              title: globalObjCopy[key].title,
              eventsTranslationsId: eventId,
            },
          },
        });
      }

      setSaving(false);
      history.push(
        "/dashboard/initiatives/" + id + "/" + eventId + "/justcreated"
      );
    } catch (e) {
      console.log(e);
      setErrorToastActive(true);
      setSaving(false);
    }
  };

  // ERROR WHEN A TEXT WITH 4000 CHARACTERS OR MORE IS PASTED
  const [lengthToastActive, setLengthToastActive] = useState(false);

  const toggleLengthToastActive = useCallback(
    () => setLengthToastActive((lengthToastActive) => !lengthToastActive),
    []
  );

  const lengthToastMarkup = lengthToastActive ? (
    <Toast
      content={localize("ThePastedText")}
      onDismiss={toggleLengthToastActive}
      error
      duration={3500}
    />
  ) : null;

  return (
    <Page
      title={localize("AddEventFor") + " " + name}
      breadcrumbs={[
        {
          content: "Overview",
          url: `/dashboard/initiatives/${id}`,
        },
      ]}
      primaryAction={{
        content: localize("Add"),
        disabled: addButtonDisabled,
        onAction: checkFields,
        loading: saving,
      }}
    >
      <Modal
        open={choiceModalActive}
        onClose={handleChoiceModalClose}
        title={localize("AddOrRemoveLanguages")}
        primaryAction={{
          content: localize("Done"),
          onAction: handleLanguagesManaged,
        }}
      >
        <Modal.Section>
          <ChoiceList
            allowMultiple
            choices={[
              {
                label: tabs[0] ? tabs[0].content : "Loading",
                value: tabs[0] ? tabs[0].id : "loading",
              },
            ]}
            selected={tabs[0] ? [tabs[0].id] : ["loading"]}
            disabled
          />
          <ChoiceList
            allowMultiple
            choices={langChoices}
            selected={selectedChoices}
            onChange={handleChoicesSelectionChange}
          />
        </Modal.Section>
      </Modal>
      <Layout>
        <Layout.Section>
          <CSSTransition
            in={true}
            appear={true}
            timeout={1000}
            classNames="transform"
          >
            <Card title={localize("Details")}>
              <Card.Section>
                <Stack vertical>
                  <Stack vertical spacing="extraTight">
                    <p>{localize("Place")}</p>
                    <div
                      onKeyDown={(e) => {
                        if (e.key === "Backspace") {
                          setValue(null);
                        }
                      }}
                    >
                      <GooglePlacesAutocomplete
                        apiKey={process.env.REACT_APP_API_KEY}
                        minLengthAutocomplete={3}
                        selectProps={{
                          value,
                          onChange: setValue,
                          placeholder: "Ciao",
                          styles: {
                            placeholder: () => ({
                              display: "none",
                            }),
                            input: (provided) => ({
                              ...provided,
                              background: `url(${
                                theme.colorScheme === "dark"
                                  ? locationIconDarkTheme
                                  : locationIcon
                              }) no-repeat scroll -2px`,
                              transition: "250ms",
                              paddingLeft: "32px",
                            }),
                            menu: (provided) => ({
                              ...provided,
                              background:
                                theme.colorScheme === "dark"
                                  ? `url(${poweredByGoogleWhite}) no-repeat scroll 99.9% 99%, #202123`
                                  : `url(${poweredByGoogle}) no-repeat scroll 99.9% 99%, white`,
                              backgroundSize: "105px 13px",
                              paddingBottom: "13px",
                              transition: "none !important",
                              zIndex: 21,
                            }),
                            option: (provided, state) => ({
                              ...provided,
                              backgroundColor:
                                theme.colorScheme === "dark"
                                  ? state.isFocused
                                    ? "#2686ff"
                                    : "#202123"
                                  : state.isFocused
                                  ? "#ddebff"
                                  : "#ffffff",
                            }),
                            singleValue: (provided) => ({
                              ...provided,
                              paddingLeft: "32px",
                              color: theme.colorScheme === "dark" && "white",
                            }),
                          },
                        }}
                      />
                    </div>
                  </Stack>
                  <TextField
                    label={localize("City")}
                    prefix={
                      <div style={{ marginRight: 3 }}>
                        <Icon source={BankMajor} />
                      </div>
                    }
                    value={cityInput}
                    onChange={handleCityChange}
                    clearButton
                    error={missingCityError ? localize("EnterTheCity") : null}
                    onClearButtonClick={() => {
                      setCityInput("");
                      setMissingCityError(false);
                    }}
                  />
                  <Stack alignment="leading" distribution="fillEvenly">
                    <TextField
                      type="text"
                      label={localize("Date")}
                      onFocus={() => {
                        handleStartModalChange();
                        setInvalidDateError(false);
                      }}
                      suffix={
                        <Button
                          plain
                          onClick={() => {
                            handleStartModalChange();
                            setInvalidDateError(false);
                          }}
                        >
                          {localize("PickDate")}
                        </Button>
                      }
                      value={
                        selectedStartDates.start.getDate().toString() +
                        "/" +
                        (selectedStartDates.start.getMonth() + 1).toString() +
                        "/" +
                        selectedStartDates.start.getFullYear().toString()
                      }
                      readOnly
                      prefix={
                        <Stack alignment="center">
                          <Icon source={CalendarMinor} />
                          <p>{localize("From")}</p>
                        </Stack>
                      }
                    />
                    <TextField
                      type="text"
                      label={
                        window.innerWidth > 450 ? (
                          <p
                            style={{
                              visibility: "hidden",
                            }}
                          >
                            hidden label
                          </p>
                        ) : null
                      }
                      error={invalidDateError ? localize("InvalidDate") : null}
                      onFocus={() => {
                        handleEndModalChange();
                        setInvalidDateError(false);
                      }}
                      suffix={
                        <Button
                          plain
                          onClick={() => {
                            handleEndModalChange();
                            setInvalidDateError(false);
                          }}
                        >
                          {localize("PickDate")}
                        </Button>
                      }
                      value={
                        selectedEndDates.start.getDate().toString() +
                        "/" +
                        (selectedEndDates.start.getMonth() + 1).toString() +
                        "/" +
                        selectedEndDates.start.getFullYear().toString()
                      }
                      readOnly
                      prefix={
                        <Stack alignment="center">
                          <Icon source={CalendarMinor} />
                          <p>{localize("To")}</p>
                        </Stack>
                      }
                    />
                  </Stack>
                  <Stack alignment="leading" distribution="fillEvenly">
                    <TextField
                      type="text"
                      label={localize("Time")}
                      inputMode="numeric"
                      onChange={timeFromChange}
                      value={timeFrom}
                      error={
                        timeFromError
                          ? localize("InvalidTime")
                          : missingTimeFromError
                          ? localize("EnterTheEventStartTime")
                          : null
                      }
                      prefix={
                        <Stack alignment="center">
                          <Icon source={ClockMinor} />
                          <p>{localize("FromHour")}</p>
                        </Stack>
                      }
                      clearButton={true}
                      onClearButtonClick={() => {
                        setTimeFrom("");
                        setTimeFromError(false);
                        setInvalidTimeError(false);
                        setMissingTimeFromError(false);
                      }}
                    />
                    <TextField
                      type="text"
                      label={
                        window.innerWidth > 450 ? (
                          <p
                            style={{
                              visibility: "hidden",
                            }}
                          >
                            hidden label
                          </p>
                        ) : null
                      }
                      inputMode="numeric"
                      onChange={timeToChange}
                      value={timeTo}
                      error={
                        timeToError
                          ? localize("InvalidTime")
                          : invalidTimeError
                          ? localize("SecondTimeMustBeGreater")
                          : missingTimeToError
                          ? localize("EnterTheEventEndTime")
                          : null
                      }
                      prefix={
                        <Stack alignment="center">
                          <Icon source={ClockMinor} />
                          <p>{localize("ToHour")}</p>
                        </Stack>
                      }
                      clearButton={true}
                      onClearButtonClick={() => {
                        setTimeTo("");
                        setTimeToError(false);
                        setInvalidTimeError(false);
                        setMissingTimeToError(false);
                      }}
                    />
                  </Stack>
                  <Select
                    label="Team"
                    options={teamOptions}
                    onChange={handleTeamSelectChange}
                    value={selectedTeam}
                  />
                  <p>{localize("Action")}</p>
                  <ChoiceList
                    choices={choices}
                    selected={selectedActions}
                    onChange={handleSelectionChange}
                  />
                </Stack>
              </Card.Section>
            </Card>
          </CSSTransition>
          <CSSTransition
            in={true}
            appear={true}
            timeout={1000}
            classNames="transform"
          >
            <Card
              title={localize("Information")}
              actions={[
                {
                  content: localize("AddOrRemoveLanguages"),
                  onAction: handleChoiceModalOpen,
                },
              ]}
            >
              <Tabs
                tabs={tabs}
                selected={selectedTab}
                onSelect={handleTabChange}
              ></Tabs>
              <Card.Section>
                <Stack vertical>
                  <TextField
                    label={localize("Name")}
                    value={title}
                    helpText={localize("OnlyLettersNumbers") + " @#?!-,_."}
                    onChange={handleTitleChange}
                    onBlur={() => {
                      setTitle(title.trim());
                    }}
                    placeholder={
                      tabs[selectedTab]
                        ? localize("Name") +
                          " (" +
                          tabs[selectedTab].content +
                          ")"
                        : localize("Name")
                    }
                  />
                  <p className="negative-margin-bottom">
                    {localize("Description")}
                  </p>
                  <Editor
                    toolbarClassName={
                      theme.colorScheme === "dark" && "rdw-editor-toolbar-dark"
                    }
                    onBlur={() => {
                      // to remove whitespaces before and after string
                      let currentContent = draftToHtml(
                        convertToRaw(editorState.getCurrentContent())
                      );
                      currentContent = currentContent.replace(/  +/g, " ");
                      currentContent = currentContent.replaceAll("&nbsp;", "");
                      const blocksFromHtml = htmlToDraft(currentContent);
                      const { contentBlocks, entityMap } = blocksFromHtml;
                      const contentState = ContentState.createFromBlockArray(
                        contentBlocks,
                        entityMap
                      );
                      setEditorState(
                        EditorState.createWithContent(contentState)
                      );
                    }}
                    placeholder={
                      tabs[selectedTab]
                        ? localize("Description") +
                          " (" +
                          tabs[selectedTab].content +
                          ")"
                        : localize("Description")
                    }
                    editorState={editorState}
                    onEditorStateChange={setEditorState}
                    handleBeforeInput={(val) => {
                      const textLength = editorState
                        .getCurrentContent()
                        .getPlainText().length;
                      if (val && textLength >= 4000) {
                        return "handled";
                      }
                      return "not-handled";
                    }}
                    handlePastedText={(val) => {
                      const textLength = editorState
                        .getCurrentContent()
                        .getPlainText().length;
                      if (val.length + textLength >= 4000) {
                        toggleLengthToastActive();
                        return true;
                      } else {
                        return false;
                      }
                    }}
                    toolbar={{
                      options: ["inline", "list", "history", "link"],
                      inline: {
                        options: ["bold", "italic", "underline"],
                      },
                      list: {
                        options: ["unordered"],
                      },
                    }}
                  />
                </Stack>
              </Card.Section>
            </Card>
          </CSSTransition>
          <CSSTransition
            in={true}
            appear={true}
            timeout={1000}
            classNames="transform"
          >
            <Card title={localize("OptionalImage")}>
              <Card.Section>
                <Stack vertical>
                  <DropZone
                    allowMultiple={false}
                    type="image"
                    onDrop={handleDropZoneDrop}
                    accept="image/*"
                  >
                    <div
                      style={{
                        height: "auto",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {croppedImageVisual}
                    </div>
                    {fileUpload}
                  </DropZone>
                  <Modal
                    open={modalForCroppingActive}
                    onClose={handleModalForCroppingClose}
                    title={localize("CropImage")}
                    primaryAction={
                      doneButtonDisabled
                        ? {
                            content: <Spinner size="small" />,
                            disabled: true,
                          }
                        : {
                            content: localize("Done"),
                            onAction: takeCroppedImage,
                          }
                    }
                    secondaryActions={[
                      {
                        content: localize("Cancel"),
                        onAction: handleModalForCroppingClose,
                      },
                    ]}
                  >
                    <Modal.Section>
                      <div className="cropper-container">
                        <Cropper
                          image={imageSrc}
                          crop={crop}
                          zoom={zoom}
                          aspect={16 / 9}
                          onCropChange={setCrop}
                          onCropComplete={onCropComplete}
                          onZoomChange={setZoom}
                        />
                      </div>
                      <div
                        className="slider-container"
                        style={{
                          backgroundColor:
                            theme.colorScheme === "dark" ? "#202123" : "white",
                        }}
                      >
                        <Slider
                          value={zoom}
                          min={1}
                          max={3}
                          step={0.1}
                          aria-labelledby="Zoom"
                          onChange={(e, zoom) => setZoom(zoom)}
                        />
                      </div>
                    </Modal.Section>
                  </Modal>
                </Stack>
              </Card.Section>
            </Card>
          </CSSTransition>
          <Modal
            open={startModalActive}
            onClose={handleStartModalChange}
            title={localize("PickStartDate")}
            primaryAction={{
              content: localize("Done"),
              onAction: handleStartModalChange,
            }}
          >
            <Modal.Section>
              <DatePicker
                month={startMonth}
                year={startYear}
                onChange={setSelectedStartDates}
                onMonthChange={handleStartMonthChange}
                selected={selectedStartDates}
                disableDatesBefore={dateToDisableBefore}
                disableDatesAfter={dateToDisableAfter}
              />
            </Modal.Section>
          </Modal>
          <Modal
            open={endModalActive}
            onClose={handleEndModalChange}
            title={localize("PickEndDate")}
            primaryAction={{
              content: localize("Done"),
              onAction: handleEndModalChange,
            }}
          >
            <Modal.Section>
              <DatePicker
                month={endMonth}
                year={endYear}
                onChange={setSelectedEndDates}
                onMonthChange={handleEndMonthChange}
                selected={selectedEndDates}
                disableDatesBefore={dateToDisableBefore}
                disableDatesAfter={dateToDisableAfter}
              />
            </Modal.Section>
          </Modal>
          <Modal
            open={supportModalActive}
            onClose={handleSupportModalChange}
            title={localize("Warning")}
            primaryAction={{
              content: localize("Yes"),
              onAction: handleSupportModalYes,
            }}
            secondaryActions={[
              {
                content: localize("Cancel"),
                onAction: handleSupportModalChange,
              },
            ]}
          >
            <Modal.Section>
              <TextContainer>
                {localize("ByContinuingYouWillBeginToSupport")}
                <TextStyle variation="strong">{name}</TextStyle>{" "}
                {localize("WithYourTeam")}
                <TextStyle variation="strong">
                  {teamOptions
                    .filter((item) => item.value === selectedTeam)
                    .map((item) => item.label)}
                </TextStyle>
                {localize("DoYouWantToContinue")}
              </TextContainer>
            </Modal.Section>
          </Modal>
        </Layout.Section>
      </Layout>
      {errorToastMarkup}
      {dataMissingToastMarkup}
      {documentMissingToastMarkup}
      {lengthToastMarkup}
    </Page>
  );
};

export default EventNew;
