import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useContext,
} from "react"
import {
  Page,
  Card,
  Layout,
  Stack,
  Heading,
  TextContainer,
  ResourceList,
  ResourceItem,
  TextStyle,
  Icon,
  Modal,
  OptionList,
  Toast,
  Image,
  Badge,
  TextField,
  Button,
  Tooltip,
  RangeSlider,
  Spinner,
} from "@shopify/polaris"
import { useHistory, useParams } from "react-router-dom"

// icons
import {
  LocationsMinor,
  SearchMinor,
  InfoMinor,
  FilterMajor,
  CancelSmallMinor,
  GlobeMinor,
  ClipboardMinor,
  TickMinor,
  ArrowRightMinor,
  PinMinor,
} from "@shopify/polaris-icons"

// style
import "./styles.css"

// components
import InitiativeSkeleton from "./skeleton"

// api
import Api, { GraphApi } from "../../api"
import initiative from "../../api/modules/initiatives"

// localizer
import { useLocalizer } from "reactjs-localizer"

// animation
import { CSSTransition } from "react-transition-group"

// function to remove event listener
import debounce from "../../app/config/debounceFunction"

// function to reset global object
import { resetGlobalObjEvent } from "./Event/Utilities/globalObjEvent"

// plus icon
import plusIcon from "../../images/CirclePlusMinor.svg"
import plusIconBlack from "../../images/CirclePlusMinorBlack.svg"

// context
import { AuthContext } from "../../app/AuthContext"

const Initiative = () => {
  const { initiativeId } = useParams()
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const { localize } = useLocalizer()
  var userLang = navigator.language || navigator.userLanguage
  userLang = userLang.slice(0, userLang.indexOf("-"))

  const { theme } = useContext(AuthContext)

  useEffect(() => {
    resetGlobalObjEvent()
    window.scrollTo(0, 0)
  }, [])

  // REMOVE RESIZE END EVENT
  if (window.innerWidth > 450) {
    window.removeEventListener("resize", debounce)
  }

  // TUTORIAL
  const [tutorial, setTutorial] = useState(false)
  const [firstStepDone, setFirstStepDone] = useState(false)

  const handler = useCallback((e) => {
    e.stopPropagation()
    e.preventDefault()
  }, [])

  useEffect(() => {
    if (tutorial) {
      window.scrollTo(0, 0)
      if (!loading) {
        let overlayDiv = document.getElementById("overlay")
        overlayDiv.className = "overlay"
        let page = document.getElementsByClassName("Polaris-Page")[0]
        page.addEventListener("click", handler, true)
      }

      let html = document.getElementsByTagName("html")[0]
      html.style.overflow = "hidden"
    }
    //eslint-disable-next-line
  }, [loading])

  const removeListener = () => {
    let page = document.getElementsByClassName("Polaris-Page")[0]
    page.removeEventListener("click", handler, true)
  }

  // GELOCATION
  const [latitude, setLatitude] = useState(null)
  const [longitude, setLongitude] = useState(null)

  const getLocation = async () => {
    return new Promise(function (resolve, reject) {
      navigator.geolocation.getCurrentPosition(resolve, reject)
    })
  }

  // INFOS
  const [initiativeInfo, setInitiativeInfo] = useState()
  const [defaultLanguage, setDefaultLanguage] = useState()
  const [organizingTeam, setOrganizingTeam] = useState()
  const [organizingTeamDocuments, setOrganizingTeamDocuments] = useState()
  const [startsAt, setStartsAt] = useState()
  const [endsAt, setEndsAt] = useState()
  const [numberOfPartecipants, setNumberOfPartecipants] = useState()
  const [numberOfSupportingTeams, setNumberOfSupportingTeams] = useState()
  const [numberOfActionsTaken, setNumberOfActionsTaken] = useState()
  const [eventsList, setEventsList] = useState([])
  const [actionsList, setActionsList] = useState([])

  // TEAMS
  const [teamsList, setTeamsList] = useState([])
  const [teamsOptionsList, setTeamsOptionsList] = useState([])

  // LOADING ERROR TOAST
  const [loadingErrorToast, setLoadingErrorToastActive] = useState(false)

  const toggleLoadingErrorToastActive = useCallback(
    () => setLoadingErrorToastActive((loadingErrorToast) => !loadingErrorToast),
    []
  )

  const loadingErrorToastMarkup = loadingErrorToast ? (
    <Toast
      content={localize("ErrorLoadingInitiative")}
      error
      onDismiss={toggleLoadingErrorToastActive}
    />
  ) : null

  // EVENTS NEXT TOKEN
  const [eventsNextToken, setEventsNextToken] = useState("")

  // INITIATIVE OPEN
  const [isInitiativeOpen, setIsInitiativeOpen] = useState()

  // FETCH INITIATIVE
  const [currentInitiative, setCurrentInitiative] = useState([])

  const isoDateToString = (date) => {
    let months = [
      localize("JAN"),
      localize("FEB"),
      localize("MAR"),
      localize("APR"),
      localize("MAY"),
      localize("JUN"),
      localize("JUL"),
      localize("AUG"),
      localize("SEP"),
      localize("OCT"),
      localize("NOV"),
      localize("DEC"),
    ]

    let dateToPrint = date.getDate() + " " + months[date.getMonth()]

    return dateToPrint
  }

  const fetchInitiativeAndTeams = async () => {
    const initiativeCall = GraphApi.graphql({
      query: initiative.queries.getInitiatives,
      variables: { id: initiativeId },
    })
    const teamsCall = Api.teams.getMyTeams()

    var data
    try {
      data = await Promise.all([initiativeCall, teamsCall])
    } catch (e) {
      console.error(e)
      toggleLoadingErrorToastActive()
      return
    }

    // INITIATIVE
    let currentInitiativeToSet = data[0].data.getInitiatives
    setCurrentInitiative(currentInitiativeToSet)

    // events next token
    if (currentInitiativeToSet.events.nextToken) {
      setEventsNextToken(currentInitiativeToSet.events.nextToken)
    } else {
      setEventsNextToken("")
    }

    // open
    setIsInitiativeOpen(currentInitiativeToSet.open)

    // get the translation in the user or default language
    let defaultLang = currentInitiativeToSet.defaultLang
    setDefaultLanguage(defaultLang)
    localStorage.setItem("defaultLang", currentInitiativeToSet.defaultLang)
    let translations = currentInitiativeToSet.translations.items
    let done = false
    for (let i = 0; i < translations.length; i++) {
      if (translations[i].lang === userLang) {
        setInitiativeInfo(translations[i])
        done = true
      }
    }
    if (!done) {
      for (let i = 0; i < translations.length; i++) {
        if (translations[i].lang === defaultLang) {
          setInitiativeInfo(translations[i])
          done = true
        }
      }
    }

    // organizing team
    let team = JSON.parse(
      JSON.parse(currentInitiativeToSet.teamCreator.documents)
    )
    setOrganizingTeam(
      team.hasOwnProperty("en") ? team.en.heading : localize("none")
    )
    setOrganizingTeamDocuments(team)

    // start and end date
    let startDate = new Date(currentInitiativeToSet.startsAt)
    let endDate = new Date(currentInitiativeToSet.endsAt)
    let startDateToPrint = isoDateToString(startDate)
    let endDateToPrint = isoDateToString(endDate)
    setStartsAt(startDateToPrint)
    setEndsAt(endDateToPrint)

    // number of partecipants
    setNumberOfPartecipants(
      currentInitiativeToSet.participantCounter
        ? currentInitiativeToSet.participantCounter.count
        : localize("NotAvailable")
    )

    // number of supporting teams
    setNumberOfSupportingTeams(
      currentInitiativeToSet.supportingTeamCounter
        ? currentInitiativeToSet.supportingTeamCounter.count
        : localize("NotAvailable")
    )

    // number of actions taken
    setNumberOfActionsTaken(
      currentInitiativeToSet.actionCounter
        ? currentInitiativeToSet.actionCounter.count
        : localize("NotAvailable")
    )

    // events list
    setEventsList(currentInitiativeToSet.events.items)

    // actions list
    setActionsList(currentInitiativeToSet.actions.items)
    localStorage.setItem(
      "actionsList",
      JSON.stringify(currentInitiativeToSet.actions.items)
    )

    // TEAMS
    let list = data[1]
    setTeamsList(list)

    if (list.length > 1) {
      var ordered = false

      while (ordered === false) {
        for (let i = 0; i < list.length - 1; i++) {
          ordered = true

          if (list[i + 1].id < list[i].id) {
            let temp = list[i + 1]
            list[i + 1] = list[i]
            list[i] = temp

            ordered = false
          }
        }
      }
    }

    // array of options for modal
    let options = []

    for (let i = 0; i < list.length; i++) {
      let choiceToAdd = {
        value: list[i].id,
        label: list[i].name,
      }

      options.push(choiceToAdd)
    }

    setTeamsOptionsList(options)

    // LOCAL STORAGE
    let itemToSet = {
      name: currentInitiativeToSet.name,
      startDate: startDate,
      endDate: endDate,
      defaultLang: defaultLang,
      image: currentInitiativeToSet.translations.items[0].image,
    }

    localStorage.setItem("initiativeData", JSON.stringify(itemToSet))

    setLoading(false)
  }

  useEffect(() => {
    fetchInitiativeAndTeams()
    //eslint-disable-next-line
  }, [])

  // FETCH MORE EVENTS
  const [fetchMoreEventsLoading, setFetchMoreEventsLoading] = useState(false)

  const fetchMoreEvents = async () => {
    setFetchMoreEventsLoading(true)

    const { data } = await GraphApi.graphql({
      query: `query GetInitiatives {
        getInitiatives(id: "${initiativeId}") {
          events(limit: 10, nextToken: "${eventsNextToken}") {
            items {
              id
              name
              startsAt
              endsAt
              city
            }
            nextToken
          }
        }
      }`,
    })

    let currentData = data.getInitiatives

    if (currentData.events.nextToken) {
      setEventsNextToken(currentData.events.nextToken)
    } else {
      setEventsNextToken("")
    }

    setEventsList([...eventsList, ...currentData.events.items])

    setFetchMoreEventsLoading(false)
  }

  // SUPPORTING LOADING
  const [supportingLoading, setSupportingLoading] = useState(false)

  // ACTIONS CARDS
  const ActionsCards = () => {
    let cards = []

    for (let i = 0; i < actionsList.length; i++) {
      let action = actionsList[i].action.translations.items
      let actionInUserLang = action.filter((value) => value.lang === userLang)
      if (actionInUserLang.length === 0) {
        actionInUserLang = action.filter(
          (value) => value.lang === defaultLanguage
        )
      }
      actionInUserLang = actionInUserLang[0]

      cards.push(
        <Card sectioned key={actionInUserLang.id}>
          <Stack alignment="center" spacing="loose" wrap={false}>
            <div className="img-container">
              <Image
                source={actionInUserLang.image}
                alt="Action"
                style={{
                  height: "40px",
                  borderRadius: 5,
                }}
              />
            </div>
            <p style={{ paddingLeft: "10px", maxWidth: "65%" }}>
              {actionInUserLang.title.includes("Task")
                ? actionInUserLang.title.slice(
                    actionInUserLang.title.indexOf("-") + 2
                  )
                : actionInUserLang.title}
            </p>
          </Stack>
        </Card>
      )
    }

    if (cards.length === 0) {
      cards.push(<p key={897}>{localize("NoActions")}</p>)
    }

    return cards
  }

  //TOAST
  const [toastActive, setToastActive] = useState(false)

  const toggleToastActive = useCallback(
    () => setToastActive((toastActive) => !toastActive),
    []
  )

  const showToast = useCallback(() => setToastActive(true), [])

  const toastMarkup = toastActive ? (
    <Toast
      content={localize("InitiativeSupported")}
      onDismiss={toggleToastActive}
    />
  ) : null

  //TOAST FOR INVITE
  const [inviteToastActive, setInviteToastActive] = useState(false)

  const toggleInviteToastActive = useCallback(
    () => setInviteToastActive((inviteToastActive) => !inviteToastActive),
    []
  )

  const inviteToastMarkup = inviteToastActive ? (
    <Toast
      content={localize("MembersInvited")}
      onDismiss={toggleInviteToastActive}
    />
  ) : null

  // OPTION LIST
  const [selectedOption, setSelectedOption] = useState([])

  // CHECK SUPPORT
  const [supportingTeamsList, setSupportingTeamsList] = useState([])
  const [isSupported, setIsSupported] = useState(false)
  const [teamThatSupports, setTeamThatSupports] = useState("")

  const checkSupport = () => {
    let supportingArray = JSON.parse(localStorage.getItem("supportingArray"))
    let teamsThatSupportsThisInitiative = []

    if (!supportingArray) {
      setIsSupported(false)
      return
    }
    let teamThatSupportsCounter = 0
    for (let i = 0; i < supportingArray.length; i++) {
      if (supportingArray[i].includes(currentInitiative.id)) {
        teamsThatSupportsThisInitiative.push(supportingArray[i])

        let teamName = supportingArray[i].slice(
          supportingArray[i].lastIndexOf(",") + 1,
          supportingArray[i].indexOf("%")
        )
        setTeamThatSupports(teamName)
        teamThatSupportsCounter++

        setIsSupported(true)
      }
    }

    setSupportingTeamsList(teamsThatSupportsThisInitiative)

    if (teamThatSupportsCounter > 1) {
      setTeamThatSupports(teamThatSupportsCounter + " " + localize("Teams"))
    }
  }

  useEffect(() => {
    if (!loading) {
      checkSupport()
    }
    //eslint-disable-next-line
  }, [loading])

  // UPDATE SUPPORT OPTIONS
  const updateOptions = () => {
    let newTeamsList = teamsList
    for (let i = 0; i < supportingTeamsList.length; i++) {
      let supportingTeam = supportingTeamsList[i].slice(
        0,
        supportingTeamsList[i].indexOf(",")
      )
      newTeamsList = newTeamsList.filter(
        (l) => l.id.toString() !== supportingTeam
      )
    }
    setTeamsList(newTeamsList)

    let options = []
    for (let i = 0; i < newTeamsList.length; i++) {
      let choiceToAdd = {
        value: newTeamsList[i].id,
        label: newTeamsList[i].name,
      }

      options.push(choiceToAdd)
    }
    setTeamsOptionsList(options)
    if (options.length > 0) {
      setSelectedOption([options[0].value])
    }
  }

  // UPDATE COUNTERS
  const updateCounters = async () => {
    const { data } = await GraphApi.graphql({
      query: initiative.queries.getInitiativesCounters,
      variables: { id: initiativeId },
    })

    setNumberOfPartecipants(data.getInitiatives.participantCounter.count)
    setNumberOfSupportingTeams(data.getInitiatives.supportingTeamCounter.count)
    setNumberOfActionsTaken(data.getInitiatives.actionCounter.count)
  }

  // ERROR WHILE SUPPORTING TOAST
  const [errorToastActive, setErrorToastActive] = useState(false)

  const toggleErrorToastActive = useCallback(
    () => setErrorToastActive((errorToastActive) => !errorToastActive),
    []
  )

  const errorToastMarkup = errorToastActive ? (
    <Toast
      content={localize("SomethingWentWrong")}
      error
      onDismiss={toggleErrorToastActive}
    />
  ) : null

  // MODAL TO SUPPORT
  const [supportModalActive, setSupportModalActive] = useState(false)

  const handleModalChange = useCallback(() => {
    setSupportModalActive(!supportModalActive)
  }, [supportModalActive])

  const handleModalSupport = useCallback(async () => {
    setSupportingLoading(true)

    let teamId = selectedOption[0].toString()
    let initiativesId = currentInitiative.id

    try {
      await GraphApi.graphql({
        query: initiative.mutations.supportInitiative,
        variables: { teamId: teamId, initiativesId: initiativesId },
      })
    } catch (e) {
      console.error(e)
      toggleErrorToastActive()
      setSupportingLoading(false)
      return
    }

    setSupportingLoading(false)

    setSupportModalActive(!supportModalActive)
    showToast()
    setIsSupported(true)
    setTeamThatSupports(
      supportingTeamsList.length > 0
        ? supportingTeamsList.length + 1 + " teams"
        : teamsOptionsList.map((l) => {
            if (l.value === selectedOption[0]) {
              return l.label
            }
            return null
          })
    )
    let newSupportingArray = supportingTeamsList
    let currentTeam = teamsList.filter((l) => l.id.toString() === teamId)
    newSupportingArray.push(
      currentTeam[0].id +
        "," +
        currentInitiative.id +
        "," +
        currentTeam[0].name +
        "%" +
        currentTeam[0].uid
    )
    localStorage.setItem("supportingArray", JSON.stringify(newSupportingArray))
    checkSupport()
    setTimeout(updateCounters, 2000)
    //eslint-disable-next-line
  }, [
    supportModalActive,
    showToast,
    selectedOption,
    checkSupport,
    updateCounters,
    toggleErrorToastActive,
  ])

  // FILTER BY PROXIMITY
  const [showFilterPanel, setShowFilterPanel] = useState(false)
  const [filterApplied, setFilterApplied] = useState(false)
  const [km, setKm] = useState()
  const [locationDenied, setLocationDenied] = useState(false)

  useEffect(() => {
    if (document.getElementById("animated-filter-panel")) {
      let div = document.getElementById("animated-filter-panel")

      if (showFilterPanel) {
        div.className = "grow"
      } else {
        div.className = "normal"
      }
    }
  }, [showFilterPanel])

  // PROXIMITY KM SLIDER
  const [rangeValue, setRangeValue] = useState(200)

  const handleRangeSliderChange = useCallback(
    (value) => setRangeValue(value),
    []
  )

  // FILTERED EVENTS QUERY
  const [eventsLoading, setEventsLoading] = useState(false)
  const [locationLoading, setLocationLoading] = useState(false)
  const [eventsListFiltered, setEventsListFiltered] = useState([])

  const fetchEvents = async () => {
    setEventsLoading(true)

    const { data } = await GraphApi.graphql({
      query: initiative.queries.nearbyEvents,
      variables: {
        initiativeId: initiativeId,
        km: parseFloat(km),
        location: { lat: latitude, lon: longitude },
      },
    })

    if (data.nearbyEvents) {
      setEventsListFiltered(data.nearbyEvents.items)
    }

    setEventsLoading(false)
  }

  useEffect(() => {
    if (filterApplied) {
      fetchEvents()
    } else {
      setEventsListFiltered([])
    }
    //eslint-disable-next-line
  }, [filterApplied, km])

  // EVENTS FILTER
  const [filterValue, setFilterValue] = useState("")
  const filterValueChange = useCallback((value) => setFilterValue(value), [])

  const eventsListMemo = useMemo(() => {
    if (eventsListFiltered.length === 0 && !filterApplied) {
      if (filterValue === "") {
        return eventsList
      }

      return eventsList.filter((l) =>
        l.city.toLowerCase().includes(filterValue.toLowerCase())
      )
    } else {
      if (filterValue === "") {
        return eventsListFiltered
      }

      return eventsListFiltered.filter((l) =>
        l.city.toLowerCase().includes(filterValue.toLowerCase())
      )
    }
    //eslint-disable-next-line
  }, [filterValue, eventsList, eventsListFiltered])

  // INVITE
  const [selectedTeamForInvite, setSelectedTeamForInvite] = useState([])
  const [optionsForInvite, setOptionsForInvite] = useState([])

  useEffect(() => {
    if (supportingTeamsList.length !== 0) {
      let options = []

      for (let i = 0; i < supportingTeamsList.length; i++) {
        options.push({
          value: supportingTeamsList[i].slice(
            supportingTeamsList[i].lastIndexOf("%") + 1
          ),
          label: supportingTeamsList[i].slice(
            supportingTeamsList[i].lastIndexOf(",") + 1,
            supportingTeamsList[i].indexOf("%")
          ),
        })
      }

      setOptionsForInvite(options)
    }
  }, [supportingTeamsList])

  const [inviteLink, setInviteLink] = useState("")
  const [generateLinkButtonDisabled, setGenerateLinkButtonDisabled] =
    useState(true)

  const generateInviteLink = () => {
    let lang = userLang
    if (lang !== "it" && lang !== "en") {
      lang = "en"
    }
    let link = `https://${
      process.env.REACT_APP_SHARING_URL
    }/i/${initiativeId}/team/${selectedTeamForInvite[0]}/${lang}?refid=${
      JSON.parse(localStorage.getItem("currentUser")).uid
    }`
    setInviteLink(link)
  }

  const generateInviteLinkForMainPage = (uid) => {
    let lang = userLang
    if (lang !== "it" && lang !== "en") {
      lang = "en"
    }
    let link = `https://${
      process.env.REACT_APP_SHARING_URL
    }/i/${initiativeId}/team/${uid}/${lang}?refid=${
      JSON.parse(localStorage.getItem("currentUser")).uid
    }`
    return link
  }

  useEffect(() => {
    if (selectedTeamForInvite.length === 0) {
      setGenerateLinkButtonDisabled(true)
    } else {
      setGenerateLinkButtonDisabled(false)
    }
  }, [selectedTeamForInvite])

  const [inviteModalActive, setInviteModalActive] = useState(false)

  const handleInviteModalChange = useCallback(() => {
    setInviteLink("")
    setSelectedTeamForInvite([])
    setInviteModalActive(!inviteModalActive)
  }, [inviteModalActive])

  // FUNCTION TO COPY INVITE LINK IN CLIPBOARD
  const [clipboardToastActive, setClipboardToastActive] = useState(false)

  const toggleClipboardToast = useCallback(
    () =>
      setClipboardToastActive((clipboardToastActive) => !clipboardToastActive),
    []
  )

  const toggleClipboardToastActive = useCallback(
    () => setClipboardToastActive(true),
    []
  )

  const clipboardToastMarkup = clipboardToastActive ? (
    <Toast
      content={localize("CopiedToClipboard")}
      onDismiss={toggleClipboardToast}
    />
  ) : null

  const copyToClipboard = () => {
    var copyText = document.getElementById("access-link")

    copyText.select()
    copyText.setSelectionRange(0, 99999)

    document.execCommand("copy")

    toggleClipboardToastActive()
  }

  const copyToClipboardForInvite = (id) => {
    var copyText = document.getElementById("access-link-" + id)

    copyText.select()
    copyText.setSelectionRange(0, 99999)

    document.execCommand("copy")

    toggleClipboardToastActive()
  }

  return (
    <>
      {loading ? (
        <InitiativeSkeleton />
      ) : (
        <div style={{ position: "relative", height: "88vh" }}>
          {tutorial && (
            <CSSTransition
              in={true}
              appear={true}
              timeout={1000}
              classNames="transform"
            >
              <div
                id="tutorial-banner"
                style={{
                  backgroundColor: "#ebf9fc",
                  border: "solid 1px #97c6cd",
                  width: "70%",
                  maxWidth: 934,
                  height: 160,
                  margin: "auto",
                  position: "absolute",
                  zIndex: 9999,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  borderRadius: 10,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Stack vertical alignment="center">
                  <div
                    style={{
                      maxWidth: 450,
                      textAlign: "center",
                      paddingLeft: 5,
                      paddingRight: 5,
                    }}
                  >
                    <p style={{ fontSize: 15, fontWeight: "600" }}>
                      {firstStepDone
                        ? `Dopo averla supportata, premi il tasto "Aggiungi evento" per aggiungerne uno all'iniziativa`
                        : `Usa il tasto "Supporta" in alto a destra per supportare
                      l'iniziativa con uno dei tuoi team`}
                    </p>
                  </div>
                  <div style={{ cursor: "pointer" }}>
                    <Button
                      primary
                      onClick={
                        firstStepDone
                          ? () => {
                              window.scrollTo(0, 0)
                              let overlay = document.getElementById("overlay")
                              overlay.className = ""
                              let html =
                                document.getElementsByTagName("html")[0]
                              html.removeAttribute("style")
                              let tutorialBanner =
                                document.getElementById("tutorial-banner")
                              tutorialBanner.style.display = "none"
                              removeListener()
                              setTutorial(false)
                            }
                          : () => {
                              let html =
                                document.getElementsByTagName("html")[0]
                              html.style.scrollBehavior = "smooth"
                              let tutorialBanner =
                                document.getElementById("tutorial-banner")
                              tutorialBanner.style.bottom = "-750px"
                              setFirstStepDone(true)
                              window.scrollTo(0, 750)
                            }
                      }
                    >
                      <Stack alignment="center">
                        <TextContainer>{localize("Next")}</TextContainer>
                        <Icon source={ArrowRightMinor} />
                      </Stack>
                    </Button>
                  </div>
                </Stack>
              </div>
            </CSSTransition>
          )}
          <Page
            title={
              <Stack>
                <TextContainer>{currentInitiative.name}</TextContainer>
                {isSupported ? (
                  supportingTeamsList.length < 2 ? (
                    <div style={{ marginTop: -1 }}>
                      {window.innerWidth < 376 ? (
                        <Badge status="success">
                          {localize("SuppWith")} {teamThatSupports}
                        </Badge>
                      ) : (
                        <Badge status="success">
                          {localize("SupportingWith")} {teamThatSupports}
                        </Badge>
                      )}
                    </div>
                  ) : (
                    <div
                      style={{
                        marginTop: -1,
                        cursor: "default",
                      }}
                    >
                      <Tooltip
                        content={
                          <ResourceList
                            items={supportingTeamsList}
                            renderItem={(item) => {
                              return (
                                <ResourceItem
                                  id={item.slice(0, item.indexOf(","))}
                                >
                                  <p
                                    style={{
                                      fontSize: 13,
                                    }}
                                  >
                                    {item.slice(
                                      item.lastIndexOf(",") + 1,
                                      item.indexOf("%")
                                    )}
                                  </p>
                                </ResourceItem>
                              )
                            }}
                          />
                        }
                      >
                        {window.innerWidth < 376 ? (
                          <Badge status="success">
                            {localize("SuppWith")} {teamThatSupports}
                          </Badge>
                        ) : (
                          <Badge status="success">
                            {localize("SupportingWith")} {teamThatSupports}
                          </Badge>
                        )}
                      </Tooltip>
                    </div>
                  )
                ) : (
                  <div style={{ marginTop: -1 }}>
                    <Badge>{localize("NotSupporting")}</Badge>
                  </div>
                )}
              </Stack>
            }
            breadcrumbs={[{ content: "Overview", url: "/dashboard" }]}
            primaryAction={{
              content: (
                <div id="support" style={{ transition: "800ms" }}>
                  {localize("Support")}
                </div>
              ),
              onAction: () => {
                updateOptions()
                handleModalChange()
              },
            }}
            // secondaryActions={
            //   isSupported && [
            //     {
            //       content: localize("Invite"),
            //       onAction: handleInviteModalChange,
            //     },
            //   ]
            // }
          >
            <div id="overlay">
              <Layout>
                <Layout.Section>
                  <Card>
                    <Card.Section flush>
                      <img
                        src={initiativeInfo.image}
                        alt="initiative"
                        className="initiative-image"
                      />
                    </Card.Section>
                    <Card.Section subdued>
                      <Stack vertical>
                        <div style={{ paddingTop: 6 }}>
                          <Heading>{localize("WhatsThisAllAbout")}</Heading>
                        </div>
                        <TextContainer>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: initiativeInfo.whatDescription,
                            }}
                          />
                        </TextContainer>
                        <Heading>{localize("HowCanIGetInvolved")}</Heading>
                        <TextContainer>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: initiativeInfo.howDescription,
                            }}
                          />
                        </TextContainer>
                        <Heading>{localize("WhenCanITakePart")}</Heading>
                        <TextContainer>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: initiativeInfo.whenDescription,
                            }}
                          />
                        </TextContainer>
                      </Stack>
                    </Card.Section>
                  </Card>
                  <Card
                    title={localize("Events")}
                    sectioned
                    actions={
                      isInitiativeOpen && [
                        {
                          content: (
                            <div id="add-location">
                              <Button
                                onClick={() =>
                                  history.push(
                                    `/dashboard/initiatives/${currentInitiative.id}/addevent`
                                  )
                                }
                                primary
                                disabled={
                                  JSON.parse(localStorage.getItem("apiCall"))
                                    .length === 0
                                    ? true
                                    : false
                                }
                              >
                                <Stack alignment="center" spacing="tight">
                                  <div
                                    style={
                                      JSON.parse(
                                        localStorage.getItem("apiCall")
                                      ).length === 0
                                        ? {
                                            width: 20,
                                            height: 20,
                                            background: `url(${plusIconBlack}) no-repeat scroll -2px`,
                                          }
                                        : {
                                            width: 20,
                                            height: 20,
                                            background: `url(${plusIcon}) no-repeat scroll -2px`,
                                          }
                                    }
                                  />
                                  <TextContainer>
                                    <div id="add-event-text">
                                      {localize("AddEvent")}
                                    </div>
                                  </TextContainer>
                                </Stack>
                              </Button>
                            </div>
                          ),
                        },
                      ]
                    }
                  >
                    <div id="overlay-2">
                      <Stack vertical spacing="loose">
                        <Card>
                          <ResourceList
                            loading={eventsLoading}
                            emptySearchState={
                              <div
                                style={{
                                  paddingTop: 45,
                                  paddingBottom: 45,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <Stack vertical alignment="center">
                                  <img
                                    src="data:image/svg+xml,%3csvg width='60' height='60' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M41.87 24a17.87 17.87 0 11-35.74 0 17.87 17.87 0 0135.74 0zm-3.15 18.96a24 24 0 114.24-4.24L59.04 54.8a3 3 0 11-4.24 4.24L38.72 42.96z' fill='%238C9196'/%3e%3c/svg%3e"
                                    alt=""
                                    width={50}
                                    height={50}
                                  />
                                  <p
                                    style={{
                                      color: "#636363",
                                      transform: "scale(1.1)",
                                    }}
                                  >
                                    {localize("NoEventsFound")}
                                  </p>
                                </Stack>
                              </div>
                            }
                            filterControl={
                              <>
                                <div
                                  style={{
                                    marginBottom: 14,
                                  }}
                                >
                                  <Stack vertical>
                                    <Stack>
                                      <Stack.Item fill>
                                        <TextField
                                          value={filterValue}
                                          onChange={filterValueChange}
                                          disabled={eventsLoading}
                                          clearButton
                                          onClearButtonClick={() => {
                                            setFilterValue("")
                                          }}
                                          placeholder={localize("Search")}
                                          prefix={<Icon source={SearchMinor} />}
                                          suffix={
                                            filterApplied &&
                                            `${localize("Within")} ${km}km`
                                          }
                                        />
                                      </Stack.Item>
                                      <Stack.Item
                                        fill={
                                          window.innerWidth < 551 ? true : false
                                        }
                                      >
                                        <Button
                                          disabled={eventsLoading}
                                          fullWidth
                                          onClick={async () => {
                                            if (filterApplied) {
                                              setFilterApplied(false)
                                              setShowFilterPanel(false)
                                              setTimeout(() => {
                                                setRangeValue(200)
                                              }, 500)
                                            } else {
                                              setLocationDenied(false)
                                              setShowFilterPanel(
                                                !showFilterPanel
                                              )
                                              if (
                                                latitude === null &&
                                                longitude === null
                                              ) {
                                                setLocationLoading(true)
                                                var position
                                                try {
                                                  position = await getLocation()
                                                  setLocationDenied(false)
                                                } catch {
                                                  setLocationDenied(true)
                                                  return
                                                }
                                                setLatitude(
                                                  position.coords.latitude
                                                )
                                                setLongitude(
                                                  position.coords.longitude
                                                )
                                                setLocationLoading(false)
                                              }
                                            }
                                          }}
                                        >
                                          <Stack
                                            alignment="center"
                                            spacing="tight"
                                          >
                                            <Icon
                                              source={
                                                filterApplied
                                                  ? CancelSmallMinor
                                                  : FilterMajor
                                              }
                                              color="base"
                                            />
                                            <TextContainer>
                                              {!filterApplied
                                                ? localize("FilterByProximity")
                                                : localize("RemoveFilter")}
                                            </TextContainer>
                                          </Stack>
                                        </Button>
                                      </Stack.Item>
                                    </Stack>
                                  </Stack>
                                </div>
                                <div
                                  id="animated-filter-panel"
                                  className="normal"
                                >
                                  <Stack
                                    vertical
                                    alignment={locationLoading && "center"}
                                  >
                                    <Stack.Item></Stack.Item>
                                    {locationDenied ? (
                                      <TextContainer>
                                        {localize("UnableToLoadPosition")}
                                      </TextContainer>
                                    ) : locationLoading ? (
                                      <Stack>
                                        <TextContainer>
                                          {localize("WaitingForPosition")}
                                        </TextContainer>
                                        <Spinner size="small" />
                                      </Stack>
                                    ) : (
                                      <Stack.Item>
                                        <Stack alignment="center">
                                          <Stack.Item fill>
                                            <RangeSlider
                                              output
                                              min={50}
                                              max={1000}
                                              value={rangeValue}
                                              onChange={handleRangeSliderChange}
                                            />
                                          </Stack.Item>
                                          <TextStyle variation="strong">
                                            {`${rangeValue}km`}
                                          </TextStyle>
                                          <Button
                                            disabled={eventsLoading}
                                            size="slim"
                                            onClick={() => {
                                              setKm(rangeValue)
                                              setFilterApplied(true)
                                            }}
                                          >
                                            {localize("Apply")}
                                          </Button>
                                        </Stack>
                                      </Stack.Item>
                                    )}
                                  </Stack>
                                </div>
                              </>
                            }
                            items={eventsListMemo}
                            renderItem={(item) => {
                              const {
                                id,
                                startsAt,
                                endsAt,
                                distance,
                                translations,
                                city,
                              } = item

                              let startDate = new Date(startsAt)
                              let endDate = new Date(endsAt)

                              // start and end dates
                              let startDateToPrint = isoDateToString(startDate)
                              let endDateToPrint = isoDateToString(endDate)
                              let dateToPrint = ""
                              if (startDateToPrint === endDateToPrint) {
                                dateToPrint = startDateToPrint
                              }

                              // start and end hours
                              let startHourToPrint =
                                (startDate.getHours().toString().length === 1
                                  ? "0" + startDate.getHours()
                                  : startDate.getHours()) +
                                ":" +
                                (startDate.getMinutes().toString().length === 1
                                  ? "0" + startDate.getMinutes()
                                  : startDate.getMinutes())
                              let endHourToPrint =
                                (endDate.getHours().toString().length === 1
                                  ? "0" + endDate.getHours()
                                  : endDate.getHours()) +
                                ":" +
                                (endDate.getMinutes().toString().length === 1
                                  ? "0" + endDate.getMinutes()
                                  : endDate.getMinutes())

                              let finalStartString
                              let finalEndString
                              if (dateToPrint === "") {
                                finalStartString = `${
                                  localize("From") + startDateToPrint
                                } ${localize("At")} ${startHourToPrint}`
                                finalEndString = `${
                                  localize("To") + endDateToPrint
                                } ${localize("At")} ${endHourToPrint}`
                              } else {
                                finalStartString = `${dateToPrint}  ${localize(
                                  "FromHour"
                                )}${startHourToPrint}`
                                finalEndString = `${localize(
                                  "At"
                                )} ${endHourToPrint}`
                              }

                              // event title
                              let items = translations.items
                              let userLangInfos = items.filter(
                                (i) => i.lang === userLang
                              )
                              if (userLangInfos.length < 1) {
                                userLangInfos = items.filter(
                                  (i) => i.lang === defaultLanguage
                                )
                                userLangInfos = userLangInfos[0]
                              } else {
                                userLangInfos = userLangInfos[0]
                              }
                              let title = userLangInfos.title

                              return (
                                <ResourceItem
                                  id={id}
                                  url={`/dashboard/initiatives/${initiativeId}/${id}`}
                                >
                                  {window.innerWidth < 551 ? (
                                    <Stack
                                      distribution="fillEvenly"
                                      vertical
                                      spacing="tight"
                                    >
                                      <Stack alignment="center" spacing="tight">
                                        <Icon source={PinMinor} color="base" />
                                        <h3>
                                          <TextStyle variation="strong">
                                            {title.length > 32
                                              ? title.slice(0, 32) + "..."
                                              : title}
                                          </TextStyle>
                                        </h3>
                                      </Stack>
                                      <Stack alignment="center" spacing="tight">
                                        <Icon
                                          source={LocationsMinor}
                                          color="base"
                                        />
                                        <h3>
                                          <TextStyle variation="strong">
                                            {city.length > 32
                                              ? city.slice(0, 32) + "..."
                                              : city}
                                          </TextStyle>
                                        </h3>
                                      </Stack>
                                      {distance !== undefined && (
                                        <Stack
                                          alignment="center"
                                          spacing="tight"
                                        >
                                          <Icon
                                            source={GlobeMinor}
                                            color="base"
                                          />
                                          <h3>
                                            <TextStyle variation="strong">
                                              {Math.round(distance * 10) / 10 +
                                                "km"}
                                            </TextStyle>
                                          </h3>
                                        </Stack>
                                      )}
                                      <Stack alignment="center" spacing="tight">
                                        <Icon source={TickMinor} color="base" />
                                        <div>{finalStartString}</div>
                                      </Stack>
                                      <Stack alignment="center" spacing="tight">
                                        <Icon
                                          source={CancelSmallMinor}
                                          color="base"
                                        />
                                        <div>{finalEndString}</div>
                                      </Stack>
                                    </Stack>
                                  ) : (
                                    <Stack
                                      distribution="fillEvenly"
                                      vertical
                                      spacing="tight"
                                    >
                                      <Stack alignment="center" spacing="tight">
                                        <Icon source={PinMinor} color="base" />
                                        <h3>
                                          <TextStyle variation="strong">
                                            {title.length > 60
                                              ? title.slice(0, 60) + "..."
                                              : title}
                                          </TextStyle>
                                        </h3>
                                      </Stack>
                                      <Stack distribution="fillEvenly">
                                        <Stack
                                          alignment="center"
                                          spacing="tight"
                                        >
                                          <Icon
                                            source={LocationsMinor}
                                            color="base"
                                          />
                                          <h3>
                                            <TextStyle variation="strong">
                                              {city.length > 28 &&
                                              distance !== undefined
                                                ? city.slice(0, 28) + "..."
                                                : city.length > 60
                                                ? city.slice(0, 60) + "..."
                                                : city}
                                            </TextStyle>
                                          </h3>
                                        </Stack>
                                        {distance !== undefined && (
                                          <Stack
                                            alignment="center"
                                            spacing="tight"
                                          >
                                            <Icon
                                              source={GlobeMinor}
                                              color="base"
                                            />
                                            <h3>
                                              <TextStyle variation="strong">
                                                {Math.round(distance * 10) /
                                                  10 +
                                                  "km"}
                                              </TextStyle>
                                            </h3>
                                          </Stack>
                                        )}
                                      </Stack>
                                      <Stack distribution="fillEvenly">
                                        <Stack
                                          alignment="center"
                                          spacing="tight"
                                        >
                                          <Icon
                                            source={TickMinor}
                                            color="base"
                                          />
                                          <div>{finalStartString}</div>
                                        </Stack>
                                        <Stack
                                          alignment="center"
                                          spacing="tight"
                                        >
                                          <Icon
                                            source={CancelSmallMinor}
                                            color="base"
                                          />
                                          <div>{finalEndString}</div>
                                        </Stack>
                                      </Stack>
                                    </Stack>
                                  )}
                                </ResourceItem>
                              )
                            }}
                          />
                        </Card>
                        {eventsNextToken && !filterApplied && (
                          <Button
                            fullWidth
                            primary
                            onClick={fetchMoreEvents}
                            loading={fetchMoreEventsLoading}
                          >
                            {localize("LoadMoreEvents")}
                          </Button>
                        )}
                      </Stack>
                    </div>
                  </Card>
                </Layout.Section>
                <Layout.Section secondary>
                  <Card title={localize("GeneralInformation")} sectioned>
                    <Stack vertical spacing="extraTight">
                      <TextContainer>
                        {localize("Duration")}: {localize("From")}{" "}
                        <TextStyle variation="strong">{startsAt}</TextStyle>{" "}
                        {localize("To")}{" "}
                        <TextStyle variation="strong">{endsAt}</TextStyle>
                      </TextContainer>
                      <TextContainer>
                        <Stack spacing="extraTight" vertical>
                          <TextContainer>
                            {localize("OrganizingTeam")}:{" "}
                          </TextContainer>
                          <Stack spacing="tight">
                            <TextStyle variation="strong">
                              {organizingTeam}
                            </TextStyle>
                            {organizingTeam !== "none" ? (
                              <Tooltip
                                content={
                                  organizingTeamDocuments.hasOwnProperty(
                                    userLang
                                  ) ? (
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: organizingTeamDocuments[
                                          userLang
                                        ].body[0].content.slice(
                                          0,
                                          organizingTeamDocuments[
                                            userLang
                                          ].body[0].content.indexOf(".") > 0
                                            ? organizingTeamDocuments[
                                                userLang
                                              ].body[0].content.indexOf(".") + 1
                                            : -1
                                        ),
                                      }}
                                    />
                                  ) : (
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          organizingTeamDocuments.en.body[0].content.slice(
                                            0,
                                            organizingTeamDocuments.en.body[0].content.indexOf(
                                              "."
                                            ) > 0
                                              ? organizingTeamDocuments.en.body[0].content.indexOf(
                                                  "."
                                                ) + 1
                                              : -1
                                          ),
                                      }}
                                    />
                                  )
                                }
                              >
                                <div
                                  style={{
                                    cursor: "pointer",
                                  }}
                                >
                                  <Icon source={InfoMinor} color="white" />
                                </div>
                              </Tooltip>
                            ) : null}
                          </Stack>
                        </Stack>
                      </TextContainer>
                      <TextContainer>
                        {localize("PeoplePartecipating")}:{" "}
                        <TextStyle variation="strong">
                          {numberOfPartecipants}
                        </TextStyle>
                      </TextContainer>
                      <TextContainer>
                        {localize("TeamsSupporting")}:{" "}
                        <TextStyle variation="strong">
                          {numberOfSupportingTeams}
                        </TextStyle>
                      </TextContainer>
                      <TextContainer>
                        {localize("ActionsTaken")}:{" "}
                        <TextStyle variation="strong">
                          {numberOfActionsTaken}
                        </TextStyle>
                      </TextContainer>
                      <TextContainer>
                        {localize("NumberOfEvents")}:{" "}
                        <TextStyle variation="strong">
                          {eventsList.length}
                        </TextStyle>
                      </TextContainer>
                    </Stack>
                  </Card>
                  {supportingTeamsList.length > 0 && (
                    <Card title={localize("AccessLink")} sectioned>
                      <Stack vertical spacing="extraTight">
                        {supportingTeamsList.map((item) => {
                          return (
                            <TextContainer>
                              {item.slice(
                                item.lastIndexOf(",") + 1,
                                item.indexOf("%")
                              )}
                              :{" "}
                              <Stack spacing="tight">
                                <input
                                  className={
                                    theme.colorScheme === "dark" && "input-dark"
                                  }
                                  readOnly
                                  id={`access-link-${item.slice(
                                    0,
                                    item.indexOf(",")
                                  )}`}
                                  type="text"
                                  value={generateInviteLinkForMainPage(
                                    item.slice(item.indexOf("%") + 1)
                                  )}
                                  style={
                                    window.innerWidth < 804
                                      ? {
                                          fontWeight: "bold",
                                          fontSize: 14,
                                          border: "none",
                                          cursor: "default",
                                          width: "34vh",
                                          maxWidth: 500,
                                        }
                                      : {
                                          fontWeight: "bold",
                                          fontSize: 14,
                                          border: "none",
                                          cursor: "default",
                                          width: "20vw",
                                          maxWidth: 235,
                                        }
                                  }
                                />
                                <div
                                  title={localize("CopyToClipboard")}
                                  onClick={() => {
                                    copyToClipboardForInvite(
                                      item.slice(0, item.indexOf(","))
                                    )
                                  }}
                                  style={{
                                    cursor: "pointer",
                                  }}
                                >
                                  <Icon
                                    source={ClipboardMinor}
                                    color="inkLight"
                                  />
                                </div>
                              </Stack>
                            </TextContainer>
                          )
                        })}
                      </Stack>
                    </Card>
                  )}
                  <Card title={localize("ActionsOfTheInitiative")} sectioned>
                    <Stack vertical>
                      <ActionsCards />
                    </Stack>
                  </Card>
                </Layout.Section>
              </Layout>
            </div>
            <Modal
              open={supportModalActive}
              onClose={handleModalChange}
              title={localize("Support") + " " + currentInitiative.name}
              primaryAction={
                teamsList.length === 0
                  ? null
                  : {
                      content: localize("Support"),
                      onAction: handleModalSupport,
                      loading: supportingLoading,
                      disabled: selectedOption.length === 0 ? true : false,
                    }
              }
              secondaryActions={[
                {
                  content: localize("Cancel"),
                  onAction: handleModalChange,
                },
              ]}
            >
              <Modal.Section>
                {teamsList.length === 0 ? (
                  supportingTeamsList.length > 0 ? (
                    <TextContainer>
                      {localize("AlreadySupportedTheInitiative")}
                    </TextContainer>
                  ) : (
                    <TextContainer>{localize("AtLeastOneTeam")}</TextContainer>
                  )
                ) : (
                  <Stack vertical>
                    <TextContainer>
                      {localize("SelectTheTeamToSupport")}
                    </TextContainer>
                    <Card>
                      <OptionList
                        selected={selectedOption}
                        onChange={setSelectedOption}
                        options={teamsOptionsList}
                      />
                    </Card>
                    <TextStyle variation="strong">
                      {localize("NoteThat")}
                    </TextStyle>
                  </Stack>
                )}
              </Modal.Section>
            </Modal>
            <Modal
              open={inviteModalActive}
              onClose={handleInviteModalChange}
              title={localize("InviteUsers")}
              primaryAction={
                inviteLink === ""
                  ? {
                      content: localize("GenerateInviteLink"),
                      onAction: generateInviteLink,
                      disabled: generateLinkButtonDisabled,
                    }
                  : {
                      content: localize("Done"),
                      onAction: handleInviteModalChange,
                    }
              }
              secondaryActions={
                inviteLink === "" && [
                  {
                    content: localize("Cancel"),
                    onAction: handleInviteModalChange,
                  },
                ]
              }
            >
              <Modal.Section>
                {inviteLink === "" ? (
                  <Stack vertical>
                    <TextContainer>{localize("SelectTeam")}</TextContainer>
                    <Card>
                      <OptionList
                        onChange={setSelectedTeamForInvite}
                        options={optionsForInvite}
                        selected={selectedTeamForInvite}
                      />
                    </Card>
                  </Stack>
                ) : (
                  <Stack vertical>
                    <Stack alignment="center">
                      <div
                        onClick={copyToClipboard}
                        title={localize("CopyToClipboard")}
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <Icon source={ClipboardMinor} color="inkLight" />
                      </div>
                      <input
                        className={theme.colorScheme === "dark" && "input-dark"}
                        readOnly
                        id="access-link"
                        type="text"
                        value={inviteLink}
                        style={
                          window.innerWidth < 606
                            ? {
                                fontWeight: "bold",
                                fontSize: 14,
                                border: "none",
                                cursor: "default",
                                width: "74vw",
                                maxWidth: 500,
                              }
                            : {
                                fontWeight: "bold",
                                fontSize: 14,
                                border: "none",
                                cursor: "default",
                                width: "90vw",
                                maxWidth: 530,
                              }
                        }
                      />
                    </Stack>
                    <TextContainer>
                      {localize("TakeNoteInitiativeLinkDescription")}
                    </TextContainer>
                  </Stack>
                )}
              </Modal.Section>
            </Modal>
            {toastMarkup}
            {inviteToastMarkup}
            {errorToastMarkup}
            {loadingErrorToastMarkup}
            {clipboardToastMarkup}
          </Page>
        </div>
      )}
    </>
  )
}

export default Initiative
