import React, { useCallback, useState, useContext } from "react";

// Polaris Components
import { Frame } from "@shopify/polaris";

// Stylesheets
import "@shopify/polaris/dist/styles.css";

// Custom Components
import TopBar from "./components/TopBar";

// Dashboard Layout Styles
import "./styles.css";

// auth
import { AuthContext } from "../../AuthContext";

const DashboardLayout = (props) => {
  const { setAuth } = useContext(AuthContext);

  let shouldLogOut = true;
  for (let i = 0; i < localStorage.length; i++) {
    let key = localStorage.key(i);
    if (key.includes("LastAuthUser")) {
      shouldLogOut = false;
    }
  }
  if (!shouldLogOut) {
    localStorage.setItem("lastPath", window.location.pathname);
  } else {
    setAuth({ type: "LOGOUT" });
  }

  // console.log('DashboardLayout props', props)
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const toggleMobileNavigationActive = useCallback(
    () =>
      setMobileNavigationActive(
        (mobileNavigationActive) => !mobileNavigationActive
      ),
    [setMobileNavigationActive]
  );

  return (
    <div style={{ height: "500px" }}>
      <Frame
        topBar={TopBar(toggleMobileNavigationActive)}
        showMobileNavigation={mobileNavigationActive}
        onNavigationDismiss={toggleMobileNavigationActive}
      >
        {React.cloneElement(props.children)}
      </Frame>
    </div>
  );
};

export default DashboardLayout;
