import Service from "../service";
const Api = new Service();

var userLang = navigator.language || navigator.userLanguage;
userLang = userLang.slice(0, userLang.indexOf("-"));

var body = {
  headers: {
    "aw-lang": userLang,
  },
};

const education = {
  async getClass(teamId) {
    return Api.get("educationEndpoint", `/index?teamId=${teamId}`, body);
  },

  async getModule(classId, moduleId) {
    return Api.get("educationEndpoint", `/${classId}/module/${moduleId}`, body);
  },

  async getEntity(classId, moduleId, entityId) {
    return Api.get(
      "educationEndpoint",
      `/${classId}/module/${moduleId}/entity/${entityId}`,
      body
    );
  },

  async getClassAnalytics(classId, from, to) {
    return Api.get(
      "educationEndpoint",
      `/analytics/class/${classId}?from=${from}&to=${to}`
    );
  },

  async getModuleAnalytics(moduleId, from, to) {
    return Api.get(
      "educationEndpoint",
      `/analytics/modules/${moduleId}?from=${from}&to=${to}`
    );
  },

  async getJourneyAnalytics(journeyId, from, to) {
    return Api.get(
      "educationEndpoint",
      `/analytics/journeys/${journeyId}?from=${from}&to=${to}`
    );
  },
};

export default education;
