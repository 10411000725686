import { API } from "aws-amplify"

import teams from "./modules/teams"
import user from "./modules/user"
import education from "./modules/education"
import challenges from "./modules/challenges"

const Api = {
  teams,
  user,
  education,
  challenges,
}

const GraphApi = API

export default Api

export { GraphApi }
