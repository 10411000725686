import React, { useState, useCallback, useEffect, useContext } from "react"
import {
  Page,
  Card,
  TextField,
  Layout,
  Tabs,
  Stack,
  Toast,
  TextStyle,
  TextContainer,
  Icon,
  SkeletonBodyText,
  Modal,
  ResourceList,
  ResourceItem,
  Button,
} from "@shopify/polaris"
import { ClipboardMinor } from "@shopify/polaris-icons"
import QrCode from "../../../images/QrCode"
import Academy from "../../../images/Academy"
import { useParams, useHistory } from "react-router-dom"

// animation
import { CSSTransition } from "react-transition-group"

// global object
import globalObjTeams from "../Utilities/globalObjTeams"

// text editor
import { Editor } from "react-draft-wysiwyg"
import { EditorState, ContentState } from "draft-js"
import htmlToDraft from "html-to-draftjs"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

//style
import "./styles.css"

// api
import Api, { GraphApi } from "../../../api"
import initiative from "../../../api/modules/initiatives"

// all langiages array
import languagesArray from "../../../app/config/languagesArray"

// localizer
import { useLocalizer } from "reactjs-localizer"

// function to remove event listener
import debounce from "../../../app/config/debounceFunction"

// qr code
import QRCodeStyling from "qr-code-styling"
import qrCodeOptionsDark from "./qrCodeOptionsDark.json"
import qrCodeOptionsLight from "./qrCodeOptionsLight.json"

// context
import { AuthContext } from "../../../app/AuthContext"

const TeamShow = ({ location }) => {
  const { id } = useParams()
  const { localize } = useLocalizer()
  const history = useHistory()
  const { theme } = useContext(AuthContext)

  const allLanguages = languagesArray.map((l) => {
    return { id: l.id, content: localize(l.content) }
  })

  // SHOW SUCCESS TOAST IF EDIT PAGE HAS SAVED
  const [successToastActive, setSuccessToastActive] = useState(false)

  const toggleSuccessToastActive = useCallback(
    () => setSuccessToastActive((successToastActive) => !successToastActive),
    []
  )

  const successToastMarkup = successToastActive ? (
    <Toast
      content={localize("ChangesSaved")}
      onDismiss={toggleSuccessToastActive}
    />
  ) : null

  useEffect(() => {
    window.scrollTo(0, 0)

    if (location.state && location.state.saved) {
      toggleSuccessToastActive()
      history.replace({ state: { saved: false } })
    }
    //eslint-disable-next-line
  }, [])

  const dateToString = (date) => {
    let months = [
      localize("JAN"),
      localize("FEB"),
      localize("MAR"),
      localize("APR"),
      localize("MAY"),
      localize("JUN"),
      localize("JUL"),
      localize("AUG"),
      localize("SEP"),
      localize("OCT"),
      localize("NOV"),
      localize("DEC"),
    ]

    let dateToPrint =
      date.getDate() + " " + months[date.getMonth()] + " " + date.getFullYear()

    return dateToPrint
  }

  // REMOVE RESIZE END EVENT
  if (window.innerWidth > 450) {
    window.removeEventListener("resize", debounce)
  }

  // TOAST THAT SAYS TO USER TO FILL ENGLISH LANGUAGE
  const [createToastActive, setCreateToastActive] = useState(false)

  const toggleCreateToastActive = useCallback(
    () => setCreateToastActive((createToastActive) => !createToastActive),
    []
  )

  const createToastMarkup = createToastActive ? (
    <Toast
      content={localize("EnglishIsRequired")}
      onDismiss={toggleCreateToastActive}
      duration={2500}
    />
  ) : null

  // TABS
  const [selectedTab, setSelectedTab] = useState(0)

  const handleTabChange = useCallback(
    (selectedTabIndex) => setSelectedTab(selectedTabIndex),
    [setSelectedTab]
  )

  const [tabs, setTabs] = useState([
    {
      id: "en",
      content: localize("English"),
    },
  ])

  // CHOICE LIST
  const [selectedChoices, setSelectedChoices] = useState([])

  // NAME
  const [name, setName] = useState("")

  // UID
  const [uid, setUid] = useState("")

  // EDITOR
  const [editorState, setEditorState] = useState(EditorState.createEmpty())

  // LOGO CROP
  const [croppedImageLogo, setCroppedImageLogo] = useState(null)

  // COVER CROP
  const [croppedImage, setCroppedImage] = useState(null)

  // FOR PAGE TITLE
  const [pageTitle, setPageTitle] = useState("")

  // CREATED AT
  const [createdAt, setCreatedAt] = useState()

  // UPDATED AT
  const [updatedAt, setUpdatedAt] = useState()

  // ACCESS TYPE
  const [accessType, setAccessType] = useState("")

  // COUNTERS
  const [countersLoading, setCountersLoading] = useState(true)
  const [countersArray, setCountersArray] = useState([])
  const [impactText, setImpactText] = useState("")

  // FETCH SINGLE TEAM
  const fetchTeam = async () => {
    const apiCallAllTeams = JSON.parse(localStorage.getItem("apiCall"))

    var apiCall
    for (let i = 0; i < apiCallAllTeams.length; i++) {
      if (apiCallAllTeams[i].id.toString() === id) {
        apiCall = apiCallAllTeams[i]
      }
    }

    let newTabs = [
      {
        id: "en",
        content: localize("English"),
      },
    ]
    let newSelectedChoices = []
    for (let key in apiCall.documents) {
      for (let j = 0; j < allLanguages.length; j++) {
        if (key === allLanguages[j].id) {
          newTabs.push(allLanguages[j])
        }
      }

      newSelectedChoices.push(key)
    }
    setTabs(newTabs)
    setSelectedChoices(newSelectedChoices)

    for (let key in apiCall.documents) {
      globalObjTeams[key].heading = apiCall.documents[key].heading
      globalObjTeams[key].body = apiCall.documents[key].body[0].content
    }

    setName(apiCall.documents.en.heading)
    const blocksFromHtml = htmlToDraft(apiCall.documents.en.body[0].content)
    const { contentBlocks, entityMap } = blocksFromHtml
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    )
    setEditorState(EditorState.createWithContent(contentState))
    setCroppedImage(apiCall.documents.en.cover)
    setCroppedImageLogo(apiCall.documents.en.img)
    let createdDate = new Date(apiCall.created_at)
    let updatedDate = new Date(apiCall.updated_at)
    let createDateToPrint = dateToString(createdDate)
    let updateDateToPrint = dateToString(updatedDate)
    setCreatedAt(createDateToPrint)
    setUpdatedAt(updateDateToPrint)
    setPageTitle(apiCall.name)
    setUid(apiCall.uid)
    setAccessType(apiCall.access_type)

    // counters
    let supportingArray = JSON.parse(localStorage.getItem("supportingArray"))

    if (supportingArray) {
      try {
        for (let i = 0; i < supportingArray.length; i++) {
          if (
            id === supportingArray[i].slice(0, supportingArray[i].indexOf(","))
          ) {
            let initiativeId = supportingArray[i].slice(
              supportingArray[i].indexOf(",") + 1
            )
            initiativeId = initiativeId.slice(0, initiativeId.indexOf(","))

            let initiativeName = await GraphApi.graphql({
              query: initiative.queries.getInitiativesName,
              variables: {
                id: initiativeId,
              },
            })
            initiativeName = initiativeName.data.getInitiatives.name

            let actionsCounter = await GraphApi.graphql({
              query: initiative.queries.teamInitiativeActionCounterByTeam,
              variables: {
                initiativeId: initiativeId,
                teamId: id,
              },
            })
            actionsCounter =
              actionsCounter.data.teamInitiativeActionCounterByTeam.items[0]
                .count
            let attendeesCounter = await GraphApi.graphql({
              query: initiative.queries.teamInitiativeAttendeesByTeam,
              variables: {
                initiativeId: initiativeId,
                teamId: id,
              },
            })
            attendeesCounter =
              attendeesCounter.data.teamInitiativeAttendeesByTeam.items[0].count
            let partecipantsCounter = await GraphApi.graphql({
              query: initiative.queries.teamInitiativeParticipantsByTeam,
              variables: {
                initiativeId: initiativeId,
                teamId: id,
              },
            })
            partecipantsCounter =
              partecipantsCounter.data.teamInitiativeParticipantsByTeam.items[0]
                .count

            countersArray.push({
              initiativeName: initiativeName,
              actionsCounter: actionsCounter,
              attendeesCounter: attendeesCounter,
              partecipantsCounter: partecipantsCounter,
            })
          }
        }

        localStorage.setItem(`team${id}Impact`, JSON.stringify(countersArray))

        setCountersArray(countersArray)
        setCountersLoading(false)
      } catch (e) {
        console.log(e)
        setCountersLoading(false)
        setImpactText("ErrorLoadingImpact")
      }
    } else {
      setImpactText("UnableToLoadImpact")
      setCountersLoading(false)
    }
  }

  useEffect(() => {
    setTimeout(fetchTeam, 1)
    //eslint-disable-next-line
  }, [])

  // FETCH CLASSES
  const [classes, setClasses] = useState([])
  const [classesLoading, setClassesLoading] = useState(true)

  const fetchClasses = async () => {
    const data = await Api.education.getClass(id)

    if (typeof data.items === "object") {
      setClasses([data.items])
    } else {
      setClasses(data.items)
    }
    setClassesLoading(false)
  }

  useEffect(() => {
    fetchClasses()
    //eslint-disable-next-line
  }, [])

  // READ FROM GLOBAL OBJECT
  useEffect(() => {
    setName(globalObjTeams[tabs[selectedTab].id].heading)

    const blocksFromHtml = htmlToDraft(
      globalObjTeams[tabs[selectedTab].id].body
    )
    const { contentBlocks, entityMap } = blocksFromHtml
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    )
    setEditorState(EditorState.createWithContent(contentState))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab])

  // FUNCTION TO COPY ACCESS LINK IN CLIPBOARD
  const [clipboardToastActive, setClipboardToastActive] = useState(false)

  const toggleClipboardToast = useCallback(
    () =>
      setClipboardToastActive((clipboardToastActive) => !clipboardToastActive),
    []
  )

  const toggleClipboardToastActive = useCallback(
    () => setClipboardToastActive(true),
    []
  )

  const clipboardToastMarkup = clipboardToastActive ? (
    <Toast
      content={localize("CopiedToClipboard")}
      onDismiss={toggleClipboardToast}
    />
  ) : null

  const copyToClipboard = () => {
    var copyText = document.getElementById("access-link")

    copyText.select()
    copyText.setSelectionRange(0, 99999)

    document.execCommand("copy")

    toggleClipboardToastActive()
  }

  // ERROR WHEN A TEXT WITH 4000 CHARACTERS OR MORE IS PASTED
  const [lengthToastActive, setLengthToastActive] = useState(false)

  const toggleLengthToastActive = useCallback(
    () => setLengthToastActive((lengthToastActive) => !lengthToastActive),
    []
  )

  const lengthToastMarkup = lengthToastActive ? (
    <Toast
      content={localize("ThePastedText")}
      onDismiss={toggleLengthToastActive}
      error
      duration={3500}
    />
  ) : null

  // GENERATE QR CODE
  const qrCode = new QRCodeStyling(
    theme.colorScheme === "dark" ? qrCodeOptionsDark : qrCodeOptionsLight
  )

  const [qrActive, setQrActive] = useState(false)

  const handleQrChange = () => {
    setQrActive(!qrActive)
    if (!qrActive) {
      setTimeout(() => {
        qrCode.update({
          data: "https://aworld.org/join/team/" + uid,
        })
        qrCode.append(document.getElementById("qr-code"))
      }, 1)
    }
  }

  // FETCH CHALLENGES
  const [challengesLoading, setChallengesLoading] = useState(true)
  const [challengesList, setChallengesList] = useState([])
  const [pastChallengesList, setPastChallengesList] = useState([])

  useEffect(() => {
    const fetchChallenges = async () => {
      let calls = [
        Api.challenges.getTeamChallenges(id),
        Api.challenges.getTeamPastChallenges(id),
      ]

      const data = await Promise.all(calls)
      console.log("challenges", data)

      setChallengesList(data[0].items)
      setPastChallengesList(data[1].items)

      setChallengesLoading(false)
    }

    fetchChallenges()
  }, [])

  return (
    <Page
      title={pageTitle}
      breadcrumbs={[
        {
          content: "Overview",
          url: "/dashboard",
        },
      ]}
      primaryAction={{
        content: localize("Edit"),
        onAction: () => {
          history.push(window.location.pathname + "/edit")
        },
      }}
    >
      <Layout>
        <Layout.Section>
          <CSSTransition
            in={true}
            appear={true}
            timeout={1000}
            classNames="transform"
          >
            <Card title={localize("Details")}>
              <Tabs
                tabs={tabs}
                selected={selectedTab}
                onSelect={handleTabChange}
              />
              <Card.Section>
                <Stack vertical>
                  {selectedTab === 0 ? (
                    <TextField
                      type="text"
                      label={localize("Name")}
                      placeholder={"Name (" + tabs[selectedTab].content + ")"}
                      value={name}
                      maxLength={60}
                      showCharacterCount
                      readOnly
                    />
                  ) : window.innerWidth < 439 ? (
                    <TextField
                      type="text"
                      label={localize("Name")}
                      placeholder={"Name (" + tabs[selectedTab].content + ")"}
                      value={name}
                      maxLength={60}
                      showCharacterCount
                      readOnly
                    />
                  ) : (
                    <TextField
                      type="text"
                      label={localize("Name")}
                      placeholder={"Name (" + tabs[selectedTab].content + ")"}
                      value={name}
                      maxLength={60}
                      showCharacterCount
                      readOnly
                    />
                  )}
                  <p className="negative-margin-bottom">
                    {localize("Description")}
                  </p>
                  <Editor
                    toolbarClassName={
                      theme.colorScheme === "dark" && "rdw-editor-toolbar-dark"
                    }
                    editorState={editorState}
                    placeholder={
                      "Description (" + tabs[selectedTab].content + ")"
                    }
                    editorStyle={{ height: "auto", minHeight: 100 }}
                    toolbarHidden
                    readOnly
                  />
                </Stack>
              </Card.Section>
            </Card>
          </CSSTransition>
          <CSSTransition
            in={true}
            appear={true}
            timeout={1000}
            classNames="transform"
          >
            <Card title={localize("Academy")} sectioned>
              {classesLoading ? (
                <div style={{ paddingBottom: 16 }}>
                  <SkeletonBodyText lines={5} />
                </div>
              ) : !classes ? (
                <TextContainer>{localize("YouHaveNoAcademy")}</TextContainer>
              ) : (
                <Card>
                  <ResourceList
                    resourceName={{ singular: "Class", plural: "Classes" }}
                    items={classes}
                    renderItem={(item) => {
                      const { id, starts_at, modules } = item

                      const shortcutActions = [
                        {
                          content: (
                            <Button
                              size="slim"
                              onClick={() => {
                                history.push(
                                  window.location.pathname +
                                    "/academy/" +
                                    id +
                                    "/analytics"
                                )
                              }}
                            >
                              {localize("Analytics")}
                            </Button>
                          ),
                        },
                      ]

                      return (
                        <ResourceItem
                          id={id}
                          url={window.location.pathname + "/academy/" + id}
                          accessibilityLabel={`View details for ${id}`}
                          shortcutActions={
                            process.env.REACT_APP_ENVIRONMENT === "prod"
                              ? shortcutActions
                              : null
                          }
                          persistActions
                        >
                          <Stack spacing="extraLoose" alignment="center">
                            <div style={{ marginRight: 14, marginBottom: 20 }}>
                              <Icon source={Academy} color="base" />
                            </div>
                            <Stack vertical>
                              <p style={{ fontSize: 15 }}>
                                <TextStyle variation="strong">
                                  {localize("AcademySingular")} #{id}
                                </TextStyle>
                              </p>
                              <Stack vertical spacing="extraTight">
                                <TextContainer>
                                  {localize("StartDate")}:{" "}
                                  <TextStyle variation="strong">
                                    {dateToString(new Date(starts_at))}
                                  </TextStyle>
                                </TextContainer>
                                <TextContainer>
                                  {localize("Modules")}:{" "}
                                  <TextStyle variation="strong">
                                    {modules.length}
                                  </TextStyle>
                                </TextContainer>
                              </Stack>
                            </Stack>
                          </Stack>
                        </ResourceItem>
                      )
                    }}
                  />
                </Card>
              )}
            </Card>
          </CSSTransition>
          <CSSTransition
            in={true}
            appear={true}
            timeout={1000}
            classNames="transform"
          >
            <Card title={localize("CurrentChallenges")} sectioned>
              {challengesLoading ? (
                <div style={{ paddingBottom: 16 }}>
                  <SkeletonBodyText lines={5} />
                </div>
              ) : challengesList.length === 0 ? (
                <TextContainer>
                  {localize("YouHaveNoCurrentChallenges")}
                </TextContainer>
              ) : (
                <Card>
                  <ResourceList
                    resourceName={{
                      singular: "Challenge",
                      plural: "Challenges",
                    }}
                    items={challengesList}
                    renderItem={(item) => {
                      const { id, data, starts_at, ends_at } = item

                      return (
                        <ResourceItem
                          id={id}
                          url={window.location.pathname + "/challenge/" + id}
                        >
                          <Stack spacing="extraLoose" alignment="center">
                            <div
                              style={{
                                backgroundImage: `url(${data.img})`,
                                backgroundSize: "cover",
                                width: 45,
                                height: 45,
                                borderRadius: 5,
                                marginRight: -8,
                              }}
                            />
                            <Stack vertical>
                              <p style={{ fontSize: 15 }}>
                                <TextStyle variation="strong">
                                  {data.heading}
                                </TextStyle>
                              </p>
                              <Stack vertical spacing="extraTight">
                                <TextContainer>
                                  {localize("StartDate")}:{" "}
                                  <TextStyle variation="strong">
                                    {dateToString(new Date(starts_at))}
                                  </TextStyle>
                                </TextContainer>
                                <TextContainer>
                                  {localize("EndDate")}:{" "}
                                  <TextStyle variation="strong">
                                    {dateToString(new Date(ends_at))}
                                  </TextStyle>
                                </TextContainer>
                              </Stack>
                            </Stack>
                          </Stack>
                        </ResourceItem>
                      )
                    }}
                  />
                </Card>
              )}
            </Card>
          </CSSTransition>
          <CSSTransition
            in={true}
            appear={true}
            timeout={1000}
            classNames="transform"
          >
            <Card title={localize("PastChallenges")} sectioned>
              {challengesLoading ? (
                <div style={{ paddingBottom: 16 }}>
                  <SkeletonBodyText lines={5} />
                </div>
              ) : pastChallengesList.length === 0 ? (
                <TextContainer>
                  {localize("YouHaveNoPastChallenges")}
                </TextContainer>
              ) : (
                <Card>
                  <ResourceList
                    resourceName={{
                      singular: "Challenge",
                      plural: "Challenges",
                    }}
                    items={pastChallengesList}
                    renderItem={(item) => {
                      const { id, data, starts_at, ends_at } = item

                      return (
                        <ResourceItem
                          id={id}
                          url={window.location.pathname + "/challenge/" + id}
                        >
                          <Stack spacing="extraLoose" alignment="center">
                            <div
                              style={{
                                backgroundImage: `url(${data.img})`,
                                backgroundSize: "cover",
                                width: 45,
                                height: 45,
                                borderRadius: 5,
                                marginRight: -8,
                              }}
                            />
                            <Stack vertical>
                              <p style={{ fontSize: 15 }}>
                                <TextStyle variation="strong">
                                  {data.heading}
                                </TextStyle>
                              </p>
                              <Stack vertical spacing="extraTight">
                                <TextContainer>
                                  {localize("StartDate")}:{" "}
                                  <TextStyle variation="strong">
                                    {dateToString(new Date(starts_at))}
                                  </TextStyle>
                                </TextContainer>
                                <TextContainer>
                                  {localize("EndDate")}:{" "}
                                  <TextStyle variation="strong">
                                    {dateToString(new Date(ends_at))}
                                  </TextStyle>
                                </TextContainer>
                              </Stack>
                            </Stack>
                          </Stack>
                        </ResourceItem>
                      )
                    }}
                  />
                </Card>
              )}
            </Card>
          </CSSTransition>
        </Layout.Section>
        <Layout.Section secondary>
          <CSSTransition
            in={true}
            appear={true}
            timeout={1000}
            classNames="transform"
          >
            <Card title={localize("Summary")} sectioned>
              <Stack vertical spacing="extraTight">
                <TextContainer>
                  {localize("Name")}:{" "}
                  <TextStyle variation="strong">{pageTitle}</TextStyle>
                </TextContainer>
                <TextContainer>
                  {localize("Languages")}:{" "}
                  <TextStyle variation="strong">
                    {selectedChoices.map((value, index) => {
                      if (index === selectedChoices.length - 1) {
                        return value.toUpperCase()
                      } else {
                        return value.toUpperCase() + ", "
                      }
                    })}
                  </TextStyle>
                </TextContainer>
                <TextContainer>
                  {localize("CreatedAt")}:{" "}
                  <TextStyle variation="strong">{createdAt}</TextStyle>
                </TextContainer>
                <TextContainer>
                  {localize("UpdatedAt")}:{" "}
                  <TextStyle variation="strong">{updatedAt}</TextStyle>
                </TextContainer>
              </Stack>
            </Card>
          </CSSTransition>
          {accessType === "public" && (
            <CSSTransition
              in={true}
              appear={true}
              timeout={1000}
              classNames="transform"
            >
              <Card title={localize("AccessLink")} sectioned>
                <Stack spacing="tight">
                  <input
                    className={theme.colorScheme === "dark" && "input-dark"}
                    readOnly
                    id="access-link"
                    type="text"
                    value={"https://aworld.org/join/team/" + uid}
                    style={
                      window.innerWidth < 804
                        ? {
                            fontWeight: "bold",
                            fontSize: 14,
                            border: "none",
                            cursor: "default",
                            width: "32vh",
                            maxWidth: 480,
                          }
                        : {
                            fontWeight: "bold",
                            fontSize: 14,
                            border: "none",
                            cursor: "default",
                            width: "17vw",
                            maxWidth: 200,
                          }
                    }
                  />
                  <div
                    title={localize("CopyToClipboard")}
                    onClick={copyToClipboard}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <Icon source={ClipboardMinor} color="base" />
                  </div>
                  <div
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={handleQrChange}
                  >
                    <Icon source={QrCode} color="base" />
                  </div>
                </Stack>
              </Card>
            </CSSTransition>
          )}
          {!countersLoading && countersArray.length > 0 ? (
            <CSSTransition
              in={true}
              appear={true}
              timeout={1000}
              classNames="transform"
            >
              <Card title={localize("Impact")}>
                {countersArray.map((item) => (
                  <Card.Section
                    title={item.initiativeName}
                    key={item.initiativeName}
                  >
                    <TextContainer>
                      {localize("ActionsTaken")}:{" "}
                      <TextStyle variation="strong">
                        {item.actionsCounter}
                      </TextStyle>
                    </TextContainer>
                    <TextContainer>
                      {localize("PeoplePartecipating")}:{" "}
                      <TextStyle variation="strong">
                        {item.partecipantsCounter}
                      </TextStyle>
                    </TextContainer>
                    <TextContainer>
                      {localize("Attendees")}:{" "}
                      <TextStyle variation="strong">
                        {item.attendeesCounter}
                      </TextStyle>
                    </TextContainer>
                  </Card.Section>
                ))}
              </Card>
            </CSSTransition>
          ) : countersLoading ? (
            <CSSTransition
              in={true}
              appear={true}
              timeout={1000}
              classNames="transform"
            >
              <Card sectioned title={localize("Impact")}>
                <div style={{ paddingBottom: 8, paddingTop: 4 }}>
                  <SkeletonBodyText lines={5} />
                </div>
              </Card>
            </CSSTransition>
          ) : (
            <CSSTransition
              in={true}
              appear={true}
              timeout={1000}
              classNames="transform"
            >
              <Card sectioned title={localize("Impact")}>
                <TextContainer>
                  {impactText
                    ? localize(impactText)
                    : localize("YouDoNotSupportInitiatives")}
                </TextContainer>
              </Card>
            </CSSTransition>
          )}
          <CSSTransition
            in={true}
            appear={true}
            timeout={1000}
            classNames="transform"
          >
            <Card sectioned title={localize("CoverImage")}>
              <img src={croppedImage} style={{ height: 100 }} alt="" />
            </Card>
          </CSSTransition>
          <CSSTransition
            in={true}
            appear={true}
            timeout={1000}
            classNames="transform"
          >
            <Card sectioned title={localize("LogoOnly")}>
              <img src={croppedImageLogo} style={{ height: 100 }} alt="" />
            </Card>
          </CSSTransition>
        </Layout.Section>
      </Layout>
      <Modal
        open={qrActive}
        onClose={handleQrChange}
        title={localize("AccessQRCode")}
        primaryAction={{
          content: "Download",
          onAction: () => {
            let lightQrCode = new QRCodeStyling(qrCodeOptionsLight)
            lightQrCode.update({
              data: "https://aworld.org/join/team/" + uid,
            })
            lightQrCode.download({
              name: `${localize("JoinTeam")} ${name}`,
              extension: "png",
            })
          },
        }}
        secondaryActions={[
          {
            content: localize("Close"),
            onAction: handleQrChange,
          },
        ]}
      >
        <Modal.Section>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div id="qr-code" />
          </div>
        </Modal.Section>
      </Modal>
      {createToastMarkup}
      {clipboardToastMarkup}
      {lengthToastMarkup}
      {successToastMarkup}
    </Page>
  )
}

export default TeamShow
