import React from "react";
import {
  Page,
  Card,
  ResourceItem,
  ResourceList,
  Stack,
} from "@shopify/polaris";

// localizer
import { useLocalizer } from "reactjs-localizer";

//animation
import { CSSTransition } from "react-transition-group";

// auth
import { federatedSignIn } from "../../app/AuthContext";

// sch logo
import SchLogo from "../../images/sch-logo.png";

const BusinessLogin = ({ businessTeamsList }) => {
  const { localize } = useLocalizer();

  return (
    <div className="login-card-business">
      <CSSTransition
        in={true}
        appear={true}
        timeout={1000}
        classNames="transform"
      >
        <Page breadcrumbs={[{ content: "Sign in page", url: "/dashboard" }]}>
          <Card sectioned title={localize("SelectOrganization")}>
            <Card>
              <ResourceList
                resourceName={{ singular: "Team", plural: "Teams" }}
                items={businessTeamsList}
                renderItem={(item) => {
                  const { teamId, businessLogo, businessName, access } = item;

                  let providerName = access[0].providerName;
                  let logo;
                  if (businessName === "Schlumberger") {
                    logo = SchLogo;
                  } else {
                    logo = businessLogo;
                  }

                  return (
                    <ResourceItem
                      id={teamId}
                      onClick={() => {
                        federatedSignIn(providerName);
                      }}
                      accessibilityLabel={`View details for ${businessName}`}
                    >
                      <Stack vertical>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            paddingTop: 8,
                            height: 80,
                          }}
                        >
                          <img src={logo} alt="" />
                        </div>
                      </Stack>
                    </ResourceItem>
                  );
                }}
              />
            </Card>
          </Card>
        </Page>
      </CSSTransition>
    </div>
  );
};

export default BusinessLogin;
