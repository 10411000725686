import React, { useState, useCallback, useContext } from "react"
import {
  Card,
  ResourceItem,
  ResourceList,
  TextStyle,
  Stack,
  Icon,
  TextContainer,
  Collapsible,
  Button,
} from "@shopify/polaris"

// localizer
import { useLocalizer } from "reactjs-localizer"

// icons
import ModuleIcon from "../../../images/ModuleIcon"
import ChevronDown from "../../../images/ChevronDown"
import OpenInNewTab from "../../../images/OpenInNewTab"

// survey image
import surveyImage from "../../../images/survey.jpg"

// context
import { AuthContext } from "../../../app/AuthContext"

const ResourceItemModule = ({ item, index }) => {
  const { localize } = useLocalizer()
  const { theme } = useContext(AuthContext)

  // UTILITY
  const dateToString = (date) => {
    let months = [
      localize("JAN"),
      localize("FEB"),
      localize("MAR"),
      localize("APR"),
      localize("MAY"),
      localize("JUN"),
      localize("JUL"),
      localize("AUG"),
      localize("SEP"),
      localize("OCT"),
      localize("NOV"),
      localize("DEC"),
    ]

    let dateToPrint =
      date.getDate() + " " + months[date.getMonth()] + " " + date.getFullYear()

    return dateToPrint
  }

  // COLLAPSIBLE
  const [open, setOpen] = useState(false)

  const handleToggle = useCallback(() => setOpen((open) => !open), [])

  // ITEM
  const { id, starts_at, entities } = item

  return (
    <div
      id={id}
      style={{
        position: "relative",
        zIndex: 2,
        minHeight: "4.4rem",
        padding: 15,
        cursor: "pointer",
        borderTop:
          index !== 0
            ? theme.colorScheme === "dark"
              ? "1px solid #363739"
              : "1px solid #e1e1e1"
            : "none",
      }}
      onClick={handleToggle}
      className={
        theme.colorScheme === "dark"
          ? "modules-item-container"
          : "modules-item-container-light"
      }
    >
      <Stack spacing="extraLoose" alignment="center" wrap={false}>
        <div style={{ marginRight: 14, marginBottom: 20 }}>
          <Icon source={ModuleIcon} color="base" />
        </div>
        <Stack vertical>
          <p style={{ fontSize: 15 }}>
            <TextStyle variation="strong">
              {localize("Module")} #{id}
            </TextStyle>
          </p>
          <Stack vertical spacing="extraTight">
            <TextContainer>
              {localize("StartDate")}:{" "}
              <TextStyle variation="strong">
                {dateToString(new Date(starts_at))}
              </TextStyle>
            </TextContainer>
            <TextContainer>
              {localize("JourneysAndSurveys")}:{" "}
              <TextStyle variation="strong">{entities.length}</TextStyle>
            </TextContainer>
          </Stack>
        </Stack>
        <Stack.Item fill>
          <Stack distribution="trailing">
            <div
              style={
                open
                  ? {
                      transform: "rotate(180deg)",
                      transition: "200ms",
                    }
                  : {
                      transform: "rotate(0deg)",
                      transition: "200ms",
                    }
              }
              className="chevron-container"
            >
              <Icon source={ChevronDown} color="base" />
            </div>
          </Stack>
        </Stack.Item>
      </Stack>
      <Collapsible
        open={open}
        id="basic-collapsible"
        transition={{
          duration: "200ms",
          timingFunction: "ease-in-out",
        }}
        expandOnPrint
      >
        <div
          style={{
            paddingTop: 20,
            paddingBottom: 5,
          }}
        >
          <Card>
            <ResourceList
              resourceName={{
                singular: "customer",
                plural: "customers",
              }}
              items={entities}
              renderItem={(item) => {
                const { id: entityId, entity, starts_at } = item

                const shortcutActions = [
                  {
                    content: (
                      <Button size="slim">
                        <div
                          style={{ marginBottom: -3 }}
                          onClick={(e) => {
                            e.preventDefault()
                            const win = window.open(
                              `${window.location.pathname}/module/${id}/entity/${entityId}`,
                              "_blank"
                            )
                            win.focus()
                          }}
                        >
                          <Icon source={OpenInNewTab} color="base" />
                        </div>
                      </Button>
                    ),
                  },
                ]

                return (
                  <ResourceItem
                    id={entityId}
                    url={`${window.location.pathname}/module/${id}/entity/${entityId}`}
                    shortcutActions={shortcutActions}
                    persistActions
                  >
                    <Stack spacing="loose" alignment="center" wrap={false}>
                      <img
                        src={
                          entity.data.image ? entity.data.image : surveyImage
                        }
                        style={{
                          height: "100%",
                          maxHeight: 50,
                          margin: 0,
                        }}
                        alt=""
                      />
                      <Stack vertical spacing="extraTight">
                        <p
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            fontWeight: 600,
                            width: "55vw",
                            maxWidth: 600,
                          }}
                        >
                          {entity.data.title}
                        </p>
                        <TextContainer>
                          {localize("StartDate")}:{" "}
                          <TextStyle variation="strong">
                            {dateToString(new Date(starts_at))}
                          </TextStyle>
                        </TextContainer>
                      </Stack>
                    </Stack>
                  </ResourceItem>
                )
              }}
            />
          </Card>
        </div>
      </Collapsible>
    </div>
  )
}

export default ResourceItemModule
