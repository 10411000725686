import Service from "../service"
const Api = new Service()

const challenges = {
  async getTeamChallenges(teamId) {
    return Api.get("challengesEndpoint", `/team/${teamId}/reserved`)
  },
  async getTeamPastChallenges(teamId) {
    return Api.get("challengesEndpoint", `/team/${teamId}/reserved/past`)
  },
  async getChallenge(challengeId) {
    return Api.get("challengesEndpoint", `/${challengeId}`)
  },
  async getChallengeMembersCount(challengeId) {
    return Api.get("challengesEndpoint", `/${challengeId}/users/count`)
  },
  async getLeaderboard(challengeId, date, nextToken, limit) {
    let url = `/${challengeId}/leaderboard?date=${date}`
    if (nextToken) {
      url += `&next=${nextToken}`
    }
    if (limit) {
      url += `&limit=${limit}`
    }

    return Api.get("challengesEndpoint", url)
  },
  async getGroupsLeaderboard(challengeId, date) {
    let url = `/${challengeId}/groups/leaderboard?date=${date}`

    return Api.get("challengesEndpoint", url)
  },
  async getGroupLeaderboard(challengeId, groupId, date, nextToken, limit) {
    let url = `/${challengeId}/groups/${groupId}/leaderboard?date=${date}`
    if (nextToken) {
      url += `&next=${nextToken}`
    }
    if (limit) {
      url += `&limit=${limit}`
    }

    return Api.get("challengesEndpoint", url)
  },
}

export default challenges
