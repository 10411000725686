import React, { useState, useCallback, useEffect, useContext } from "react";
import {
  Page,
  Card,
  TextField,
  Layout,
  Tabs,
  Stack,
  Modal,
  ChoiceList,
  DropZone,
  Spinner,
  Button,
  Toast,
  TextStyle,
  TextContainer,
  Icon,
  SkeletonBodyText,
} from "@shopify/polaris";
import { ClipboardMinor } from "@shopify/polaris-icons";
import QrCode from "../../../images/QrCode";
import { useParams, useHistory } from "react-router-dom";

// animation
import { CSSTransition } from "react-transition-group";

// global object
import globalObjTeams from "../Utilities/globalObjTeams";

// text editor
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

//image cropper
import Slider from "@material-ui/core/Slider";
import Cropper from "react-easy-crop";
import { getCroppedImg } from "./../Utilities/canvasUtils";

//style
import "./styles.css";

// api
import Api, { GraphApi } from "../../../api";
import initiative from "../../../api/modules/initiatives";

// all langiages array
import languagesArray from "../../../app/config/languagesArray";

// localizer
import { useLocalizer } from "reactjs-localizer";

// function to remove event listener
import debounce from "../../../app/config/debounceFunction";

// image compression
import imageCompression from "browser-image-compression";

// qr code
import QRCodeStyling from "qr-code-styling";
import qrCodeOptionsDark from "./qrCodeOptionsDark.json";
import qrCodeOptionsLight from "./qrCodeOptionsLight.json";

// context
import { AuthContext } from "../../../app/AuthContext";

const TeamEdit = () => {
  const { id } = useParams();
  const [saving, setSaving] = useState(false);
  const { localize } = useLocalizer();
  const history = useHistory();
  const { theme } = useContext(AuthContext);

  const allLanguages = languagesArray.map((l) => {
    return { id: l.id, content: localize(l.content) };
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isoDateToString = (date) => {
    let months = [
      localize("JAN"),
      localize("FEB"),
      localize("MAR"),
      localize("APR"),
      localize("MAY"),
      localize("JUN"),
      localize("JUL"),
      localize("AUG"),
      localize("SEP"),
      localize("OCT"),
      localize("NOV"),
      localize("DEC"),
    ];

    let dateToPrint =
      date.getDate() + " " + months[date.getMonth()] + " " + date.getFullYear();

    return dateToPrint;
  };

  // REMOVE RESIZE END EVENT
  if (window.innerWidth > 450) {
    window.removeEventListener("resize", debounce);
  }

  // DISABLE CREATE BUTTON
  const [createButtonDisabled, setCreateButtonDisabled] = useState(true);

  useEffect(() => {
    let primaryActionButton = document.getElementsByClassName(
      "Polaris-Page-Header__PrimaryActionWrapper"
    )[0];
    if (saving) {
      primaryActionButton.style.cursor = "default";
    } else {
      primaryActionButton.style.cursor = "pointer";
    }
    //eslint-disable-next-line
  }, [saving]);

  // TABS
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = useCallback(
    (selectedTabIndex) => setSelectedTab(selectedTabIndex),
    [setSelectedTab]
  );

  const [tabs, setTabs] = useState([
    {
      id: "en",
      content: localize("English"),
    },
  ]);

  // CHOICE LIST
  const [selectedChoices, setSelectedChoices] = useState([]);
  const [selectedChoicesWhenModalOpen, setSelectedChoicesWhenModalOpen] =
    useState([]);
  const handleChoicesSelectionChange = useCallback(
    (value) => setSelectedChoices(value),
    [setSelectedChoices]
  );

  // MODAl
  const [modalActive, setModalActive] = useState(false);

  const handleModalOpen = useCallback(() => {
    setModalActive(true);
    setSelectedChoicesWhenModalOpen(selectedChoices);
  }, [selectedChoices]);

  const handleModalClose = useCallback(() => {
    setModalActive(false);
    setSelectedChoices(selectedChoicesWhenModalOpen);
  }, [selectedChoicesWhenModalOpen]);

  const handleLanguagesManaged = useCallback(() => {
    setModalActive(!modalActive);

    let newTabs = [
      {
        id: "en",
        content: localize("English"),
      },
    ];
    for (let i = 0; i < selectedChoices.length; i++) {
      for (let j = 0; j < allLanguages.length; j++) {
        if (selectedChoices[i] === allLanguages[j].id) {
          newTabs.push(allLanguages[j]);
        }
      }
    }

    if (selectedTab >= newTabs.length) {
      setSelectedTab(0);
    }

    setTabs([...newTabs]);
  }, [modalActive, selectedChoices, selectedTab, allLanguages, localize]);

  // NAME LENGTH MUST BE > 3
  const [nameLengthError, setNameLengthError] = useState(false);

  // NAME
  const [name, setName] = useState("");
  const nameChange = useCallback(
    (value) => {
      let regex = /^$|[0-9A-Za-z-.,_#?!' \u00C0-\u00F6\u00F8-\u00FF]+$/;
      if (regex.test(value)) {
        setName(value);
      }
      setNameLengthError(false);
    },
    [setName, setNameLengthError]
  );

  // UID
  const [uid, setUid] = useState("");

  // EDITOR
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  // LOGO CROP
  // IMAGE CROP
  const [cropLogo, setCropLogo] = useState({ x: 0, y: 0 });
  const [zoomLogo, setZoomLogo] = useState(1);
  const [croppedAreaPixelsLogo, setCroppedAreaPixelsLogo] = useState(null);
  const [croppedImageLogo, setCroppedImageLogo] = useState(null);
  const [imageSrcLogo, setImageSrcLogo] = useState(null);
  const [doneButtonDisabledLogo, setDoneButtonDisabledLogo] = useState(false);
  const onCropCompleteLogo = useCallback(
    (croppedAreaLogo, croppedAreaPixelsLogo) => {
      setCroppedAreaPixelsLogo(croppedAreaPixelsLogo);
    },
    []
  );

  // read file dropped in the drop zone
  function readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  }

  const croppedImageVisualLogo = croppedImageLogo && (
    <img src={croppedImageLogo} alt="" className="cropped-image-visual-logo" />
  );

  // IMAGE UPLOAD
  const [uploadedImageLogo, setUploadedImageLogo] = useState();

  const fileUploadLogo = !croppedImageLogo && (
    <DropZone.FileUpload
      actionTitle={localize("AddImage")}
      actionHint={localize("DropImage")}
    />
  );

  const onFileChangeLogo = useCallback(async () => {
    let imageDataUrl = await readFile(uploadedImageLogo);
    setImageSrcLogo(imageDataUrl);
  }, [uploadedImageLogo]);

  // MODAL FOR CROPPING
  const [modalForCroppingActiveLogo, setModalForCroppingActiveLogo] =
    useState(false);

  const handleModalForCroppingChangeLogo = useCallback(() => {
    setModalForCroppingActiveLogo(!modalForCroppingActiveLogo);
    setCropLogo({ x: 0, y: 0 });
    setZoomLogo(1);
  }, [modalForCroppingActiveLogo]);

  const handleModalForCroppingCloseLogo = useCallback(() => {
    setModalForCroppingActiveLogo(!modalForCroppingActiveLogo);
    setUploadedImageLogo();
    setCropLogo({ x: 0, y: 0 });
    setZoomLogo(1);
  }, [modalForCroppingActiveLogo]);

  useEffect(() => {
    if (uploadedImageLogo) {
      onFileChangeLogo();
    }
  }, [uploadedImageLogo, onFileChangeLogo]);

  // handle the dropped file
  const handleDropZoneDropLogo = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) => {
      if (acceptedFiles.length > 0) {
        setUploadedImageLogo(acceptedFiles[0]);
        handleModalForCroppingChangeLogo();
      }
    },
    [handleModalForCroppingChangeLogo]
  );

  const takeCroppedImageLogo = useCallback(async () => {
    setDoneButtonDisabledLogo(true);

    try {
      const croppedImageToSave = await getCroppedImg(
        imageSrcLogo,
        croppedAreaPixelsLogo
      );

      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      try {
        const blobNotCompressed = await fetch(croppedImageToSave).then((r) =>
          r.blob()
        );

        const blob = await imageCompression(blobNotCompressed, options);

        const apiCall = await Api.teams.getSignedUrl();
        const imageUrl = apiCall.getUrl;
        const path = apiCall.s3PutObjectUrl;

        await Api.teams.putS3Object(blob, path);
        setCroppedImageLogo(imageUrl);
        handleModalForCroppingChangeLogo();
        setUploadedImageLogo();
        setDoneButtonDisabledLogo(false);
      } catch (e) {
        console.log(e);
      }
    } catch (e) {
      console.error(e);
      setDoneButtonDisabledLogo(false);
    }
  }, [imageSrcLogo, croppedAreaPixelsLogo, handleModalForCroppingChangeLogo]);

  // COVER CROP
  // IMAGE CROP
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [doneButtonDisabled, setDoneButtonDisabled] = useState(false);
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const croppedImageVisual = croppedImage && (
    <img src={croppedImage} alt="" className="cropped-image-visual" />
  );

  // IMAGE UPLOAD
  const [uploadedImage, setUploadedImage] = useState();

  const fileUpload = !croppedImage && (
    <DropZone.FileUpload
      actionTitle={localize("AddImage")}
      actionHint={localize("DropImage")}
    />
  );

  const onFileChange = useCallback(async () => {
    let imageDataUrl = await readFile(uploadedImage);
    setImageSrc(imageDataUrl);
  }, [uploadedImage]);

  // MODAL FOR CROPPING
  const [modalForCroppingActive, setModalForCroppingActive] = useState(false);

  const handleModalForCroppingChange = useCallback(() => {
    setModalForCroppingActive(!modalForCroppingActive);
    setCrop({ x: 0, y: 0 });
    setZoom(1);
  }, [modalForCroppingActive]);

  const handleModalForCroppingClose = useCallback(() => {
    setModalForCroppingActive(!modalForCroppingActive);
    setUploadedImage();
    setCrop({ x: 0, y: 0 });
    setZoom(1);
  }, [modalForCroppingActive]);

  useEffect(() => {
    if (uploadedImage) {
      onFileChange();
    }
  }, [uploadedImage, onFileChange]);

  // handle the dropped file
  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) => {
      if (acceptedFiles.length > 0) {
        setUploadedImage(acceptedFiles[0]);
        handleModalForCroppingChange();
      }
    },
    [handleModalForCroppingChange]
  );

  const takeCroppedImage = useCallback(async () => {
    setDoneButtonDisabled(true);

    try {
      const croppedImageToSave = await getCroppedImg(
        imageSrc,
        croppedAreaPixels
      );

      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      try {
        const blobNotCompressed = await fetch(croppedImageToSave).then((r) =>
          r.blob()
        );

        const blob = await imageCompression(blobNotCompressed, options);

        const apiCall = await Api.teams.getSignedUrl();
        const imageUrl = apiCall.getUrl;
        const path = apiCall.s3PutObjectUrl;

        await Api.teams.putS3Object(blob, path);
        setCroppedImage(imageUrl);
        handleModalForCroppingChange();
        setUploadedImage();
        setDoneButtonDisabled(false);
      } catch (e) {
        console.log(e);
      }
    } catch (e) {
      console.error(e);
      setDoneButtonDisabled(false);
    }
  }, [imageSrc, croppedAreaPixels, handleModalForCroppingChange]);

  // COPY NAME FROM ENGLISH
  const copyNameFromEnglish = () => {
    let nameToSet = globalObjTeams.en.heading;
    setName(nameToSet);
  };

  // FOR PAGE TITLE
  const [pageTitle, setPageTitle] = useState("");

  // CREATED AT
  const [createdAt, setCreatedAt] = useState();

  // UPDATED AT
  const [updatedAt, setUpdatedAt] = useState();

  // ACCESS TYPE
  const [accessType, setAccessType] = useState("");

  // COUNTERS
  const [countersLoading, setCountersLoading] = useState(true);
  const [countersArray, setCountersArray] = useState([]);
  const [impactText, setImpactText] = useState("");

  // FETCH SINGLE TEAM
  const fetchTeam = async () => {
    const apiCallAllTeams = JSON.parse(localStorage.getItem("apiCall"));

    var apiCall;
    for (let i = 0; i < apiCallAllTeams.length; i++) {
      if (apiCallAllTeams[i].id.toString() === id) {
        apiCall = apiCallAllTeams[i];
      }
    }

    let newTabs = [
      {
        id: "en",
        content: localize("English"),
      },
    ];
    let newSelectedChoices = [];
    for (let key in apiCall.documents) {
      for (let j = 0; j < allLanguages.length; j++) {
        if (key === allLanguages[j].id) {
          newTabs.push(allLanguages[j]);
        }
      }

      newSelectedChoices.push(key);
    }
    setTabs(newTabs);
    setSelectedChoices(newSelectedChoices);

    for (let key in apiCall.documents) {
      globalObjTeams[key].heading = apiCall.documents[key].heading;
      globalObjTeams[key].body = apiCall.documents[key].body[0].content;
    }

    setName(apiCall.name);
    const blocksFromHtml = htmlToDraft(apiCall.documents.en.body[0].content);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    setEditorState(EditorState.createWithContent(contentState));
    setCroppedImage(apiCall.documents.en.cover);
    setCroppedImageLogo(apiCall.documents.en.img);
    let createdDate = new Date(apiCall.created_at);
    let updatedDate = new Date(apiCall.updated_at);
    let createDateToPrint = isoDateToString(createdDate);
    let updateDateToPrint = isoDateToString(updatedDate);
    setCreatedAt(createDateToPrint);
    setUpdatedAt(updateDateToPrint);
    setPageTitle(apiCall.name);
    setUid(apiCall.uid);
    setAccessType(apiCall.access_type);

    // counters
    let supportingArray = JSON.parse(localStorage.getItem("supportingArray"));

    let countersArrayFromLocalStorage = JSON.parse(
      localStorage.getItem(`team${id}Impact`)
    );

    if (supportingArray && !countersArrayFromLocalStorage) {
      try {
        for (let i = 0; i < supportingArray.length; i++) {
          if (
            id === supportingArray[i].slice(0, supportingArray[i].indexOf(","))
          ) {
            let initiativeId = supportingArray[i].slice(
              supportingArray[i].indexOf(",") + 1
            );
            initiativeId = initiativeId.slice(0, initiativeId.indexOf(","));

            let initiativeName = await GraphApi.graphql({
              query: initiative.queries.getInitiativesName,
              variables: {
                id: initiativeId,
              },
            });
            initiativeName = initiativeName.data.getInitiatives.name;

            let actionsCounter = await GraphApi.graphql({
              query: initiative.queries.teamInitiativeActionCounterByTeam,
              variables: {
                initiativeId: initiativeId,
                teamId: id,
              },
            });
            actionsCounter =
              actionsCounter.data.teamInitiativeActionCounterByTeam.items[0]
                .count;
            let attendeesCounter = await GraphApi.graphql({
              query: initiative.queries.teamInitiativeAttendeesByTeam,
              variables: {
                initiativeId: initiativeId,
                teamId: id,
              },
            });
            attendeesCounter =
              attendeesCounter.data.teamInitiativeAttendeesByTeam.items[0]
                .count;
            let partecipantsCounter = await GraphApi.graphql({
              query: initiative.queries.teamInitiativeParticipantsByTeam,
              variables: {
                initiativeId: initiativeId,
                teamId: id,
              },
            });
            partecipantsCounter =
              partecipantsCounter.data.teamInitiativeParticipantsByTeam.items[0]
                .count;

            countersArray.push({
              initiativeName: initiativeName,
              actionsCounter: actionsCounter,
              attendeesCounter: attendeesCounter,
              partecipantsCounter: partecipantsCounter,
            });
          }
        }

        localStorage.setItem(`team${id}Impact`, JSON.stringify(countersArray));

        setCountersArray(countersArray);
        setCountersLoading(false);
      } catch (e) {
        console.log(e);
        setCountersLoading(false);
        setImpactText("ErrorLoadingImpact");
      }
    } else {
      if (
        countersArrayFromLocalStorage ||
        countersArrayFromLocalStorage.length === 0
      ) {
        setCountersArray(countersArrayFromLocalStorage);
      } else {
        setImpactText("UnableToLoadImpact");
      }

      setCountersLoading(false);
    }
  };

  useEffect(() => {
    setTimeout(fetchTeam, 1);
    //eslint-disable-next-line
  }, []);

  // WRITE ON GLOBAL OBJECT
  useEffect(() => {
    globalObjTeams[tabs[selectedTab].id].heading = name;
    globalObjTeams[tabs[selectedTab].id].body = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    if (croppedImage) {
      for (let key in globalObjTeams) {
        globalObjTeams[key].cover = croppedImage;
      }
    }
    if (croppedImageLogo) {
      for (let key in globalObjTeams) {
        globalObjTeams[key].img = croppedImageLogo;
      }
    }

    if (
      globalObjTeams.en.heading !== "" &&
      !globalObjTeams.en.body.startsWith("<p></p>")
    ) {
      setCreateButtonDisabled(false);
    } else {
      setCreateButtonDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, editorState, croppedImage, croppedImageLogo]);

  // READ FROM GLOBAL OBJECT
  useEffect(() => {
    setName(globalObjTeams[tabs[selectedTab].id].heading);

    const blocksFromHtml = htmlToDraft(
      globalObjTeams[tabs[selectedTab].id].body
    );
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    setEditorState(EditorState.createWithContent(contentState));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  // SAVE
  const [successToastActive, setSuccessToastActive] = useState(false);
  const [errorToastActive, setErrorToastActive] = useState(false);
  const [errorToastMessage, setErrorToastMessage] = useState("");

  const toggleSuccessToastActive = useCallback(
    () => setSuccessToastActive((successToastActive) => !successToastActive),
    []
  );
  const toggleErrorToastActive = useCallback(
    () => setErrorToastActive((errorToastActive) => !errorToastActive),
    []
  );

  const successToastMarkup = successToastActive ? (
    <Toast
      content={localize("ChangesSaved")}
      onDismiss={toggleSuccessToastActive}
    />
  ) : null;

  const errorToastMarkup = errorToastActive ? (
    <Toast
      content={errorToastMessage}
      onDismiss={toggleErrorToastActive}
      error
    />
  ) : null;

  const onSave = async () => {
    setCreateButtonDisabled(true);
    setSaving(true);
    setSuccessToastActive(false);
    setErrorToastActive(false);

    let globalObjToPass = JSON.parse(JSON.stringify(globalObjTeams));

    let languagesToMantein = [];
    for (let i = 0; i < tabs.length; i++) {
      languagesToMantein.push(tabs[i].id);
    }

    for (let key in globalObjToPass) {
      if (!languagesToMantein.includes(key)) {
        delete globalObjToPass[key];
      }
    }

    for (let i = 0; i < languagesToMantein.length; i++) {
      if (
        globalObjToPass[languagesToMantein[i]].heading === "" ||
        globalObjToPass[languagesToMantein[i]].body === "" ||
        globalObjToPass[languagesToMantein[i]].body.startsWith("<p></p>")
      ) {
        delete globalObjToPass[languagesToMantein[i]];
      } else {
        globalObjToPass[languagesToMantein[i]].body = globalObjToPass[
          languagesToMantein[i]
        ].body.slice(0, globalObjToPass[languagesToMantein[i]].body.length - 1);
      }
    }

    let canCreate = true;
    for (let key in globalObjToPass) {
      if (globalObjToPass[key].heading.length < 4) {
        canCreate = false;
      }
    }

    for (let key in globalObjToPass) {
      globalObjToPass[key].body = globalObjToPass[key].body.replace(
        /(\r\n|\n|\r)/gm,
        ""
      );
    }

    const body = {
      body: {
        name: globalObjTeams.en.heading,
        documents: globalObjToPass,
      },
    };

    if (canCreate) {
      try {
        await Api.teams.updateTeam(id, body);

        // updating and saving new apiCall
        let oldApiCall = JSON.parse(localStorage.getItem("apiCall"));

        for (let key in globalObjToPass) {
          globalObjToPass[key].body = [
            { type: "html", content: globalObjToPass[key].body },
          ];
        }

        for (let i = 0; i < oldApiCall.length; i++) {
          if (oldApiCall[i].id.toString() === id) {
            console.log("entro e setto");
            oldApiCall[i].name = body.body.name;
            oldApiCall[i].documents = globalObjToPass;
          }
        }

        localStorage.setItem("apiCall", JSON.stringify(oldApiCall));

        setPageTitle(globalObjTeams.en.heading);
        setSuccessToastActive(true);

        let path = window.location.pathname;
        path = path.slice(0, path.lastIndexOf("/"));
        history.push({ pathname: path, state: { saved: true } });
      } catch (e) {
        setErrorToastMessage(e.message);
        setErrorToastActive(true);
      }

      setCreateButtonDisabled(false);
      setSaving(false);
    } else {
      setCreateButtonDisabled(false);
      setSaving(false);
      setNameLengthError(true);
    }
  };

  // TO MODIFY BREADCRUMB STYLE WHILE SAVING
  useEffect(() => {
    let breadcrumb = document.getElementsByClassName(
      "Polaris-Breadcrumbs__Breadcrumb"
    )[0];
    let icon = document.getElementsByClassName("Polaris-Icon__Svg")[0];

    if (saving) {
      breadcrumb.style.cursor = "not-allowed";
      icon.style.opacity = "0.4";
    } else {
      breadcrumb.style.cursor = "pointer";
      icon.style.opacity = "1";
    }
  }, [saving]);

  // FUNCTION TO COPY ACCESS LINK IN CLIPBOARD
  const [clipboardToastActive, setClipboardToastActive] = useState(false);

  const toggleClipboardToast = useCallback(
    () =>
      setClipboardToastActive((clipboardToastActive) => !clipboardToastActive),
    []
  );

  const toggleClipboardToastActive = useCallback(
    () => setClipboardToastActive(true),
    []
  );

  const clipboardToastMarkup = clipboardToastActive ? (
    <Toast
      content={localize("CopiedToClipboard")}
      onDismiss={toggleClipboardToast}
    />
  ) : null;

  const copyToClipboard = () => {
    var copyText = document.getElementById("access-link");

    copyText.select();
    copyText.setSelectionRange(0, 99999);

    document.execCommand("copy");

    toggleClipboardToastActive();
  };

  // ERROR WHEN A TEXT WITH 4000 CHARACTERS OR MORE IS PASTED
  const [lengthToastActive, setLengthToastActive] = useState(false);

  const toggleLengthToastActive = useCallback(
    () => setLengthToastActive((lengthToastActive) => !lengthToastActive),
    []
  );

  const lengthToastMarkup = lengthToastActive ? (
    <Toast
      content={localize("ThePastedText")}
      onDismiss={toggleLengthToastActive}
      error
      duration={3500}
    />
  ) : null;

  // GENERATE QR CODE
  const qrCode = new QRCodeStyling(
    theme.colorScheme === "dark" ? qrCodeOptionsDark : qrCodeOptionsLight
  );

  const [qrActive, setQrActive] = useState(false);

  const handleQrChange = () => {
    setQrActive(!qrActive);
    if (!qrActive) {
      setTimeout(() => {
        qrCode.update({
          data: "https://aworld.org/join/team/" + uid,
        });
        qrCode.append(document.getElementById("qr-code"));
      }, 1);
    }
  };

  return (
    <Page
      title={pageTitle}
      breadcrumbs={[
        {
          content: "Overview",
          url: !saving ? "/dashboard" : window.location.pathname,
        },
      ]}
      primaryAction={{
        content: localize("SaveChanges"),
        disabled: createButtonDisabled,
        onAction: onSave,
        loading: saving,
      }}
      secondaryActions={[
        {
          content: localize("Cancel"),
          onAction: () => {
            let path = window.location.pathname;
            path = path.slice(0, path.lastIndexOf("/"));
            history.push(path);
          },
        },
      ]}
    >
      <Modal
        open={modalActive}
        onClose={handleModalClose}
        title={localize("AddOrRemoveLanguages")}
        primaryAction={{
          content: localize("Done"),
          onAction: handleLanguagesManaged,
        }}
      >
        <Modal.Section>
          <ChoiceList
            allowMultiple
            choices={[
              {
                label: localize("English"),
                value: "en",
              },
            ]}
            selected={["en"]}
            disabled
          />
          <ChoiceList
            allowMultiple
            choices={[
              {
                label: localize("Italian"),
                value: "it",
              },
              {
                label: localize("Spanish"),
                value: "es",
              },
              {
                label: localize("French"),
                value: "fr",
              },
            ]}
            selected={selectedChoices}
            onChange={handleChoicesSelectionChange}
          />
        </Modal.Section>
      </Modal>
      <Layout>
        <Layout.Section>
          <CSSTransition
            in={true}
            appear={true}
            timeout={1000}
            classNames="transform"
          >
            <Card
              title={localize("Details")}
              actions={[
                {
                  content: localize("AddOrRemoveLanguages"),
                  onAction: handleModalOpen,
                },
              ]}
            >
              <Tabs
                tabs={tabs}
                selected={selectedTab}
                onSelect={handleTabChange}
              ></Tabs>
              <Card.Section>
                <Stack vertical>
                  {selectedTab === 0 ? (
                    <TextField
                      type="text"
                      label={localize("Name")}
                      helpText={
                        !nameLengthError &&
                        localize("OnlyLettersNumbers") + " #?!-,_."
                      }
                      placeholder={"Name (" + tabs[selectedTab].content + ")"}
                      onBlur={() => {
                        setName(name.trim());
                      }}
                      error={
                        nameLengthError ? localize("TeamNameMustBe") : null
                      }
                      value={name}
                      onChange={nameChange}
                      clearButton={true}
                      onClearButtonClick={() => {
                        setName("");
                        setNameLengthError(false);
                      }}
                      maxLength={60}
                      showCharacterCount
                    />
                  ) : window.innerWidth < 439 ? (
                    <Stack vertical>
                      <TextField
                        type="text"
                        label={localize("Name")}
                        helpText={
                          !nameLengthError &&
                          localize("OnlyLettersNumbers") + " #?!-,_."
                        }
                        placeholder={"Name (" + tabs[selectedTab].content + ")"}
                        onBlur={() => {
                          setName(name.trim());
                        }}
                        error={
                          nameLengthError ? localize("TeamNameMustBe") : null
                        }
                        value={name}
                        onChange={nameChange}
                        clearButton={true}
                        onClearButtonClick={() => {
                          setName("");
                          setNameLengthError(false);
                        }}
                        maxLength={60}
                        showCharacterCount
                      />
                      <Button fullWidth onClick={copyNameFromEnglish}>
                        {localize("CopyFromEnglish")}
                      </Button>
                    </Stack>
                  ) : (
                    <Stack alignment="center" spacing="tight">
                      <Stack.Item fill>
                        <TextField
                          type="text"
                          label={localize("Name")}
                          helpText={
                            !nameLengthError &&
                            localize("OnlyLettersNumbers") + " #?!-,_."
                          }
                          placeholder={
                            "Name (" + tabs[selectedTab].content + ")"
                          }
                          onBlur={() => {
                            setName(name.trim());
                          }}
                          error={
                            nameLengthError ? localize("TeamNameMustBe") : null
                          }
                          value={name}
                          onChange={nameChange}
                          clearButton={true}
                          onClearButtonClick={() => {
                            setName("");
                            setNameLengthError(false);
                          }}
                          maxLength={60}
                          showCharacterCount
                        />
                      </Stack.Item>
                      <Stack.Item>
                        <Button fullWidth onClick={copyNameFromEnglish}>
                          {localize("CopyFromEnglish")}
                        </Button>
                      </Stack.Item>
                    </Stack>
                  )}
                  <p className="negative-margin-bottom">
                    {localize("Description")}
                  </p>
                  <Editor
                    toolbarClassName={
                      theme.colorScheme === "dark" && "rdw-editor-toolbar-dark"
                    }
                    onBlur={() => {
                      // to remove whitespaces before and after string
                      let currentContent = draftToHtml(
                        convertToRaw(editorState.getCurrentContent())
                      );
                      currentContent = currentContent.replace(/  +/g, " ");
                      currentContent = currentContent.replaceAll("&nbsp;", "");
                      const blocksFromHtml = htmlToDraft(currentContent);
                      const { contentBlocks, entityMap } = blocksFromHtml;
                      const contentState = ContentState.createFromBlockArray(
                        contentBlocks,
                        entityMap
                      );
                      setEditorState(
                        EditorState.createWithContent(contentState)
                      );
                    }}
                    editorState={editorState}
                    placeholder={
                      "Description (" + tabs[selectedTab].content + ")"
                    }
                    onEditorStateChange={setEditorState}
                    handleBeforeInput={(val) => {
                      const textLength = editorState
                        .getCurrentContent()
                        .getPlainText().length;
                      if (val && textLength >= 4000) {
                        return "handled";
                      }
                      return "not-handled";
                    }}
                    handlePastedText={(val) => {
                      const textLength = editorState
                        .getCurrentContent()
                        .getPlainText().length;
                      if (val.length + textLength >= 4000) {
                        toggleLengthToastActive();
                        return true;
                      } else {
                        return false;
                      }
                    }}
                    toolbar={{
                      options: ["inline", "list", "history", "link"],
                      inline: {
                        options: ["bold", "italic", "underline"],
                      },
                      list: {
                        options: ["unordered"],
                      },
                    }}
                  />
                </Stack>
              </Card.Section>
            </Card>
          </CSSTransition>
          <CSSTransition
            in={true}
            appear={true}
            timeout={1000}
            classNames="transform"
          >
            <Card title={localize("CoverImage")}>
              <Card.Section>
                <Stack vertical>
                  <DropZone
                    allowMultiple={false}
                    type="image"
                    onDrop={handleDropZoneDrop}
                    accept="image/*"
                  >
                    {fileUpload}
                    <div
                      style={{
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {croppedImageVisual}
                    </div>
                  </DropZone>
                  <Modal
                    open={modalForCroppingActive}
                    onClose={handleModalForCroppingClose}
                    title="Crop image"
                    primaryAction={
                      doneButtonDisabled
                        ? {
                            content: <Spinner size="small" />,
                            disabled: true,
                          }
                        : {
                            content: "Done",
                            onAction: takeCroppedImage,
                          }
                    }
                    secondaryActions={[
                      {
                        content: "Cancel",
                        onAction: handleModalForCroppingClose,
                      },
                    ]}
                  >
                    <Modal.Section>
                      <div className="cropper-container">
                        <Cropper
                          image={imageSrc}
                          crop={crop}
                          zoom={zoom}
                          aspect={16 / 9}
                          onCropChange={setCrop}
                          onCropComplete={onCropComplete}
                          onZoomChange={setZoom}
                        />
                      </div>
                      <div
                        className="slider-container"
                        style={{
                          backgroundColor:
                            theme.colorScheme === "dark" ? "#202123" : "white",
                        }}
                      >
                        <Slider
                          value={zoom}
                          min={1}
                          max={3}
                          step={0.1}
                          aria-labelledby="Zoom"
                          onChange={(e, zoom) => setZoom(zoom)}
                        />
                      </div>
                    </Modal.Section>
                  </Modal>
                </Stack>
              </Card.Section>
            </Card>
          </CSSTransition>
          <CSSTransition
            in={true}
            appear={true}
            timeout={1000}
            classNames="transform"
          >
            <Card title={localize("Logo")}>
              <Card.Section>
                <Stack vertical>
                  <DropZone
                    allowMultiple={false}
                    type="image"
                    onDrop={handleDropZoneDropLogo}
                    accept="image/*"
                  >
                    {fileUploadLogo}
                    <div
                      style={{
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {croppedImageVisualLogo}
                    </div>
                  </DropZone>
                  <Modal
                    open={modalForCroppingActiveLogo}
                    onClose={handleModalForCroppingCloseLogo}
                    title={localize("CropImage")}
                    primaryAction={
                      doneButtonDisabledLogo
                        ? {
                            content: <Spinner size="small" />,
                            disabled: true,
                          }
                        : {
                            content: localize("Done"),
                            onAction: takeCroppedImageLogo,
                          }
                    }
                    secondaryActions={[
                      {
                        content: localize("Cancel"),
                        onAction: handleModalForCroppingCloseLogo,
                      },
                    ]}
                  >
                    <Modal.Section>
                      <div className="cropper-container">
                        <Cropper
                          image={imageSrcLogo}
                          crop={cropLogo}
                          zoom={zoomLogo}
                          aspect={1 / 1}
                          onCropChange={setCropLogo}
                          onCropComplete={onCropCompleteLogo}
                          onZoomChange={setZoomLogo}
                        />
                      </div>
                      <div
                        className="slider-container"
                        style={{
                          backgroundColor:
                            theme.colorScheme === "dark" ? "#202123" : "white",
                        }}
                      >
                        <Slider
                          value={zoomLogo}
                          min={1}
                          max={3}
                          step={0.1}
                          aria-labelledby="Zoom"
                          onChange={(e, zoom) => setZoomLogo(zoom)}
                        />
                      </div>
                    </Modal.Section>
                  </Modal>
                </Stack>
              </Card.Section>
            </Card>
          </CSSTransition>
        </Layout.Section>
        <Layout.Section secondary>
          <CSSTransition
            in={true}
            appear={true}
            timeout={1000}
            classNames="transform"
          >
            <Card title={localize("Summary")} sectioned>
              <Stack vertical spacing="extraTight">
                <TextContainer>
                  {localize("Name")}:{" "}
                  <TextStyle variation="strong">{pageTitle}</TextStyle>
                </TextContainer>
                <TextContainer>
                  {localize("Languages")}:{" "}
                  <TextStyle variation="strong">
                    {selectedChoices.map((value, index) => {
                      if (index === selectedChoices.length - 1) {
                        return value.toUpperCase();
                      } else {
                        return value.toUpperCase() + ", ";
                      }
                    })}
                  </TextStyle>
                </TextContainer>
                <TextContainer>
                  {localize("CreatedAt")}:{" "}
                  <TextStyle variation="strong">{createdAt}</TextStyle>
                </TextContainer>
                <TextContainer>
                  {localize("UpdatedAt")}:{" "}
                  <TextStyle variation="strong">{updatedAt}</TextStyle>
                </TextContainer>
              </Stack>
            </Card>
          </CSSTransition>
          {accessType === "public" && (
            <CSSTransition
              in={true}
              appear={true}
              timeout={1000}
              classNames="transform"
            >
              <Card title={localize("AccessLink")} sectioned>
                <Stack spacing="tight">
                  <input
                    className={theme.colorScheme === "dark" && "input-dark"}
                    readOnly
                    id="access-link"
                    type="text"
                    value={"https://aworld.org/join/team/" + uid}
                    style={
                      window.innerWidth < 804
                        ? {
                            fontWeight: "bold",
                            fontSize: 14,
                            border: "none",
                            cursor: "default",
                            width: "32vh",
                            maxWidth: 480,
                          }
                        : {
                            fontWeight: "bold",
                            fontSize: 14,
                            border: "none",
                            cursor: "default",
                            width: "17vw",
                            maxWidth: 200,
                          }
                    }
                  />
                  <div
                    title={localize("CopyToClipboard")}
                    onClick={copyToClipboard}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <Icon source={ClipboardMinor} color="base" />
                  </div>
                  <div
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={handleQrChange}
                  >
                    <Icon source={QrCode} color="base" />
                  </div>
                </Stack>
              </Card>
            </CSSTransition>
          )}
          {!countersLoading && countersArray.length > 0 ? (
            <CSSTransition
              in={true}
              appear={true}
              timeout={1000}
              classNames="transform"
            >
              <Card title={localize("Impact")}>
                {countersArray.map((item) => (
                  <Card.Section
                    title={item.initiativeName}
                    key={item.initiativeName}
                  >
                    <TextContainer>
                      {localize("ActionsTaken")}:{" "}
                      <TextStyle variation="strong">
                        {item.actionsCounter}
                      </TextStyle>
                    </TextContainer>
                    <TextContainer>
                      {localize("PeoplePartecipating")}:{" "}
                      <TextStyle variation="strong">
                        {item.partecipantsCounter}
                      </TextStyle>
                    </TextContainer>
                    <TextContainer>
                      {localize("Attendees")}:{" "}
                      <TextStyle variation="strong">
                        {item.attendeesCounter}
                      </TextStyle>
                    </TextContainer>
                  </Card.Section>
                ))}
              </Card>
            </CSSTransition>
          ) : countersLoading ? (
            <CSSTransition
              in={true}
              appear={true}
              timeout={1000}
              classNames="transform"
            >
              <Card sectioned title={localize("Impact")}>
                <div style={{ paddingBottom: 8, paddingTop: 4 }}>
                  <SkeletonBodyText lines={5} />
                </div>
              </Card>
            </CSSTransition>
          ) : (
            <CSSTransition
              in={true}
              appear={true}
              timeout={1000}
              classNames="transform"
            >
              <Card sectioned title={localize("Impact")}>
                <TextContainer>
                  {impactText
                    ? localize(impactText)
                    : localize("YouDoNotSupportInitiatives")}
                </TextContainer>
              </Card>
            </CSSTransition>
          )}
        </Layout.Section>
      </Layout>
      <Modal
        open={qrActive}
        onClose={handleQrChange}
        title={localize("AccessQRCode")}
        primaryAction={{
          content: "Download",
          onAction: () => {
            let lightQrCode = new QRCodeStyling(qrCodeOptionsLight);
            lightQrCode.update({
              data: "https://aworld.org/join/team/" + uid,
            });
            lightQrCode.download({
              name: `${localize("JoinTeam")} ${name}`,
              extension: "png",
            });
          },
        }}
        secondaryActions={[
          {
            content: localize("Close"),
            onAction: handleQrChange,
          },
        ]}
      >
        <Modal.Section>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div id="qr-code" />
          </div>
        </Modal.Section>
      </Modal>
      {successToastMarkup}
      {errorToastMarkup}
      {clipboardToastMarkup}
      {lengthToastMarkup}
    </Page>
  );
};

export default TeamEdit;
