import React, { useEffect, useState, useCallback } from "react"
import {
  Caption,
  Card,
  Loading,
  Modal,
  Page,
  ResourceItem,
  ResourceList,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  Stack,
  TextStyle,
  TextField,
  Icon,
  DatePicker,
  TextContainer,
  Spinner,
  Button,
  Tabs,
} from "@shopify/polaris"
import { CalendarMinor } from "@shopify/polaris-icons"
import { useParams } from "react-router"

// animation
import { CSSTransition } from "react-transition-group"

// api
import Api from "../../../api"

// localizer
import { useLocalizer } from "reactjs-localizer"

// csv export
import { CSVLink } from "react-csv"

// utilities
import { parseDate } from "./utils"

const Challenge = () => {
  const { id, teamId } = useParams()
  const { localize } = useLocalizer()

  // UTILITIES
  const dateToString = (date) => {
    let months = [
      localize("JAN"),
      localize("FEB"),
      localize("MAR"),
      localize("APR"),
      localize("MAY"),
      localize("JUN"),
      localize("JUL"),
      localize("AUG"),
      localize("SEP"),
      localize("OCT"),
      localize("NOV"),
      localize("DEC"),
    ]

    let dateToPrint =
      date.getDate() + " " + months[date.getMonth()] + " " + date.getFullYear()

    return dateToPrint
  }

  // TABS
  const [selectedTab, setSelectedTab] = useState(
    localStorage.getItem("leaderboardTab")
      ? parseInt(localStorage.getItem("leaderboardTab"))
      : 0
  )
  const handleTabChange = useCallback(
    (selectedTabIndex) => setSelectedTab(selectedTabIndex),
    []
  )
  const tabs = [
    {
      id: "global",
      content: localize("Global"),
      accessibilityLabel: localize("Global"),
    },
    {
      id: "per-group",
      content: localize("Groups"),
      accessibilityLabel: localize("Groups"),
    },
  ]

  useEffect(() => {
    localStorage.setItem("leaderboardTab", selectedTab.toString())
  }, [selectedTab])

  // LOADING
  const [loading, setLoading] = useState(true)

  // FETCH CHALLENGE
  const [challengeLoading, setChallengeLoading] = useState(true)
  const [challengeName, setChallengeName] = useState()
  const [challengeMetric, setChallengeMetric] = useState()
  const [challengeStart, setChallengeStart] = useState()
  const [challengeEnd, setChallengeEnd] = useState()
  const [challengeMembersCount, setChallengeMembersCount] = useState()
  const [teamMembersCount, setTeamMembersCount] = useState()

  useEffect(() => {
    const fetchChallenge = async () => {
      let calls = [
        Api.challenges.getChallenge(id),
        Api.challenges.getChallengeMembersCount(id),
        Api.teams.getTeamMembers(teamId),
      ]
      const data = await Promise.all(calls)

      setChallengeName(data[0].item.data.heading)
      setChallengeMetric(
        data[0].item.metrics[0].unit + " " + data[0].item.metrics[0].name
      )
      localStorage.setItem(
        "currentChallengeMetric",
        data[0].item.metrics[0].unit + " " + data[0].item.metrics[0].name
      )
      setChallengeStart(new Date(data[0].item.starts_at))

      if (new Date(data[0].item.ends_at) < new Date()) {
        setSelectedDates({
          start: new Date(data[0].item.ends_at),
          end: new Date(data[0].item.ends_at),
        })
        setPickedDate(new Date(data[0].item.ends_at))
        setChallengeEnd(new Date(data[0].item.ends_at))
      }

      setChallengeMembersCount(data[1].total)
      setTeamMembersCount(data[2].membersCount)

      setChallengeLoading(false)
    }

    fetchChallenge()
  }, [])

  // FETCH LEADERBOARD
  const [leaderboardListLoading, setLeaderboardListLoading] = useState(false)
  const [leaderboard, setLeaderboard] = useState()
  const [previousDayLeaderboard, setPreviousDayLeaderboard] = useState()
  const [nextToken, setNextToken] = useState()
  const [previousDayNextToken, setPreviousDayNextToken] = useState()

  const fetchLeaderboard = async () => {
    let yesterday = new Date(selectedDates.start)
    yesterday.setDate(yesterday.getDate() - 1)

    let calls = [
      Api.challenges.getLeaderboard(id, parseDate(selectedDates.start)),
      Api.challenges.getLeaderboard(id, parseDate(yesterday)),
    ]

    const result = await Promise.all(calls)

    setLeaderboard(result[0].items)
    setNextToken(result[0].next)
    setPreviousDayLeaderboard(result[1].items)
    setPreviousDayNextToken(result[1].next)

    setLoading(false)
    setLeaderboardListLoading(false)
  }

  const fetchLeaderboardNext = async () => {
    setLeaderboardListLoading(true)

    let yesterday = new Date(selectedDates.start)
    yesterday.setDate(yesterday.getDate() - 1)

    let calls = [
      Api.challenges.getLeaderboard(
        id,
        parseDate(selectedDates.start),
        nextToken
      ),
      Api.challenges.getLeaderboard(
        id,
        parseDate(yesterday),
        previousDayNextToken
      ),
    ]

    const result = await Promise.all(calls)

    setLeaderboard([...leaderboard, ...result[0].items])
    setNextToken(result[0].next)
    setPreviousDayLeaderboard([...previousDayLeaderboard, ...result[1].items])
    setPreviousDayNextToken(result[1].next)

    setLeaderboardListLoading(false)
  }

  useEffect(() => {
    if (!challengeLoading) {
      fetchLeaderboard()
    }
    //eslint-disable-next-line
  }, [challengeLoading])

  // FETCH GROUPS LEADERBOARD
  const [currentTeam, setCurrentTeam] = useState()
  const [groupsLeaderboardsListLoading, setGroupsLeaderboardsListLoading] =
    useState(true)
  const [groupsLeaderboards, setGroupsLeaderboards] = useState([])

  const fetchGroupsLeaderboard = async (team) => {
    setCsvButtonLoadingGroups(true)

    const { items } = await Api.challenges.getGroupsLeaderboard(
      id,
      parseDate(selectedDates.start)
    )

    for (let i = 0; i < team.groups.length; i++) {
      for (let j = 0; j < items.length; j++) {
        if (items[j].group === team.groups[i].id) {
          items[j]["group_name"] = team.groups[i].name
        }
      }
    }

    let dataToSet = [
      [localize("Position"), localize("GroupName"), challengeMetric],
    ]
    for (let i = 0; i < items.length; i++) {
      dataToSet.push([
        i + 1,
        items[i].group_name,
        Math.round(items[i].total_amount * 100) / 100,
      ])
    }
    setCsvDataGroups(dataToSet)

    setGroupsLeaderboards(items)
    setGroupsLeaderboardsListLoading(false)
  }

  useEffect(() => {
    if (!challengeLoading) {
      const teamsList = JSON.parse(localStorage.getItem("apiCall"))
      const currentTeamToSet = teamsList.filter(
        (item) => item.id.toString() === teamId
      )[0]
      setCurrentTeam(currentTeamToSet)

      fetchGroupsLeaderboard(currentTeamToSet)
    }
    //eslint-disable-next-line
  }, [challengeLoading])

  // DATE PICKER
  const [pickedDate, setPickedDate] = useState(new Date())

  const [{ month, year }, setDate] = useState({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  })

  const [selectedDates, setSelectedDates] = useState({
    start: new Date(),
    end: new Date(),
  })

  const handleMonthChange = useCallback(
    (month, year) => setDate({ month, year }),
    []
  )

  // MODAL TO PICK DATE
  const [active, setActive] = useState(false)

  const handleChange = useCallback(() => setActive(!active), [active])

  const reloadLeaderboard = async () => {
    setPickedDate(selectedDates.start)
    setLeaderboardListLoading(true)
    setGroupsLeaderboardsListLoading(true)
    handleChange()

    fetchLeaderboard()
    fetchGroupsLeaderboard(currentTeam)
  }

  // DOWNLOAD AS CSV
  const [csvButtonLoadingGlobal, setCsvButtonLoadingGlobal] = useState(true)
  const [csvButtonLoadingGroups, setCsvButtonLoadingGroups] = useState(true)
  const [csvDataGlobal, setCsvDataGlobal] = useState([])
  const [csvDataGroups, setCsvDataGroups] = useState([])

  useEffect(() => {
    const fetchDataForCsv = async () => {
      setCsvButtonLoadingGlobal(true)

      const { items } = await Api.challenges.getLeaderboard(
        id,
        parseDate(pickedDate),
        null,
        500
      )

      let dataToSet = [
        [
          localize("Position"),
          localize("FirstNameOnly"),
          localize("LastNameOnly"),
          challengeMetric,
        ],
      ]
      for (let i = 0; i < items.length; i++) {
        dataToSet.push([
          i + 1,
          items[i].first_name,
          items[i].last_name,
          Math.round(items[i].total_amount * 100) / 100,
        ])
      }
      setCsvDataGlobal(dataToSet)

      setCsvButtonLoadingGlobal(false)
    }

    if (challengeLoading) {
      fetchDataForCsv()
    }
  }, [pickedDate, challengeLoading])

  useEffect(() => {
    if (challengeMetric && csvDataGroups.length > 0) {
      csvDataGroups[0][2] = challengeMetric
      setCsvButtonLoadingGroups(false)
    }
  }, [challengeMetric, csvDataGroups])

  return loading ? (
    <>
      <Loading />
      <SkeletonPage>
        <Stack vertical>
          <CSSTransition
            in={true}
            appear={true}
            timeout={1000}
            classNames="transform"
          >
            <Card sectioned>
              <div style={{ paddingBottom: 8 }}>
                <SkeletonBodyText lines={2} />
              </div>
            </Card>
          </CSSTransition>
          <CSSTransition
            in={true}
            appear={true}
            timeout={1000}
            classNames="transform"
          >
            <Stack distribution="fillEvenly">
              <Stack.Item fill>
                <Card sectioned title="Members number">
                  <div style={{ paddingTop: 6, paddingBottom: 6 }}>
                    <SkeletonBodyText lines={1} />
                  </div>
                </Card>
              </Stack.Item>
              <Stack.Item fill>
                <Card sectioned title="Percentage of Team members">
                  <div style={{ paddingTop: 6, paddingBottom: 6 }}>
                    <SkeletonBodyText lines={1} />
                  </div>
                </Card>
              </Stack.Item>
            </Stack>
          </CSSTransition>
          <CSSTransition
            in={true}
            appear={true}
            timeout={1000}
            classNames="transform"
          >
            <Card>
              <Tabs tabs={tabs} selected={selectedTab} fitted />
              <Card.Section>
                <div style={{ paddingBottom: 2 }}>
                  <SkeletonBodyText lines={10} />
                </div>
              </Card.Section>
            </Card>
          </CSSTransition>
        </Stack>
      </SkeletonPage>
    </>
  ) : (
    <Page
      title={
        challengeLoading ? (
          <SkeletonDisplayText />
        ) : (
          challengeName + " | " + localize("LeaderboardOnly")
        )
      }
      primaryAction={
        selectedTab === 0
          ? {
              content: (
                <CSVLink
                  data={csvDataGlobal}
                  filename={`${localize(
                    "GlobalLeaderboard"
                  )} - ${challengeName} - ${dateToString(pickedDate)}.csv`}
                  target="_blank"
                  style={{
                    textDecoration: "none",
                    color: "white",
                    paddingTop: 10,
                    paddingBottom: 10,
                  }}
                >
                  {localize("DownloadGlobalAsCSV")}
                </CSVLink>
              ),
              loading: csvButtonLoadingGlobal,
              disabled: leaderboard.length === 0,
            }
          : {
              content: (
                <CSVLink
                  data={csvDataGroups}
                  filename={`${localize(
                    "GroupsLeaderboard"
                  )} - ${challengeName} - ${dateToString(pickedDate)}.csv`}
                  target="_blank"
                  style={{
                    textDecoration: "none",
                    color: "white",
                    paddingTop: 10,
                    paddingBottom: 10,
                  }}
                >
                  {localize("DownloadGroupsAsCSV")}
                </CSVLink>
              ),
              loading: csvButtonLoadingGroups,
              disabled: groupsLeaderboards.length === 0,
            }
      }
      breadcrumbs={[
        {
          content: "Team",
          url: window.location.pathname.slice(
            0,
            window.location.pathname.indexOf("challenge") - 1
          ),
        },
      ]}
    >
      <Stack vertical>
        <Card sectioned>
          <TextField
            prefix={<Icon source={CalendarMinor} color="base" />}
            suffix={
              pickedDate.toLocaleDateString() ===
              new Date().toLocaleDateString()
                ? `${localize("LastUpdate")}: ${localize("TodayAt")} 04:00`
                : null
            }
            value={
              dateToString(pickedDate) === dateToString(new Date())
                ? localize("Today")
                : dateToString(pickedDate)
            }
            readOnly
            onFocus={handleChange}
          />
        </Card>
        <Stack distribution="fillEvenly">
          <Stack.Item fill>
            <Card sectioned title="Members number">
              {challengeMembersCount}
            </Card>
          </Stack.Item>
          <Stack.Item fill>
            <Card sectioned title="Percentage of Team members">
              {Math.round(
                (challengeMembersCount / teamMembersCount) * 100 * 10
              ) / 10 > 100 ? "100" : Math.round(
                (challengeMembersCount / teamMembersCount) * 100 * 10
              ) / 10}
              %
            </Card>
          </Stack.Item>
        </Stack>
        <Card>
          <Tabs
            tabs={tabs}
            selected={selectedTab}
            onSelect={handleTabChange}
            fitted
          />
          {selectedTab === 0 ? (
            <Card.Section>
              {leaderboard.length === 0 ? (
                <Stack vertical>
                  <TextContainer>
                    {localize("NoDataForTheSelectedDay")}
                  </TextContainer>
                  <div
                    style={{
                      height: 20,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {leaderboardListLoading && <Spinner />}
                  </div>
                </Stack>
              ) : (
                <Stack vertical>
                  <Card>
                    <ResourceList
                      resourceName={{ singular: "user", plural: "users" }}
                      items={leaderboard}
                      loading={leaderboardListLoading}
                      renderItem={(item, id, index) => {
                        const {
                          uid,
                          first_name,
                          last_name,
                          user_sub,
                          total_amount,
                        } = item

                        let userYesterdayPosiiton =
                          previousDayLeaderboard.findIndex(
                            (item) => item.user_sub === user_sub
                          ) + 1
                        if (userYesterdayPosiiton !== 0) {
                          userYesterdayPosiiton =
                            (index + 1 - userYesterdayPosiiton) * -1
                        }

                        return (
                          <ResourceItem id={uid}>
                            <Stack alignment="center" spacing="loose">
                              <div
                                style={{
                                  marginRight: index + 1 < 10 ? 7 : 0,
                                  fontSize: 15,
                                }}
                              >
                                <TextStyle variation="strong">
                                  {index + 1}
                                </TextStyle>
                              </div>
                              <div
                                style={{
                                  width: 30,
                                  height: 30,
                                  borderRadius: 15,
                                  backgroundImage: `url(http://cdn.aworld.io/users/${user_sub}/profile.jpg), url(https://cdn.aworld.io/users/default/profile.jpg)`,
                                  backgroundSize: "cover",
                                }}
                              />
                              <Stack vertical spacing="none">
                                <TextStyle variation="strong">
                                  {first_name + " " + last_name}
                                </TextStyle>
                                <Caption>
                                  <TextStyle variation="subdued">
                                    {uid}
                                  </TextStyle>
                                </Caption>
                              </Stack>
                              <Stack.Item fill>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                    fontSize: 15,
                                    fontWeight: 300,
                                  }}
                                >
                                  <div style={{ marginRight: 6 }}>
                                    {Math.round(total_amount * 100) / 100}
                                  </div>
                                  <div
                                    style={{
                                      marginRight: 14,
                                      fontSize: 10,
                                      marginTop: 4,
                                    }}
                                  >
                                    <TextStyle variation="subdued">
                                      {challengeMetric}
                                    </TextStyle>
                                  </div>
                                  {pickedDate.toLocaleDateString() ===
                                    new Date().toLocaleDateString() && (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                      }}
                                    >
                                      {userYesterdayPosiiton > 0 ? (
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          height="24px"
                                          viewBox="0 0 24 24"
                                          width="24px"
                                          fill="#008060"
                                        >
                                          <path
                                            d="M0 0h24v24H0V0z"
                                            fill="none"
                                          />
                                          <path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14l-6-6z" />
                                        </svg>
                                      ) : userYesterdayPosiiton < 0 ? (
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          height="24px"
                                          viewBox="0 0 24 24"
                                          width="24px"
                                          fill="#d82c0c"
                                        >
                                          <path
                                            d="M24 24H0V0h24v24z"
                                            fill="none"
                                            opacity=".87"
                                          />
                                          <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z" />
                                        </svg>
                                      ) : (
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          height="24px"
                                          viewBox="0 0 24 24"
                                          width="24px"
                                          fill="#cccccc"
                                          style={{ marginBottom: 12 }}
                                        >
                                          <path
                                            d="M0 0h24v24H0V0z"
                                            fill="none"
                                          />
                                          <path d="M6 19h12v2H6v-2z" />
                                        </svg>
                                      )}
                                      {userYesterdayPosiiton !== 0 && (
                                        <div
                                          style={{
                                            fontSize: 11,
                                            marginTop: -8,
                                          }}
                                        >
                                          {userYesterdayPosiiton
                                            .toString()
                                            .replace("-", "")}
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </Stack.Item>
                            </Stack>
                          </ResourceItem>
                        )
                      }}
                    />
                  </Card>
                  <Button
                    disabled={!nextToken}
                    loading={leaderboardListLoading}
                    fullWidth
                    onClick={nextToken ? fetchLeaderboardNext : null}
                  >
                    {localize("LoadMore")}
                  </Button>
                </Stack>
              )}
            </Card.Section>
          ) : (
            <Card.Section>
              {groupsLeaderboards.length === 0 ? (
                <Stack vertical>
                  <TextContainer>
                    {localize("NoDataForTheSelectedDay")}
                  </TextContainer>
                  <div
                    style={{
                      height: 20,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {groupsLeaderboardsListLoading && <Spinner />}
                  </div>
                </Stack>
              ) : (
                <Stack vertical>
                  <Card>
                    <ResourceList
                      resourceName={{ singular: "group", plural: "groups" }}
                      items={groupsLeaderboards}
                      loading={groupsLeaderboardsListLoading}
                      renderItem={(item, id, index) => {
                        const { group, group_name, total_amount } = item

                        return (
                          <ResourceItem
                            id={group}
                            url={
                              window.location.pathname +
                              "/group/" +
                              group +
                              "/" +
                              group_name
                            }
                          >
                            <div style={{ paddingTop: 2, marginBottom: -2 }}>
                              <Stack alignment="center" spacing="loose">
                                <div
                                  style={{
                                    marginRight: index + 1 < 10 ? 7 : 0,
                                    fontSize: 15,
                                    marginBottom: 4,
                                  }}
                                >
                                  <TextStyle variation="strong">
                                    {index + 1}
                                  </TextStyle>
                                </div>
                                <img
                                  src="https://cdn.aworld.io/assets/placeholder_team_icon.png"
                                  style={{
                                    width: 30,
                                    height: 30,
                                    borderRadius: 15,
                                    margin: 0,
                                    marginTop: 1,
                                  }}
                                />
                                <div style={{ marginBottom: 4 }}>
                                  <TextStyle variation="strong">
                                    {group_name}
                                  </TextStyle>
                                </div>
                                <Stack.Item fill>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                      fontSize: 15,
                                      fontWeight: 300,
                                      marginBottom: 2,
                                    }}
                                  >
                                    <div style={{ marginRight: 6 }}>
                                      {Math.round(total_amount * 100) / 100}
                                    </div>
                                    <div
                                      style={{
                                        marginRight: 14,
                                        fontSize: 10,
                                        marginTop: 2,
                                      }}
                                    >
                                      <TextStyle variation="subdued">
                                        {`${localize(
                                          "Average"
                                        )} ${challengeMetric}`}
                                      </TextStyle>
                                    </div>
                                  </div>
                                </Stack.Item>
                              </Stack>
                            </div>
                          </ResourceItem>
                        )
                      }}
                    />
                  </Card>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Caption>
                      <TextStyle variation="subdued">
                        {localize("ClickOnAGroupToSeeItsLeaderboard")}
                      </TextStyle>
                    </Caption>
                  </div>
                </Stack>
              )}
            </Card.Section>
          )}
        </Card>
      </Stack>
      <Modal
        open={active}
        onClose={handleChange}
        title={localize("SelectDate")}
        primaryAction={{
          content: localize("Select"),
          onAction: reloadLeaderboard,
        }}
        secondaryActions={[
          {
            content: localize("Cancel"),
            onAction: handleChange,
          },
        ]}
      >
        <Modal.Section>
          <DatePicker
            month={month}
            year={year}
            onChange={setSelectedDates}
            onMonthChange={handleMonthChange}
            selected={selectedDates}
            disableDatesAfter={challengeEnd ? challengeEnd : new Date()}
            disableDatesBefore={challengeStart}
          />
        </Modal.Section>
      </Modal>
    </Page>
  )
}

export default Challenge
