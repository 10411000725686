import React from 'react'

// Polaris Components
import { Frame } from '@shopify/polaris'

// Stylesheets
import '@shopify/polaris/dist/styles.css'
import './styles.css'

const AuthLayout = ({ children }) => {
  return (
    <Frame>{children}</Frame>
  )
}

export default AuthLayout
