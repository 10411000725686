import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

// localizer
import { LocaleProvider } from "reactjs-localizer";

ReactDOM.render(
  <LocaleProvider>
    <script
      async
      src={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_API_KEY}&libraries=places`}
    ></script>
    <App />
  </LocaleProvider>,
  document.getElementById("root")
);
