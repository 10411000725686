import React from "react";
import {
  Card,
  Layout,
  Stack,
  Heading,
  TextContainer,
  SkeletonPage,
  SkeletonBodyText,
  SkeletonThumbnail,
  Loading,
} from "@shopify/polaris";

// animation
import { CSSTransition } from "react-transition-group";

// style
import "./styles.css";

// localizer
import { useLocalizer } from "reactjs-localizer";

const EventSkeleton = () => {
  const { localize } = useLocalizer();

  return (
    <div className="skeleton-container-i">
      <Loading />
      <SkeletonPage breadcrumbs={window.innerWidth > 425 ? false : true}>
        <Layout>
          <Layout.Section>
            <CSSTransition
              in={true}
              appear={true}
              timeout={1000}
              classNames="transform"
            >
              <Card>
                <Card.Section flush>
                  <div className="skeleton-card-image-i"></div>
                </Card.Section>
                <Card.Section subdued>
                  <TextContainer>
                    <div className="about-this-event" style={{ paddingTop: 5 }}>
                      <Heading>{localize("AboutThisInitiative")}</Heading>
                    </div>
                    <div
                      style={{
                        paddingTop: 6,
                        paddingBottom: 6,
                      }}
                    >
                      {window.innerWidth < 426 ? (
                        <SkeletonBodyText lines={10} />
                      ) : (
                        <SkeletonBodyText lines={6} />
                      )}
                    </div>
                  </TextContainer>
                </Card.Section>
              </Card>
            </CSSTransition>
          </Layout.Section>
          <Layout.Section secondary>
            <CSSTransition
              in={true}
              appear={true}
              timeout={1000}
              classNames="transform"
            >
              <Card sectioned title={localize("GeneralInformation")}>
                <div style={{ paddingBottom: 12 }}>
                  <SkeletonBodyText lines={7} />
                </div>
              </Card>
            </CSSTransition>
            <CSSTransition
              in={true}
              appear={true}
              timeout={1000}
              classNames="transform"
            >
              <Card sectioned title={localize("ActionsOfTheEvent")}>
                <Card sectioned>
                  <Stack>
                    <SkeletonThumbnail size="small" />
                  </Stack>
                </Card>
              </Card>
            </CSSTransition>
          </Layout.Section>
        </Layout>
      </SkeletonPage>
    </div>
  );
};

export default EventSkeleton;
