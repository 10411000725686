import React from "react"
import {
  Card,
  Layout,
  Stack,
  Heading,
  TextContainer,
  ResourceList,
  ResourceItem,
  Icon,
  SkeletonPage,
  SkeletonBodyText,
  SkeletonThumbnail,
  Loading,
  TextField,
  Button,
  SkeletonDisplayText,
} from "@shopify/polaris"
import { SearchMinor, FilterMajor } from "@shopify/polaris-icons"

// animation
import { CSSTransition } from "react-transition-group"

// style
import "./styles.css"

// localizer
import { useLocalizer } from "reactjs-localizer"

// plus icon
import plusIcon from "../../images/CirclePlusMinorBlack.svg"

const InitiativeSkeleton = () => {
  const { localize } = useLocalizer()

  return (
    <div className="skeleton-container-i">
      <Loading />
      <SkeletonPage
        primaryAction={window.innerWidth < 426 ? false : true}
        breadcrumbs={window.innerWidth > 425 ? false : true}
      >
        {window.innerWidth < 376 && (
          <div style={{ marginLeft: 20, marginBottom: 16, marginTop: -10 }}>
            <SkeletonDisplayText />
          </div>
        )}
        <Layout>
          <Layout.Section>
            <CSSTransition
              in={true}
              appear={true}
              timeout={1000}
              classNames="transform"
            >
              <Card>
                <Card.Section flush>
                  <div className="skeleton-card-image-i"></div>
                </Card.Section>
                <Card.Section subdued>
                  <TextContainer>
                    <div className="about-this-event" style={{ paddingTop: 5 }}>
                      <Heading>{localize("WhatsThisAllAbout")}</Heading>
                    </div>
                    <div
                      style={{
                        paddingTop: 6,
                        paddingBottom: 6,
                      }}
                    >
                      <SkeletonBodyText lines={5} />
                    </div>
                    <Heading>{localize("HowCanIGetInvolved")}</Heading>
                    <div
                      style={{
                        paddingTop: 6,
                        paddingBottom: 6,
                      }}
                    >
                      <SkeletonBodyText lines={5} />
                    </div>
                    <Heading>{localize("WhenCanITakePart")}</Heading>
                    <div
                      style={{
                        paddingTop: 6,
                        paddingBottom: 6,
                      }}
                    >
                      <SkeletonBodyText lines={5} />
                    </div>
                  </TextContainer>
                </Card.Section>
              </Card>
            </CSSTransition>
            <CSSTransition
              in={true}
              appear={true}
              timeout={1000}
              classNames="transform"
            >
              <Card
                sectioned
                title={localize("Events")}
                actions={[
                  {
                    content: (
                      <div style={{ cursor: "default" }}>
                        <Button disabled>
                          <Stack alignment="center" spacing="tight">
                            <div
                              style={{
                                width: 20,
                                height: 20,
                                background: `url(${plusIcon}) no-repeat scroll -2px`,
                              }}
                            />
                            <TextContainer>
                              <div id="add-event-text">
                                {localize("AddEvent")}
                              </div>
                            </TextContainer>
                          </Stack>
                        </Button>
                      </div>
                    ),
                  },
                ]}
              >
                <div className="resource-list">
                  <Stack vertical spacing="loose">
                    <Card>
                      <ResourceList
                        resourceName={{
                          singular: "customer",
                          plural: "customers",
                        }}
                        filterControl={
                          <Stack>
                            <Stack.Item fill>
                              <TextField
                                disabled={true}
                                clearButton
                                placeholder={localize("Search")}
                                prefix={<Icon source={SearchMinor} />}
                              />
                            </Stack.Item>
                            <Stack.Item>
                              <Button fullWidth disabled>
                                <Stack alignment="center" spacing="tight">
                                  <Icon source={FilterMajor} />
                                  <TextContainer>
                                    {localize("FilterByProximity")}
                                  </TextContainer>
                                </Stack>
                              </Button>
                            </Stack.Item>
                          </Stack>
                        }
                        items={[
                          {
                            id: 1,
                          },
                          {
                            id: 2,
                          },
                          {
                            id: 3,
                          },
                          {
                            id: 4,
                          },
                          {
                            id: 5,
                          },
                          {
                            id: 6,
                          },
                          {
                            id: 7,
                          },
                          {
                            id: 8,
                          },
                          {
                            id: 9,
                          },
                          {
                            id: 10,
                          },
                        ]}
                        renderItem={(item) => {
                          const { id } = item

                          return (
                            <ResourceItem id={id}>
                              {window.innerWidth < 426 ? (
                                <div
                                  style={{
                                    paddingTop: 18,
                                    paddingBottom: 18,
                                  }}
                                >
                                  <SkeletonBodyText lines={4} />
                                </div>
                              ) : (
                                <div
                                  style={{
                                    paddingTop: 14,
                                    paddingBottom: 14,
                                  }}
                                >
                                  <SkeletonBodyText lines={3} />
                                </div>
                              )}
                            </ResourceItem>
                          )
                        }}
                      />
                    </Card>
                    <Button fullWidth disabled>
                      {localize("LoadMoreEvents")}
                    </Button>
                  </Stack>
                </div>
              </Card>
            </CSSTransition>
          </Layout.Section>
          <Layout.Section secondary>
            <CSSTransition
              in={true}
              appear={true}
              timeout={1000}
              classNames="transform"
            >
              <Card sectioned title={localize("GeneralInformation")}>
                <div style={{ paddingBottom: 12 }}>
                  <SkeletonBodyText lines={7} />
                </div>
              </Card>
            </CSSTransition>
            <CSSTransition
              in={true}
              appear={true}
              timeout={1000}
              classNames="transform"
            >
              <Card sectioned title={localize("ActionsOfTheInitiative")}>
                <Card sectioned>
                  <Stack>
                    <SkeletonThumbnail size="small" />
                  </Stack>
                </Card>
              </Card>
            </CSSTransition>
          </Layout.Section>
        </Layout>
      </SkeletonPage>
    </div>
  )
}

export default InitiativeSkeleton
