// utility
import languagesArray from "../../../app/config/languagesArray";

const allLanguages = languagesArray.map((l) => {
  return { id: l.id };
});

allLanguages.unshift({ id: "en" });

var globalObjTeams = {};

for (let i = 0; i < allLanguages.length; i++) {
  globalObjTeams[allLanguages[i].id] = {
    heading: "",
    body: "",
    img: "",
    cover: "",
    tags: [],
  };
}

const resetGlobalObjTeams = () => {
  for (let i = 0; i < allLanguages.length; i++) {
    globalObjTeams[allLanguages[i].id].heading = "";
    globalObjTeams[allLanguages[i].id].body = "";
    globalObjTeams[allLanguages[i].id].img = "";
    globalObjTeams[allLanguages[i].id].cover = "";
    globalObjTeams[allLanguages[i].id].tags = [];
  }
};

export default globalObjTeams;
export { resetGlobalObjTeams };
