import Service from "../service"
const Api = new Service()

const teams = {
  async getMyTeams() {
    return Api.get("myTeamsEndpoint", "")
  },

  async createTeam(body) {
    return Api.post("myTeamsEndpoint", "", body)
  },

  async getTeam(id) {
    return Api.get("myTeamsEndpoint", `/${id}`)
  },

  async updateTeam(id, body) {
    return Api.awsPut("myTeamsEndpoint", `/${id}`, body)
  },

  async getTeamMembers(teamId) {
    return Api.get("teamsEndpoint", `/${teamId}/users/count`)
  },

  async getSignedUrl() {
    return Api.get(
      "signedUrlEndpoint",
      `/mediamanager/upload/getSignedUrl?timestamp=${+new Date()}`
    )
  },

  async putS3Object(data, path, imageType = "jpeg") {
    return Api.put(data, path, imageType)
  },
}

export default teams
