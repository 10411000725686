import React, { useEffect, useState, useCallback } from "react";
import {
  Page,
  Card,
  Layout,
  Stack,
  Heading,
  TextContainer,
  TextStyle,
  Tooltip,
  Icon,
  Image,
  Toast,
} from "@shopify/polaris";
import { InfoMinor } from "@shopify/polaris-icons";
import { useParams } from "react-router-dom";

// api
import { GraphApi } from "../../../../api";
import initiative from "../../../../api/modules/initiatives";

// localizer
import { useLocalizer } from "reactjs-localizer";

// style
import "./styles.css";

// skeleton
import EventSkeleton from "./skeleton";

// function to remove event listener
import debounce from "../../../../app/config/debounceFunction";

const EventShow = () => {
  const { initiativeId, eventId } = useParams();
  const [loading, setLoading] = useState(true);
  const { localize } = useLocalizer();
  var userLang = navigator.language || navigator.userLanguage;
  userLang = userLang.slice(0, userLang.indexOf("-"));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // REMOVE RESIZE END EVENT
  if (window.innerWidth > 450) {
    window.removeEventListener("resize", debounce);
  }

  // EVENT CREATED TOAST
  const [active, setActive] = useState(false);

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const toastMarkup = active ? (
    <Toast content={localize("Congratulations")} onDismiss={toggleActive} />
  ) : null;

  useEffect(() => {
    if (window.location.pathname.includes("/justcreated")) {
      toggleActive();
    }
    //eslint-disable-next-line
  }, []);

  // INFORMATION
  const [eventName, setEventName] = useState("");
  const [eventImage, setEventImage] = useState("");
  const [eventDescription, setEventDescription] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startHour, setStartHour] = useState("");
  const [endHour, setEndHour] = useState("");
  const [creatorTeam, setCreatorTeam] = useState("");
  const [place, setPlace] = useState("");
  const [peoplePartecipating, setPeoplePartecipating] = useState("");
  const [actionsTaken, setActionsTaken] = useState("");
  const [actionsList, setActionsList] = useState([]);

  // FETCH INITIATIVE
  const isoDateToString = (date) => {
    let months = [
      localize("JAN"),
      localize("FEB"),
      localize("MAR"),
      localize("APR"),
      localize("MAY"),
      localize("JUN"),
      localize("JUL"),
      localize("AUG"),
      localize("SEP"),
      localize("OCT"),
      localize("NOV"),
      localize("DEC"),
    ];

    let dateToPrint = date.getDate() + " " + months[date.getMonth()];

    return dateToPrint;
  };

  const fetchEvent = async () => {
    const { data } = await GraphApi.graphql({
      query: initiative.queries.getEvents,
      variables: { id: eventId },
    });

    let event = data.getEvents;
    console.log(event);

    // name
    let defaultLanguage = localStorage.getItem("defaultLang");
    let nameToSet = event.translations.items.filter(
      (value) => value.lang === userLang
    );
    if (nameToSet.length === 0) {
      nameToSet = event.translations.items.filter(
        (value) => value.lang === defaultLanguage
      );
    }
    setEventName(nameToSet[0].title);

    // image
    setEventImage(event.translations.items[0].image);

    // description
    let description = "";
    for (let i = 0; i < event.translations.items.length; i++) {
      if (event.translations.items[i].lang === userLang) {
        description = event.translations.items[i].description;
      }
    }
    if (description === "") {
      let defaultLanguage = localStorage.getItem("defaultLang");
      for (let i = 0; i < event.translations.items.length; i++) {
        if (event.translations.items[i].lang === defaultLanguage) {
          description = event.translations.items[i].description;
        }
      }
    }
    setEventDescription(description);

    // actions
    setActionsList(event.actions.items);

    // date
    let startD = new Date(event.startsAt);
    let endD = new Date(event.endsAt);
    let startDateToPrint = isoDateToString(startD);
    let endDateToPrint = isoDateToString(endD);
    setStartDate(startDateToPrint);
    setEndDate(endDateToPrint);

    // hour
    let startH =
      startD.getHours() +
      ":" +
      (startD.getMinutes().toString().length === 1
        ? "0" + startD.getMinutes()
        : startD.getMinutes());
    let endH =
      endD.getHours() +
      ":" +
      (endD.getMinutes().toString().length === 1
        ? "0" + endD.getMinutes()
        : endD.getMinutes());
    setStartHour(startH);
    setEndHour(endH);

    // creator team
    let creator = JSON.parse(JSON.parse(event.teamCreator.documents));
    if (creator.hasOwnProperty(userLang)) {
      setCreatorTeam(creator[userLang]);
    } else {
      setCreatorTeam(creator.en);
    }

    // place
    setPlace(event.locationLabel ? event.locationLabel : event.city);

    // number of people partecipating
    setPeoplePartecipating(event.participantCounter.count);

    // number of actions taken
    setActionsTaken(event.actionCounter.count);

    setLoading(false);
  };

  useEffect(() => {
    fetchEvent();
    //eslint-disable-next-line
  }, []);

  // ACTIONS CARDS
  const ActionsCards = () => {
    let defaultLanguage = localStorage.getItem("defaultLang");
    let cards = [];

    if (actionsList[0].action !== null) {
      for (let i = 0; i < actionsList.length; i++) {
        let action = actionsList[i].action.translations.items;
        let actionInUserLang = action.filter(
          (value) => value.lang === userLang
        );
        if (actionInUserLang.length === 0) {
          actionInUserLang = action.filter(
            (value) => value.lang === defaultLanguage
          );
        }
        actionInUserLang = actionInUserLang[0];

        cards.push(
          <Card sectioned key={actionInUserLang.title}>
            <Stack alignment="center" spacing="loose" wrap={false}>
              <div className="img-container">
                <Image
                  source={actionInUserLang.image}
                  alt="Action"
                  style={{
                    height: "40px",
                    borderRadius: 5,
                  }}
                />
              </div>
              <p style={{ paddingLeft: "10px", maxWidth: "65%" }}>
                {actionInUserLang.title.slice(
                  actionInUserLang.title.indexOf("-") + 1
                )}
              </p>
            </Stack>
          </Card>
        );
      }
    }

    if (cards.length === 0) {
      cards.push(<p key={897}>{localize("NoActions")}</p>);
    }

    return cards;
  };

  return (
    <>
      {loading ? (
        <EventSkeleton />
      ) : (
        <Page
          title={eventName}
          breadcrumbs={[
            {
              content: "Initiative",
              url: `/dashboard/initiatives/${initiativeId}`,
            },
          ]}
        >
          <Layout>
            <Layout.Section>
              <Card>
                <Card.Section flush>
                  <img src={eventImage} alt="Event" className="event-image" />
                </Card.Section>
                <Card.Section subdued>
                  <Stack vertical>
                    <div style={{ paddingTop: 6 }}>
                      <Heading>{localize("AboutThisInitiative")}</Heading>
                    </div>
                    <TextContainer>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: eventDescription,
                        }}
                      />
                    </TextContainer>
                  </Stack>
                </Card.Section>
              </Card>
            </Layout.Section>
            <Layout.Section secondary>
              <Card title={localize("GeneralInformation")} sectioned>
                <Stack vertical spacing="extraTight">
                  <TextContainer>
                    {localize("Place")}:{" "}
                    <TextStyle variation="strong">{place}</TextStyle>
                  </TextContainer>
                  <TextContainer>
                    {localize("Start")}:{" "}
                    <TextStyle variation="strong">{startDate}</TextStyle>{" "}
                    {localize("At")}{" "}
                    <TextStyle variation="strong">{startHour}</TextStyle>
                  </TextContainer>
                  <TextContainer>
                    {localize("End")}:{" "}
                    <TextStyle variation="strong">{endDate}</TextStyle>{" "}
                    {localize("At")}{" "}
                    <TextStyle variation="strong">{endHour}</TextStyle>
                  </TextContainer>
                  <Stack spacing="extraTight" alignment="center">
                    <TextContainer>{localize("CreatorTeam")}: </TextContainer>
                    <TextStyle variation="strong">
                      {creatorTeam.heading}
                    </TextStyle>
                    <Tooltip
                      content={
                        <div
                          dangerouslySetInnerHTML={{
                            __html: creatorTeam.body[0].content.slice(
                              0,
                              creatorTeam.body[0].content.indexOf(".") > 0
                                ? creatorTeam.body[0].content.indexOf(".") + 1
                                : -1
                            ),
                          }}
                        />
                      }
                    >
                      <div
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <Icon source={InfoMinor} color="white" />
                      </div>
                    </Tooltip>
                  </Stack>
                  <TextContainer>
                    {localize("PeoplePartecipating")}:{" "}
                    <TextStyle variation="strong">
                      {peoplePartecipating}
                    </TextStyle>
                  </TextContainer>
                  <TextContainer>
                    {localize("ActionsTaken")}:{" "}
                    <TextStyle variation="strong">{actionsTaken}</TextStyle>
                  </TextContainer>
                </Stack>
              </Card>
              <Card title={localize("ActionsOfTheEvent")} sectioned>
                <ActionsCards />
              </Card>
            </Layout.Section>
          </Layout>
          {toastMarkup}
        </Page>
      )}
    </>
  );
};

export default EventShow;
