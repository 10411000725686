/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const teamInitiativeActionCounterByTeam = /* GraphQL */ `
  query TeamInitiativeActionCounterByTeam($initiativeId: ID!, $teamId: String) {
    teamInitiativeActionCounterByTeam(
      initiativeId: { eq: $initiativeId }
      teamId: $teamId
    ) {
      items {
        count
      }
    }
  }
`;
export const teamInitiativeAttendeesByTeam = /* GraphQL */ `
  query TeamInitiativeAttendeesByTeam($initiativeId: ID!, $teamId: String) {
    teamInitiativeAttendeesByTeam(
      initiativeId: { eq: $initiativeId }
      teamId: $teamId
    ) {
      items {
        count
      }
    }
  }
`;
export const teamInitiativeParticipantsByTeam = /* GraphQL */ `
  query TeamInitiativeParticipantsByTeam($initiativeId: ID!, $teamId: String) {
    teamInitiativeParticipantsByTeam(
      initiativeId: { eq: $initiativeId }
      teamId: $teamId
    ) {
      items {
        count
      }
    }
  }
`;
export const getInitiativesName = /* GraphQL */ `
  query GetInitiatives($id: ID!) {
    getInitiatives(id: $id) {
      name
    }
  }
`;
export const listInitiatives = /* GraphQL */ `
  query ListInitiatives($currentDate: String) {
    listInitiatives(filter: { endsAt: { ge: $currentDate } }) {
      items {
        defaultLang
        id
        translations {
          items {
            lang
            whatDescription
            image
          }
        }
        name
        open
      }
      nextToken
    }
  }
`;
export const getInitiatives = /* GraphQL */ `
  query GetInitiatives($id: ID!) {
    getInitiatives(id: $id) {
      actionCounter {
        count
      }
      actionCounterByAction {
        nextToken
      }
      actions {
        items {
          action {
            id
            translations {
              items {
                id
                title
                description
                image
                lang
              }
            }
            tasks {
              items {
                translations {
                  items {
                    id
                    title
                    description
                    image
                    lang
                  }
                }
              }
            }
          }
        }
        nextToken
      }
      attendeeCounter {
        count
        createdAt
        id
        updatedAt
      }
      createdAt
      defaultLang
      endsAt
      eventCounter {
        count
        createdAt
        id
        updatedAt
      }
      events(limit: 10) {
        items {
          id
          name
          startsAt
          endsAt
          city
          translations {
            items {
              id
              title
              lang
            }
          }
        }
        nextToken
      }
      id
      name
      open
      participantCounter {
        count
      }
      startsAt
      supportingTeamCounter {
        count
      }
      supportingTeams {
        nextToken
      }
      teamCreator {
        documents
        id
      }
      tenant
      translations {
        items {
          lang
          whatDescription
          howDescription
          whenDescription
          image
        }
      }
      updatedAt
    }
  }
`;
export const getInitiativesWithLimit = /* GraphQL */ `
  query GetInitiatives($id: ID!, $eventLimit: Int, $eventsNextToken: String) {
    getInitiatives(id: $id) {
      actionCounter {
        count
      }
      actionCounterByAction {
        nextToken
      }
      actions {
        items {
          action {
            id
            tasks {
              items {
                translations {
                  items {
                    id
                    title
                    description
                    image
                    lang
                  }
                }
              }
            }
          }
        }
        nextToken
      }
      attendeeCounter {
        count
        createdAt
        id
        updatedAt
      }
      createdAt
      defaultLang
      endsAt
      eventCounter {
        count
        createdAt
        id
        updatedAt
      }
      events(limit: $eventLimit, nextToken: $eventsNextToken) {
        items {
          id
          name
          startsAt
          endsAt
          city
        }
        nextToken
      }
      id
      name
      open
      participantCounter {
        count
      }
      startsAt
      supportingTeamCounter {
        count
      }
      supportingTeams {
        nextToken
      }
      teamCreator {
        documents
        id
      }
      tenant
      translations {
        items {
          lang
          whatDescription
          howDescription
          whenDescription
          image
        }
      }
      updatedAt
    }
  }
`;
export const getInitiativesCounters = /* GraphQL */ `
  query GetInitiatives($id: ID!) {
    getInitiatives(id: $id) {
      actionCounter {
        count
      }
      participantCounter {
        count
      }
      supportingTeamCounter {
        count
      }
      supportingTeams {
        nextToken
      }
    }
  }
`;
export const isSupporting = /* GraphQL */ `
  query IsSupporting($initiativeId: ID, $teamId: String) {
    isSupporting(initiativeId: $initiativeId, teamId: { eq: $teamId }) {
      items {
        id
      }
    }
  }
`;
export const nearbyEvents = /* GrapQL */ `
query NearbyEvents($initiativeId: String!, $km: Int, $location: LocationInput!) {
    nearbyEvents(initiativeId: $initiativeId, km: $km, location: $location) {
      items {
        id
        startsAt
        endsAt
        city
        distance
        translations {
          items {
            id
            title
            lang
          }
        }
      }
      nextToken
    }
  }
`;
export const getEvents = /* GraphQL */ `
  query GetEvents($id: ID!) {
    getEvents(id: $id) {
      actionCounter {
        count
        createdAt
        id
        updatedAt
      }
      actionCounterByAction {
        nextToken
      }
      actions {
        items {
          action {
            id
            translations {
              items {
                lang
                description
                image
                title
              }
            }
          }
        }
        nextToken
      }
      address
      attendeeCounter {
        count
        createdAt
        id
        updatedAt
      }
      attendees {
        nextToken
      }
      city
      country
      createdAt
      endsAt
      id
      initiativeId
      location {
        lat
        lon
      }
      locationLabel
      locationType
      name
      participant {
        nextToken
      }
      participantCounter {
        count
        createdAt
        id
        updatedAt
      }
      scope
      startsAt
      state
      teamCreator {
        documents
        id
      }
      translations {
        nextToken
        items {
          id
          lang
          title
          description
          image
        }
      }
      updatedAt
    }
  }
`;
export const getActions = /* GraphQL */ `
  query GetActions($id: ID!) {
    getActions(id: $id) {
      createdAt
      events {
        nextToken
      }
      hasPost
      id
      initiatives {
        nextToken
      }
      repeatable
      tasks {
        nextToken
      }
      translations {
        nextToken
      }
      updatedAt
    }
  }
`;
export const initiativesByEndsAt = /* GraphQL */ `
  query InitiativesByEndsAt(
    $endsAt: ModelStringKeyConditionInput
    $filter: ModelInitiativesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $tenant: String
  ) {
    initiativesByEndsAt(
      endsAt: $endsAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      tenant: $tenant
    ) {
      items {
        createdAt
        defaultLang
        endsAt
        id
        name
        open
        startsAt
        tenant
        updatedAt
      }
      nextToken
    }
  }
`;
export const listCurrentInitiatives = /* GraphQL */ `
  query ListCurrentInitiatives($lang: String) {
    listCurrentInitiatives(lang: $lang) {
      items {
        createdAt
        defaultLang
        endsAt
        id
        name
        open
        startsAt
        tenant
        updatedAt
      }
      nextToken
    }
  }
`;
export const isAttendees = /* GraphQL */ `
  query IsAttendees(
    $eventId: ID
    $filter: ModelAttendeesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $sub: ModelStringKeyConditionInput
  ) {
    isAttendees(
      eventId: $eventId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      sub: $sub
    ) {
      items {
        attendeesAt
        blob
        createdAt
        eventId
        id
        sub
        updatedAt
      }
      nextToken
    }
  }
`;
