import React from "react";
import { Page, Card, TextStyle } from "@shopify/polaris";

// animation
import { CSSTransition } from "react-transition-group";

// localizer
import { useLocalizer } from "reactjs-localizer";

// function to remove event listener
import debounce from "../../app/config/debounceFunction";

const Privacy = () => {
  const { localize } = useLocalizer();

  // REMOVE RESIZE END EVENT
  if (window.innerWidth > 450) {
    window.removeEventListener("resize", debounce);
  }

  return (
    <Page
      title={localize("Privacy")}
      breadcrumbs={[{ content: "Overview", url: "/dashboard" }]}
    >
      <CSSTransition
        in={true}
        appear={true}
        timeout={1000}
        classNames="transform"
      >
        <Card sectioned title={localize("TermsAndConditions")}>
          <iframe
            title="Terms and Conditions"
            style={{ width: "100%", height: "22vh", borderRadius: 10 }}
            src="https://aworld.org/terms-and-conditions"
            frameBorder="0"
          />
        </Card>
      </CSSTransition>
      <CSSTransition
        in={true}
        appear={true}
        timeout={1000}
        classNames="transform"
      >
        <Card sectioned title={localize("Privacy")}>
          <iframe
            title="Privacy"
            style={{ width: "100%", height: "22vh", borderRadius: 10 }}
            src="https://site.aworld.org/privacy"
            frameBorder="0"
          />
        </Card>
      </CSSTransition>
      <CSSTransition
        in={true}
        appear={true}
        timeout={1000}
        classNames="transform"
      >
        <div
          style={{
            marginTop: 45,
            width: "100%",
            height: "4.5vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <TextStyle variation="subdued">
            version {process.env.REACT_APP_VERSION_NUMBER}
          </TextStyle>
        </div>
      </CSSTransition>
    </Page>
  );
};

export default Privacy;
