import React, { useCallback, useState, useEffect, useContext } from "react";
import {
  Page,
  Layout,
  Form,
  FormLayout,
  TextField,
  Button,
  Stack,
  Card,
  TextContainer,
  Spinner,
  TextStyle,
} from "@shopify/polaris";
import debounce from "../../app/config/debounceFunction";

// auth
import { Auth } from "aws-amplify";
import { federatedSignIn, signIn, AuthContext } from "../../app/AuthContext";

//animation
import { CSSTransition } from "react-transition-group";

//style
import "./styles.css";

//logos
import logo from "./../../images/team-dashboard-logo.jpg";
import logoDark from "./../../images/team-dashboard-logo-dark.jpg";
import appleLogo from "../../images/apple.png";
import appleLogoWhite from "../../images/apple-white.png";
import googleLogo from "../../images/google.png";
import facebookLogo from "../../images/facebook.png";
import businessLogo from "../../images/business.png";

// localizer
import { useLocalizer } from "reactjs-localizer";

// components
import CodePage from "./codePage";
import SignUpPage from "./signUpPage";

// mobile session
import {
  CognitoUser,
  CognitoUserSession,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoAccessToken,
} from "amazon-cognito-identity-js";

// api
import Api from "../../api";

// business login component
import BusinessLogin from "./businessLogin";

//axios
import axios from "axios";

const SignIn = () => {
  const { setAuth, theme } = useContext(AuthContext);

  // FETCH BUSINESS TEAMS LIST
  const [loadingBusinessTeams, setLoadingBusinessTeams] = useState(true);
  const [businessTeamsList, setBusinessTeamsList] = useState([]);

  const fetchBusinessList = async () => {
    var config = {
      method: "get",
      url: "https://aworld-media.s3.eu-west-1.amazonaws.com/utils/businessAuth.json",
    };
    try {
      const data = await axios(config);
      console.log(data);
      setBusinessTeamsList(data.data.accessList);
      setLoadingBusinessTeams(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchBusinessList();
  }, []);

  // REMOVE RESIZE END EVENT
  if (window.innerWidth > 450) {
    window.removeEventListener("resize", debounce);
  }

  const sessionFromApp = async () => {
    let url = window.location.href;

    let idToken = url.slice(url.indexOf("idToken") + 8, url.indexOf("&"));
    let refreshToken = url.slice(url.indexOf("refreshToken") + 13);
    refreshToken = refreshToken.slice(0, refreshToken.indexOf("&"));
    let accessToken = url.slice(url.indexOf("accessToken") + 12);
    accessToken = accessToken.slice(0, accessToken.indexOf("&"));
    let username = url.slice(url.lastIndexOf("=") + 1);

    const localSession = new CognitoUserSession({
      IdToken: new CognitoIdToken({ IdToken: idToken }),
      RefreshToken: new CognitoRefreshToken({
        RefreshToken: refreshToken,
      }),
      AccessToken: new CognitoAccessToken({
        AccessToken: accessToken,
      }),
    });

    const localUser = new CognitoUser({
      Username: username,
      Pool: Auth.userPool,
      Storage: Auth.userPool.storage,
    });

    localUser.setSignInUserSession(localSession);

    Auth.currentCredentials = async () => localSession;

    const session = await Auth.currentSession();

    setAuth({
      type: "LOGIN",
      payload: { session },
    });
  };

  if (
    window.location.href.includes("idToken") &&
    window.location.href.includes("refreshToken") &&
    window.location.href.includes("accessToken") &&
    window.location.href.includes("username")
  ) {
    sessionFromApp();
  }

  // check in which environment i am
  const currentEnvironment = process.env.REACT_APP_ENVIRONMENT;

  // general
  const { localize } = useLocalizer();

  const [email, setEmail] = useState("");
  const [isEmailOk, setIsEmailOk] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailNotValidError, setEmailNotValidError] = useState(false);

  const [loading, setLoading] = useState(false);

  const emailChange = useCallback(
    (value) => {
      setEmail(value.toLowerCase().replaceAll(" ", ""));
      setEmailError(false);
      setEmailNotValidError(false);
    },
    [setEmail]
  );

  // check if user has been redirected by social login
  const [redirected, setRedirected] = useState(false);
  const [isSocialLogin, setIsSocialLogin] = useState(false);

  const checkRedirect = async () => {
    if (localStorage.getItem("amplify-redirected-from-hosted-ui") === "true") {
      setRedirected(true);

      const apiCall = await Api.user.getCurrentUser();

      if (
        apiCall.first_name === undefined ||
        apiCall.last_name === undefined ||
        apiCall.uid === undefined
      ) {
        for (let i = 0; i < localStorage.length; i++) {
          let key = localStorage.key(i);
          if (key.includes("userData")) {
            setEmail(
              JSON.parse(localStorage.getItem(localStorage.key(i)))
                .UserAttributes[3].Value
            );
          }
        }

        setRedirected(false);
        setIsSocialLogin(true);
        setEmailError(true);

        return;
      }

      const session = await Auth.currentSession();

      setAuth({
        type: "LOGIN",
        payload: { session },
      });
    }
  };

  useEffect(() => {
    checkRedirect();
    //eslint-disable-next-line
  }, []);

  // checks if email field is empty or not and disable or enable sign in button
  const [signinButtonDisabled, setSigninButtonDisabled] = useState(true);
  useEffect(() => {
    if (email.length === 0) {
      setSigninButtonDisabled(true);
    } else {
      setSigninButtonDisabled(false);
    }
  }, [email]);

  // checks if email is valid and if it's registered
  const checkEmail = async () => {
    setEmailError(false);
    setEmailNotValidError(false);

    let regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regex.test(email)) {
      setEmailNotValidError(true);
      return;
    }

    setLoading(true);
    const result = await signIn(email);

    if (result === "error") {
      setEmailError(true);
      setLoading(false);
    } else {
      setIsEmailOk(true);
      setLoading(false);
    }
  };

  // business login
  const [showBusinessLogin, setShowBusinessLogin] = useState(false);

  // email form
  const insertEmail = () => {
    return (
      <Stack vertical spacing="loose">
        <Button
          onClick={() => {
            federatedSignIn("SignInWithApple");
          }}
          fullWidth
          size="large"
          disabled={currentEnvironment === "dev" ? true : false}
        >
          <Stack alignment="trailing">
            <img
              src={theme.colorScheme === "dark" ? appleLogoWhite : appleLogo}
              style={{ maxWidth: "16px", margin: 0 }}
              alt="Apple logo"
            />
            <TextContainer>{localize("SignInWith")} Apple</TextContainer>
          </Stack>
        </Button>
        <Button
          onClick={() => {
            federatedSignIn("Google");
          }}
          fullWidth
          size="large"
          disabled={currentEnvironment === "dev" ? true : false}
        >
          <Stack alignment="trailing">
            <img
              src={googleLogo}
              style={{ maxWidth: "15px", margin: 0 }}
              alt="Google logo"
            />
            <TextContainer>{localize("SignInWith")} Google</TextContainer>
          </Stack>
        </Button>
        <Button
          onClick={() => {
            federatedSignIn("Facebook");
          }}
          fullWidth
          size="large"
          disabled={currentEnvironment === "dev" ? true : false}
        >
          <Stack alignment="trailing">
            <img
              src={facebookLogo}
              style={{ maxWidth: "15px", margin: 0 }}
              alt="Facebook logo"
            />
            <TextContainer>{localize("SignInWith")} Facebook</TextContainer>
          </Stack>
        </Button>
        <Button
          fullWidth
          size="large"
          loading={loadingBusinessTeams}
          onClick={() => {
            setShowBusinessLogin(true);
          }}
          disabled={currentEnvironment === "dev" ? true : false}
        >
          <Stack alignment="trailing">
            <img
              src={businessLogo}
              style={{ maxWidth: "15px", margin: 0 }}
              alt="Facebook logo"
            />
            <TextContainer>{localize("BusinessLogin")}</TextContainer>
          </Stack>
        </Button>
        <Stack alignment="center" distribution="fill" spacing="none">
          <Stack.Item>
            <hr />
          </Stack.Item>
          <Stack.Item>
            <hr />
          </Stack.Item>
          <Stack.Item>
            <hr />
          </Stack.Item>
          <Stack.Item>
            <Stack distribution="center">
              <p className="or">{localize("Or")}</p>
            </Stack>
          </Stack.Item>
          <Stack.Item>
            <hr />
          </Stack.Item>
          <Stack.Item>
            <hr />
          </Stack.Item>
          <Stack.Item>
            <hr />
          </Stack.Item>
        </Stack>
        <p
          style={{
            textAlign: "center",
            fontSize: 16,
            marginTop: -2,
            marginBottom: -12,
          }}
        >
          {localize("GetACode")}
        </p>
        <Form onSubmit={checkEmail}>
          <FormLayout>
            <Stack vertical spacing="loose">
              <TextField
                type="text"
                placeholder={localize("EmailAddress")}
                onChange={emailChange}
                value={email}
                clearButton={true}
                onClearButtonClick={() => {
                  setEmail("");
                  setEmailError(false);
                  setEmailNotValidError(false);
                }}
                inputMode="email"
                error={
                  emailNotValidError ? (
                    <CSSTransition
                      in={true}
                      appear={true}
                      timeout={1000}
                      classNames="transform"
                    >
                      <p>{localize("InsertAValidEmailAddress")}</p>
                    </CSSTransition>
                  ) : (
                    false
                  )
                }
              />
              <Button
                primary
                onClick={checkEmail}
                fullWidth
                loading={loading}
                size="large"
                disabled={signinButtonDisabled}
              >
                {localize("EmailMeALoginLink")}
              </Button>
            </Stack>
          </FormLayout>
        </Form>
      </Stack>
    );
  };

  return showBusinessLogin ? (
    <BusinessLogin businessTeamsList={businessTeamsList} />
  ) : redirected ? (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Spinner />
    </div>
  ) : isEmailOk ? (
    <CodePage userEmail={email} />
  ) : emailError ? (
    <SignUpPage userEmail={email} isSocialLogin={isSocialLogin} />
  ) : (
    <div className="login-card">
      <CSSTransition
        in={true}
        appear={true}
        timeout={1000}
        classNames="transform"
      >
        <Page>
          <Card sectioned>
            <div className="inner-card">
              <Layout>
                <Layout.Section>
                  <Stack distribution="center">
                    <img
                      src={theme.colorScheme === "dark" ? logoDark : logo}
                      alt="logo"
                      className="logo"
                    />
                  </Stack>
                </Layout.Section>
                <Layout.Section>{insertEmail()}</Layout.Section>
              </Layout>
            </div>
            <div
              style={{
                marginTop: 20,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <TextStyle variation="subdued">
                {localize("ByContinuingYouAcceptThe")}{" "}
                <a
                  href="https://aworld.org/terms-and-conditions"
                  target="_blank"
                  rel="noreferrer"
                >
                  {localize("TermsAndConditionsLowerCase")}
                </a>
              </TextStyle>
            </div>
          </Card>
        </Page>
      </CSSTransition>
    </div>
  );
};

export default SignIn;
