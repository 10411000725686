import React, { useCallback, useEffect, useState, useContext } from "react";
import {
  Page,
  Layout,
  Form,
  FormLayout,
  TextField,
  Button,
  Stack,
  Card,
  Toast,
} from "@shopify/polaris";

//animation
import { CSSTransition } from "react-transition-group";

//style
import "./signUpStyles.css";

// localizer
import { useLocalizer } from "reactjs-localizer";

// components
import CodePage from "./codePage";
import Api from "../../api";

// auth
import { Auth } from "aws-amplify";
import { signUp, signIn, AuthContext } from "../../app/AuthContext";

const SignUpPage = ({ userEmail, isSocialLogin }) => {
  const { setAuth } = useContext(AuthContext);

  useEffect(() => {
    const backArrow = document.getElementsByClassName(
      "Polaris-Breadcrumbs__Breadcrumb"
    )[0];
    backArrow.addEventListener("click", () => {
      let theme;
      if (localStorage.getItem("theme")) {
        theme = localStorage.getItem("theme");
      }
      localStorage.clear();
      if (theme) {
        localStorage.setItem("theme", theme);
      }
    });
  }, []);

  // general
  const { localize } = useLocalizer();

  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [username, setUsername] = useState("");

  const [existingUsernameError, setExistingUsernameError] = useState(false);
  const [isDataOk, setIsDataOk] = useState(false);

  const [loading, setLoading] = useState(false);

  const nameChange = useCallback(
    (value) => {
      if (value.length === 1 && value === " ") {
        return;
      }
      let newName = value.toLowerCase();
      newName = newName.charAt(0).toUpperCase() + newName.slice(1);
      setName(newName);
    },
    [setName]
  );

  const surnameChange = useCallback(
    (value) => {
      if (value.length === 1 && value === " ") {
        return;
      }
      let newSurname = value.toLowerCase();
      newSurname = newSurname.charAt(0).toUpperCase() + newSurname.slice(1);
      setSurname(newSurname);
    },
    [setSurname]
  );

  const usernameChange = useCallback(
    (value) => {
      setExistingUsernameError(false);
      let regex = /^$|[0-9A-Za-z-._]+$/;
      if (regex.test(value)) {
        setUsername(value.toLowerCase());
      }
    },
    [setUsername]
  );

  // checks if all the fields are empty or not and disable or enable sign up button
  const [signupButtonDisabled, setSignupButtonDisabled] = useState(true);
  useEffect(() => {
    if (name.length === 0 || surname.length === 0 || username.length === 0) {
      setSignupButtonDisabled(true);
    } else {
      setSignupButtonDisabled(false);
    }
  }, [name, surname, username]);

  // generates a random string of n characters
  const randomString = (length, chars) => {
    let result = "";
    for (let i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  };

  // generates username based on name, surname and a random 4 characters string
  const generateUsername = () => {
    if (name.length === 0 || surname.length === 0) {
      return;
    } else {
      let usernameToSave =
        name.toLowerCase() +
        "-" +
        surname.toLowerCase() +
        "-" +
        randomString(4, "0123456789abcdefghijklmnopqrstuvwxyz");
      usernameToSave = usernameToSave.replaceAll(" ", "-");
      setUsername(usernameToSave);
    }
  };

  // toast to show in case of error
  const [showErrorToast, setShowErrorToast] = useState(false);

  const errorToast = (
    <Toast
      content={localize("SomethingWentWrong")}
      error
      onDismiss={() => setShowErrorToast(false)}
      duration={6000}
    />
  );

  // does the signup
  const checkData = async () => {
    setExistingUsernameError(false);
    setShowErrorToast(false);

    let password = randomString(
      20,
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
    );

    setLoading(true);
    const result = await signUp(userEmail, password, name, surname, username);

    if (result === "UserLambdaValidationException") {
      // username già preso
      setExistingUsernameError(true);
      setLoading(false);
    } else {
      let result = await signIn(userEmail);
      if (result === "error") {
        setShowErrorToast(true);
        setLoading(false);
      } else {
        setIsDataOk(true);
        setLoading(false);
      }
    }
  };

  // update user data (first name, last name, uid) after social login
  const updateUserData = async () => {
    setLoading(true);

    let offset = new Date().getTimezoneOffset(),
      o = Math.abs(offset);
    offset =
      (offset < 0 ? "+" : "-") +
      ("00" + Math.floor(o / 60)).slice(-2) +
      ":" +
      ("00" + (o % 60)).slice(-2);
    let ip;
    let country;
    try {
      ip = await (await fetch("http://ip-api.com/json")).json();
      country = ip.countryCode;

      let userLang = navigator.language || navigator.userLanguage;
      userLang = userLang.slice(userLang.indexOf("-") + 1);

      let body = {
        first_name: name,
        last_name: surname,
        email: userEmail,
        uid: username,
        timezone: offset,
        lang: userLang.toLowerCase(),
        country: country,
      };

      try {
        await Api.user.updateCurrentUser(body);
      } catch {
        setExistingUsernameError(true);
        setLoading(false);
        return;
      }

      const session = await Auth.currentSession();

      setAuth({
        type: "LOGIN",
        payload: { session },
      });
    } catch (e) {
      console.log(e);
    }
  };

  // data form
  const insertData = () => {
    return (
      <Stack vertical>
        <p style={{ fontSize: 16 }}>{localize("HowShouldWeCallYou")}</p>
        <Form onSubmit={isSocialLogin ? updateUserData : checkData}>
          <FormLayout>
            <Stack vertical>
              <TextField
                placeholder={localize("FirstName")}
                onChange={nameChange}
                value={name}
                clearButton={true}
                onClearButtonClick={() => {
                  setName("");
                }}
                onBlur={generateUsername}
                inputMode="text"
              />
              <TextField
                placeholder={localize("LastName")}
                onChange={surnameChange}
                value={surname}
                clearButton={true}
                onClearButtonClick={() => {
                  setSurname("");
                }}
                onBlur={generateUsername}
                inputMode="text"
              />
              <Stack.Item></Stack.Item>
              <p style={{ fontSize: 16 }}>
                {localize("WhichUsernameDoYouWantToUse")}
              </p>
              <Stack.Item></Stack.Item>
              <TextField
                placeholder={localize("Username")}
                onChange={usernameChange}
                value={username}
                clearButton={true}
                onClearButtonClick={() => {
                  setUsername("");
                  setExistingUsernameError(false);
                }}
                error={
                  existingUsernameError ? (
                    <CSSTransition
                      in={true}
                      appear={true}
                      timeout={1000}
                      classNames="transform"
                    >
                      <p>{localize("ThisUsernameIsAlreadyTaken")}</p>
                    </CSSTransition>
                  ) : (
                    false
                  )
                }
                inputMode="text"
              />
              <Button
                primary
                fullWidth
                size="large"
                disabled={signupButtonDisabled}
                onClick={isSocialLogin ? updateUserData : checkData}
                loading={loading}
              >
                {localize("Continue")}
              </Button>
            </Stack>
          </FormLayout>
        </Form>
      </Stack>
    );
  };

  return isDataOk ? (
    <CodePage userEmail={userEmail} fromSignup={true} />
  ) : (
    <div className="login-card-signup">
      <CSSTransition
        in={true}
        appear={true}
        timeout={1000}
        classNames="transform"
      >
        <Page breadcrumbs={[{ content: "Sign in page", url: "/dashboard" }]}>
          <Card sectioned>
            <div className="inner-card-signup">
              <Layout>
                <Layout.Section>{insertData()}</Layout.Section>
              </Layout>
            </div>
          </Card>
          {showErrorToast ? errorToast : null}
        </Page>
      </CSSTransition>
    </div>
  );
};

export default SignUpPage;
