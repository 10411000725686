import React, { useContext, useCallback, useState, useEffect } from "react";
import {
  Page,
  Layout,
  Form,
  FormLayout,
  TextField,
  Button,
  Stack,
  Card,
} from "@shopify/polaris";
import { answerCustomChallenge, AuthContext } from "../../app/AuthContext";
import { useHistory } from "react-router-dom";

//animation
import { CSSTransition } from "react-transition-group";

//style
import "./styles.css";

// localizer
import { useLocalizer } from "reactjs-localizer";
import Api from "../../api";

const CodePage = ({ userEmail, fromSignup }) => {
  // general
  const { setAuth } = useContext(AuthContext);
  const history = useHistory();
  const { localize } = useLocalizer();

  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState(false);

  const [loading, setLoading] = useState(false);

  const codeChange = useCallback(
    (value) => {
      setCode(value);
      setCodeError(false);
    },
    [setCode]
  );

  // checks if code field is empty or not and disable or enable submit button
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
  useEffect(() => {
    if (code.length < 6 || code.length > 6) {
      setSubmitButtonDisabled(true);
    } else {
      setSubmitButtonDisabled(false);
    }
  }, [code]);

  // checks if the inserted code is correct
  const checkCode = async () => {
    setCodeError(false);

    setLoading(true);
    var session = await answerCustomChallenge(code);

    if (session !== false) {
      setAuth({
        type: "LOGIN",
        payload: { session },
      });

      if (fromSignup) {
        let offset = new Date().getTimezoneOffset(),
          o = Math.abs(offset);
        offset =
          (offset < 0 ? "+" : "-") +
          ("00" + Math.floor(o / 60)).slice(-2) +
          ":" +
          ("00" + (o % 60)).slice(-2);
        let ip;
        let country;
        try {
          ip = await (await fetch("http://ip-api.com/json")).json();
          country = ip.countryCode;

          let userLang = navigator.language || navigator.userLanguage;
          userLang = userLang.slice(userLang.indexOf("-") + 1);

          let body = {
            timezone: offset,
            lang: userLang.toLowerCase(),
            country: country,
          };

          try {
            await Api.user.updateCurrentUser(body);
          } catch (e) {
            console.log(e);
            return;
          }
        } catch (e) {
          console.log(e);
        }
      }
    } else if (session === "3rd") {
      history.push("/dashboard");
    } else {
      setCodeError(true);
    }

    setLoading(false);
  };

  // code form
  const insertCode = () => {
    return (
      <Form onSubmit={checkCode}>
        <FormLayout>
          <TextField
            type="password"
            placeholder={localize("EnterTheCode")}
            onChange={codeChange}
            value={code}
            showCharacterCount
            maxLength={6}
            inputMode="numeric"
            error={
              codeError ? (
                <CSSTransition
                  in={true}
                  appear={true}
                  timeout={1000}
                  classNames="transform"
                >
                  <p>{localize("WrongCode")}</p>
                </CSSTransition>
              ) : null
            }
          />
          <Button
            primary
            onClick={checkCode}
            size="large"
            fullWidth
            disabled={submitButtonDisabled}
            loading={loading}
          >
            {localize("Next")}
          </Button>
        </FormLayout>
      </Form>
    );
  };

  return (
    <div className="login-card" style={{ paddingTop: "24vh" }}>
      <CSSTransition
        in={true}
        appear={true}
        timeout={1000}
        classNames="transform"
      >
        <Page breadcrumbs={[{ content: "Sign in page", url: "/dashboard" }]}>
          <Card sectioned>
            <div style={{ minHeight: "27vh" }}>
              <Layout>
                <Layout.Section>
                  <Stack vertical spacing="tight">
                    <p style={{ fontSize: 16 }}>
                      {localize("CheckYourEmail")} {userEmail}!
                    </p>
                    <p style={{ fontSize: 16 }}>
                      {localize("ToConfirmYourAccount")}
                    </p>
                  </Stack>
                </Layout.Section>
                <Layout.Section></Layout.Section>
                <Layout.Section>{insertCode()}</Layout.Section>
              </Layout>
            </div>
          </Card>
        </Page>
      </CSSTransition>
    </div>
  );
};

export default CodePage;
