import React, { useEffect, useState } from "react";
import {
  Page,
  Card,
  Loading,
  ResourceList,
  SkeletonPage,
  SkeletonBodyText,
} from "@shopify/polaris";
import { useParams } from "react-router";

// animation
import { CSSTransition } from "react-transition-group";

// api
import Api from "../../../api";

// localizer
import { useLocalizer } from "reactjs-localizer";

// custom resource item
import ResourceItemModule from "./resourceItemModule";

const AcademyClass = () => {
  const { teamId, id } = useParams();
  const { localize } = useLocalizer();

  // FETCH CLASS
  const [currentClass, setCurrentClass] = useState();
  const [classLoading, setClassLoading] = useState(true);

  const fetchClassAndModules = async () => {
    const data = await Api.education.getClass(teamId);

    let calls = [];
    for (let i = 0; i < data.items.modules.length; i++) {
      calls.push(Api.education.getModule(id, data.items.modules[i].id));
    }
    const result = await Promise.all(calls);

    for (let i = 0; i < data.items.modules.length; i++) {
      data.items.modules[i]["entities"] = result[i].module.entities;
    }

    console.log(data.items);
    setCurrentClass(data.items);
    setClassLoading(false);
  };

  useEffect(() => {
    fetchClassAndModules();
    //eslint-disable-next-line
  }, []);

  return classLoading ? (
    <>
      <Loading />
      <SkeletonPage>
        <CSSTransition
          in={true}
          appear={true}
          timeout={1000}
          classNames="transform"
        >
          <Card sectioned title={localize("Modules")}>
            <div style={{ paddingBottom: 2 }}>
              <SkeletonBodyText lines={6} />
            </div>
          </Card>
        </CSSTransition>
      </SkeletonPage>
    </>
  ) : (
    <Page
      title={`${localize("AcademySingular")} #${currentClass.id}`}
      breadcrumbs={[
        {
          content: "Team",
          url: window.location.pathname.slice(
            0,
            window.location.pathname.indexOf("academy") - 1
          ),
        },
      ]}
    >
      <Card title={localize("Modules")} sectioned>
        <Card>
          <ResourceList
            resourceName={{ singular: "module", plural: "modules" }}
            items={currentClass.modules}
            renderItem={(item, identifier, index) => {
              return <ResourceItemModule item={item} index={index} />;
            }}
          />
        </Card>
      </Card>
    </Page>
  );
};

export default AcademyClass;
